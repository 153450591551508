import React, { useRef } from "react";
import { Scrollbars } from "./components";
import { useScrollerProvider } from "components/scroller/scoller.context";
import { ScrollerProps } from "components/scroller/scoller.types";
import { useInnerRef } from "utils";
import * as Markup from "components/scroller/scroller.styles";

export const Scroller = React.memo(
  React.forwardRef((props: ScrollerProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { children, style, onScroll } = props;
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const { Provider, providerValue } = useScrollerProvider({ scrollRef });

    return (
      <Provider value={providerValue}>
        <Markup.Wrapper>
          <Markup.Container
            ref={useInnerRef<HTMLDivElement>({ innerRef: scrollRef, ref })}
            style={style}
            onScroll={onScroll}
          >
            {children}
          </Markup.Container>
          <Scrollbars scrollRef={scrollRef} />
        </Markup.Wrapper>
      </Provider>
    );
  }),
);
