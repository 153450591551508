import React, { HTMLAttributes, useCallback, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { ariaAttributes, useControlLoading } from "utils";
import { keyboardDispatcher } from "project";

export interface InputProps extends Omit<HTMLAttributes<HTMLInputElement>, "onChange" | "onInput"> {
  value: Nullable<string>;
  onChange: (value: string, name: string | undefined, e: any) => void;
  onInput?: (value: string) => void;
  name?: string;
  disabled?: boolean;
  locked?: boolean;
  clearable?: boolean;
  ghost?: boolean;
}

export const Input = React.memo(
  React.forwardRef((props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      value,
      onChange: onPropsChange,
      onInput,
      onBlur,
      onKeyDown,
      name,
      disabled,
      locked,
      clearable,
      ghost,
      ...restProps
    } = props;
    const [input, setInput] = useState(value || "");
    useEffect(() => setInput(value || ""), [value]);
    const [isLoading, onChange] = useControlLoading<string>(value || "", onPropsChange);
    const innerRef = useRef<HTMLInputElement | null>(null);

    const handleChange = useCallback(
      (e) => {
        onInput ? onInput(e.target.value) : setInput(e.target.value);
        innerRef.current = e.target;
      },
      [setInput, onInput],
    );

    const handleBlur = useCallback(
      (e) => {
        onBlur?.(e);
        e.target.value = e.target.value.trim();
        onChange(e.target.value, name, e);
        innerRef.current = null;
      },
      [onChange, onBlur, name],
    );

    const handleKeyDown = useCallback(
      (e) => {
        onKeyDown?.(e);
        keyboardDispatcher(e);
        if (e.key === "Enter") {
          e.target.blur();
          return;
        }
        if (locked && clearable && (e.key === "Delete" || e.key === "Backspace")) {
          onChange("", name, e);
          innerRef.current = null;
        }
      },
      [onKeyDown, locked, onChange, name, clearable],
    );

    useEffect(() => {
      return () => {
        innerRef.current && onChange(innerRef.current.value, name, { target: innerRef.current });
      };
    }, [onChange, innerRef, name]);

    return (
      <Control
        ref={ref}
        disabled={disabled || isLoading}
        {...ariaAttributes(isLoading && "busy")}
        value={input}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        readOnly={locked}
        ghost={ghost}
        {...restProps}
      />
    );
  }),
);

const Control = styled.input<{ ghost?: boolean }>(
  ({
    theme: {
      spacings,
      typography,
      components: { control },
    },
    ghost,
  }) => css`
    appearance: none;
    -webkit-appearance: none;
    display: block;
    min-width: 50px;
    font-size: inherit;
    padding: ${spacings.xsmall} ${spacings.medium};
    margin: 0 -${spacings.medium};
    line-height: ${typography.lineHeight};
    min-height: ${control.height};
    max-height: ${control.height};
    font-family: inherit;
    font-weight: inherit;
    border: none;
    cursor: pointer;
    outline: none;
    color: ${ghost ? "inherit" : control.default.color};
    box-shadow: ${ghost ? "none" : `0 0 0 1px ${control.default.borderColor}`};
    background: ${ghost ? "transparent" : control.default.backgroundColor};
    position: relative;

    &:not(:disabled):hover {
      box-shadow: 0 0 0 1px ${ghost ? control.default.borderColor : control.hover.borderColor};
      z-index: 1;
    }

    &:read-only:not(:disabled) {
      color: ${control.readOnly.color};
    }

    &:not(:disabled):focus {
      background: ${control.focus.backgroundColor};
      cursor: text;
      color: ${control.focus.color};
      box-shadow: 0 0 0 1px ${control.focus.borderColor};
      z-index: 2;
    }

    &:read-only:not(:disabled):focus {
      color: ${control.readOnly.color};
      box-shadow: 0 0 0 1px ${control.readOnly.borderColor};
      background: ${control.readOnly.backgroundColor};
      user-select: none;
      cursor: pointer;
    }

    &:disabled {
      color: ${control.disabled.color};
      background: ${control.disabled.backgroundColor};
      box-shadow: 0 0 0 1px ${control.disabled.borderColor};
      cursor: default;
    }

    &[aria-busy="true"] {
      background: ${control.default.backgroundColor};
    }
  `,
);
