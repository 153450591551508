import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ModalContent } from "./modal-content";
import { modalContext } from "./modal.context";
import { closeModal } from "reducers/layout";
import { ModalData, Position, Size } from "types";
import * as Markup from "./modal.styles";

export interface ModalWindowProps extends ModalData {
  position: Position;
  onMove: (path: string, position: Position) => void;
  onFocus: (path: string) => void;
}

export const ModalWindow = React.memo(
  React.forwardRef((props: ModalWindowProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { position: propsPosition, path, params, onMove, onFocus } = props;
    const dispatch = useDispatch();
    const [size, setSize] = useState<Size>();
    const [position, setPosition] = useState(propsPosition);
    useEffect(() => setPosition(propsPosition), [propsPosition]);

    const handleClose = useCallback(() => {
      path && dispatch(closeModal(path));
    }, [dispatch, path]);

    const { Provider } = modalContext;
    const providerValue = useMemo(
      () => ({
        setSize,
        path,
        position: propsPosition,
        onMove: (pos: Position) => setPosition(pos),
        onMoveEnd: (pos: Position) => onMove(path, pos),
        onClose: handleClose,
      }),
      [path, onMove, propsPosition, handleClose],
    );

    const style: React.CSSProperties = useMemo(
      () => ({
        ...position,
        ...size,
      }),
      [size, position],
    );

    const handlePointerDown = useCallback(() => onFocus(path), [path, onFocus]);

    return (
      <Provider value={providerValue}>
        <Markup.Window style={style} ref={ref} onPointerDown={handlePointerDown}>
          <ModalContent {...params} />
        </Markup.Window>
      </Provider>
    );
  }),
);
