import styled from "styled-components";

export const Placeholder = styled.span`
  color: ${(p) => p.theme.colors.content.tertiary};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  flex-basis: 100%;
`;
