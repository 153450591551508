import { useCallback } from "react";
import { useDispatch } from "react-redux";
import useEventListener from "@use-it/event-listener";
import { setZoom, stepZoom, zoomToFit } from "reducers/editor";
import { toggleUI } from "reducers/layout";
import { CTRL_KEY } from "consts.env";

export const useKeyboard = () => {
  const dispatch = useDispatch();

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case "!":
          if (!e.shiftKey) return;
          e.preventDefault();
          dispatch(zoomToFit());
          return;

        case "0":
          if (!e[CTRL_KEY]) return;
          e.preventDefault();
          dispatch(setZoom(1));
          return;

        case "-":
          if (!e[CTRL_KEY]) return;
          e.preventDefault();
          dispatch(stepZoom(-1));
          return;

        case "=":
          if (!e[CTRL_KEY]) return;
          e.preventDefault();
          dispatch(stepZoom(1));
          return;

        case "\\":
          if (!e[CTRL_KEY]) return;
          dispatch(toggleUI());
          return;

        default:
          return;
      }
    },
    [dispatch],
  );

  useEventListener("keydown", handleKeyDown, window);
};
