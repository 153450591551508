import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Binder } from "../binder";
import { useObjectHandlers } from "canvas/use-object-handlers";
import { ariaAttributes } from "utils";
import { BindDirection, schemeModels, StreamType } from "const";
import { ReduxState, EditorState } from "types";
import { IMassLink, IMassStream } from "models";
import * as Markup from "../../canvas.styles";

export const MassStream = React.memo((props: IMassStream) => {
  const { id, type, name, position: defaultPosition } = props;
  const massLinks = useSelector<ReduxState, IMassLink[]>(({ project }) => project?.massLinks || [], shallowEqual);
  const selected = useSelector<ReduxState, EditorState["selected"]>(({ editor }) => editor.selected, shallowEqual);
  const model = useMemo(() => schemeModels[StreamType.Mass], []);
  const [isSelected, isBindSelected, position] = useMemo(
    () =>
      selected && selected.id === id && selected.type === type
        ? [true, Boolean(selected.direction), selected.position]
        : [false, false, defaultPosition],
    [selected, id, type, defaultPosition],
  );

  const handlers = useObjectHandlers(props, defaultPosition);

  const anchors = useMemo(() => {
    if (!model) return null;
    return (
      <>
        <Binder
          parent={props}
          direction={BindDirection.DeviceToStream}
          left={model.width / 2 + model[BindDirection.DeviceToStream][0].offset.left}
          top={model.height / 2 + model[BindDirection.DeviceToStream][0].offset.top}
          index={0}
          azimuth={3}
          isBinded={massLinks.some(
            (massLink) => massLink.massStreamId === id && massLink.direction === BindDirection.DeviceToStream,
          )}
          isSelected={
            isSelected && selected?.direction === BindDirection.DeviceToStream && selected?.connectorIndex === 0
          }
        />
        <Binder
          parent={props}
          azimuth={1}
          direction={BindDirection.StreamToDevice}
          left={model.width / 2 + model[BindDirection.StreamToDevice][0].offset.left}
          top={model.height / 2 + model[BindDirection.StreamToDevice][0].offset.top}
          index={0}
          isBinded={massLinks.some(
            (massLink) => massLink.massStreamId === id && massLink.direction === BindDirection.StreamToDevice,
          )}
          isSelected={
            isSelected && selected?.direction === BindDirection.StreamToDevice && selected?.connectorIndex === 0
          }
        />
      </>
    );
  }, [selected, model, id, props, massLinks, isSelected]);

  if (!model) return null;

  return (
    <Markup.Object
      {...ariaAttributes(isSelected && !isBindSelected && "selected")}
      role="flow"
      key={id}
      transform={`translate(${position.left - model.width / 2}, ${position.top - model.height / 2})`}
      {...handlers}
    >
      {model.source}
      {name && (
        <Markup.Text
          textAnchor="middle"
          x={model.width / 2}
          y={model.height + 12}
          style={{ fontSize: 10, width: model.width }}
        >
          {name}
        </Markup.Text>
      )}
      {anchors}
    </Markup.Object>
  );
});
