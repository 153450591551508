import { parseLocationQuery } from "utils/parse-location-query";
import { addPositions, getProjectBounds, isDevice, isMassStream, subtrackPositions } from "utils";
import { sortSelected, updateDevicePosition, updateMassStreamPosition } from "reducers/project";
import { EditorState, Position, ReduxActions, ReduxState } from "types";
import { Dispatch } from "redux";
import { IDevice, IMassStream } from "models";

const getInitialState = () => {
  const params: Record<string, string> = parseLocationQuery(window.location.search);
  const [left, top, zoom] = params.p ? params.p.split(";").map((s) => (isNaN(+s) ? 0 : +s)) : [0, 0, 1];
  return {
    offset: { left, top },
    zoom,
  };
};

const initialState: EditorState = {
  ...getInitialState(),
  pressedKeys: {},
};

const SET_SELECTED = "editor/SET_SELECTED";
const SET_BINDING = "editor/SET_BINDING";
const SET_VIEWPORT_OFFSET = "editor/SET_VIEWPORT_OFFSET";
const SET_VIEWPORT_ZOOM = "editor/SET_VIEWPORT_ZOOM";
const SET_KEY_PRESSED = "editor/SET_KEY_PRESSED";

interface SetSelectedAction {
  type: typeof SET_SELECTED;
  payload: EditorState["selected"];
}

interface SetBindingAction {
  type: typeof SET_BINDING;
  payload: EditorState["binding"];
}

interface SetViewportOffsetAction {
  type: typeof SET_VIEWPORT_OFFSET;
  position: Position;
}

interface SetViewportZoomAction {
  type: typeof SET_VIEWPORT_ZOOM;
  zoom: number;
}

interface SetKeyPressedAction {
  type: typeof SET_KEY_PRESSED;
  key: string;
  value: boolean;
}

export type EditorActions =
  | SetSelectedAction
  | SetBindingAction
  | SetViewportOffsetAction
  | SetViewportZoomAction
  | SetKeyPressedAction;

export default (state = initialState, action: EditorActions) => {
  switch (action.type) {
    case SET_SELECTED:
      return {
        ...state,
        selected: action.payload,
      };
    case SET_BINDING:
      return {
        ...state,
        binding: action.payload,
      };

    case SET_VIEWPORT_OFFSET:
      return {
        ...state,
        offset: action.position,
      };

    case SET_VIEWPORT_ZOOM:
      return {
        ...state,
        zoom: action.zoom,
      };

    case SET_KEY_PRESSED:
      return {
        ...state,
        pressedKeys: {
          ...state.pressedKeys,
          [action.key]: action.value,
        },
      };

    default:
      return state;
  }
};

export const setKeyPress = (key: string, value: boolean) => ({
  type: SET_KEY_PRESSED,
  key,
  value,
});

export const setSelected = (payload: EditorState["selected"]) => (dispatch: Dispatch<any>) => {
  dispatch({ type: SET_SELECTED, payload });
  payload && dispatch(sortSelected(payload));
};

export const setViewportOffset = (position: Position): SetViewportOffsetAction => ({
  type: SET_VIEWPORT_OFFSET,
  position,
});

export const zoomToFit = () => (dispatch: Dispatch<ReduxActions>, getState: () => ReduxState) => {
  const { devices, massStreams } = getState().project || {};
  const { selected } = getState().editor;
  const { hasUI, editorPosition } = getState().layout;
  const bounds = getProjectBounds({ devices, massStreams, selected });
  if (!bounds) return;

  const boundsSize = {
    width: bounds[1].left - bounds[0].left,
    height: bounds[1].top - bounds[0].top,
  };

  const viewportSize = {
    width: (editorPosition?.width || 0) - (hasUI ? 420 + 236 : 0),
    height: editorPosition?.height || 0,
  };

  const zoom = Math.min(
    1,
    Math.min((viewportSize.width - 50) / boundsSize.width, (viewportSize.height - 50) / boundsSize.height),
  );

  dispatch({
    type: SET_VIEWPORT_ZOOM,
    zoom,
  });

  dispatch({
    type: SET_VIEWPORT_OFFSET,
    position: {
      left: bounds[0].left + boundsSize.width / 2 + (hasUI ? (420 - 236) / 2 / zoom : 0),
      top: bounds[0].top + boundsSize.height / 2,
    },
  });
};

export const setZoom =
  (nextZoom: number, cursor?: Position) => (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const { zoom, offset } = getState().editor;
    const position = getState().layout.editorPosition;
    const hasUI = getState().layout.hasUI;

    const center = {
      left: (position?.left ?? 0) + (position?.width ?? 0) / 2,
      top: (position?.top ?? 0) + (position?.height ?? 0) / 2,
    };

    const centerOffset = subtrackPositions(
      cursor || {
        left: center.left - (hasUI ? (400 - 236) / 2 : 0),
        top: center.top,
      },
      center,
    );

    dispatch({
      type: SET_VIEWPORT_ZOOM,
      zoom: nextZoom,
    });

    dispatch({
      type: SET_VIEWPORT_OFFSET,
      position: addPositions(offset, {
        left: centerOffset.left / zoom - centerOffset.left / nextZoom,
        top: centerOffset.top / zoom - centerOffset.top / nextZoom,
      }),
    });

    dispatch({ type: SET_VIEWPORT_ZOOM, zoom: nextZoom });
  };

export const stepZoom = (step: number) => (dispatch: Dispatch<any>, getState: () => ReduxState) => {
  const zoom = getState().editor.zoom;
  const values = [0.15, 0.3, 0.5, 0.67, 0.8, 0.9, 1, 1.25, 1.5, 2];
  if (step > 0) {
    const nextValue = values.find((item) => item > zoom);
    dispatch(setZoom(nextValue ?? 2));
  }

  if (step < 0) {
    const nextValue = values.reverse().find((item) => item < zoom);
    dispatch(setZoom(nextValue ?? 0.15));
  }
};

export const changeZoom =
  (delta: number, cursor?: Position) => (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const properDelta = navigator.userAgent.indexOf("Macintosh") > -1 ? delta : delta / 5;
    const { zoom } = getState().editor;
    const nextZoom = Math.max(0.15, Math.min(2, zoom * (1 + properDelta / 100)));
    dispatch(setZoom(nextZoom, cursor));
  };

export const updateObjectPosition =
  (projectId: number, object: IMassStream | IDevice, position: Position) => (dispatch: Dispatch<any>) => {
    isMassStream(object) && dispatch(updateMassStreamPosition(projectId, { ...(object as IMassStream), position }));
    isDevice(object) && dispatch(updateDevicePosition(projectId, { ...(object as IDevice), position }));
  };

export const setBinding = (payload: EditorState["binding"]): SetBindingAction => ({
  type: SET_BINDING,
  payload,
});

export const setTargetBingind =
  (payload: EditorState["binding"]) => (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    if (!payload) return;
    const massLinks = getState().project?.massLinks || [];
    const devices = getState().project?.devices || [];
    const massStreams = getState().project?.massStreams || [];

    if (isMassStream(payload)) {
      const massLink = massLinks.find(
        (link) => link.massStreamId === payload.id && link.direction === payload.direction,
      );
      const device = massLink && devices.find((item) => item.id === massLink.deviceId);
      if (!device || !massLink) return;

      dispatch(
        setBinding({
          ...device,
          direction: payload.direction,
          connectorIndex: massLink.connectorIndex,
        }),
      );
    }

    if (isDevice(payload)) {
      const massLink = massLinks.find(
        (link) =>
          link.deviceId === payload.id &&
          link.direction === payload.direction &&
          link.connectorIndex === payload.connectorIndex,
      );
      const stream = massLink && massStreams.find((item) => item.id === massLink.massStreamId);
      if (!stream || !massLink) return;

      dispatch(
        setBinding({
          ...stream,
          direction: payload.direction,
          connectorIndex: 0,
        }),
      );
    }
  };
