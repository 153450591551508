import { IWorksheet } from "models";
import { API_URL } from "consts.env";
import { Condition } from "const";

export const getMassStreamWorksheet = async (
  token: string,
  projectId: number,
  massStreamId: number,
): Promise<IWorksheet> => {
  const response = await fetch(`${API_URL}/${projectId}/stream/${massStreamId}/worksheet`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};

export const getDeviceWorksheet = async (
  token: string,
  projectId: number,
  deviceId: number,
): Promise<{
  deviceId: number;
  deviceName: string;
  streamWorksheets: IWorksheet[];
  conditions: IWorksheet["conditions"];
}> => {
  const response = await fetch(`${API_URL}/${projectId}/device/${deviceId}/worksheet`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};

export interface ApiConditionsPayload {
  attributeType: Condition;
  value: number | null;
  measureType: string | null;
}

export interface ApiComponentPayload {
  feed: number | null;
  componentId: number;
}

export const putMassStreamConditions = async (
  token: string,
  projectId: number,
  id: number,
  payload: ApiConditionsPayload[],
): Promise<IWorksheet> => {
  const response = await fetch(`${API_URL}/${projectId}/stream/${id}/worksheet/condition`, {
    method: "PUT",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};

export const putMassStreamComponent = async (
  token: string,
  projectId: number,
  id: number,
  payload: ApiComponentPayload[],
): Promise<IWorksheet> => {
  const response = await fetch(`${API_URL}/${projectId}/stream/${id}/worksheet/component`, {
    method: "PUT",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};

export const putDeviceConditions = async (
  token: string,
  projectId: number,
  id: number,
  payload: ApiConditionsPayload[],
): Promise<{
  deviceId: number;
  deviceName: string;
  streamWorksheets: IWorksheet[];
  conditions: IWorksheet["conditions"];
}> => {
  const response = await fetch(`${API_URL}/${projectId}/device/${id}/worksheet/condition`, {
    method: "PUT",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};
