import React from "react";
import { ReactComponent as FlowSVG } from "assets/flow.svg";
import { ReactComponent as Separator2phSVG } from "assets/separator_2ph.svg";
import { ReactComponent as Separator3phSVG } from "assets/separator_3ph.svg";
import { ReactComponent as MixerSVG } from "assets/mixer.svg";
import { ReactComponent as ValveSVG } from "assets/valve.svg";
import { ReactComponent as CompressorSVG } from "assets/compressor.svg";
import { ReactComponent as CoolerHeaterSVG } from "assets/cooler_heater.svg";
import { ReactComponent as PumpSVG } from "assets/pump.svg";
import { ReactComponent as ExchangerSVG } from "assets/exchanger.svg";
import { ReactComponent as ExpanderSVG } from "assets/expander.svg";

import { SchemeModel } from "types";

export enum BindDirection {
  StreamToDevice = "StreamToDevice",
  DeviceToStream = "DeviceToStream",
}

export enum StreamType {
  Mass = "Mass",
}

export enum DeviceType {
  Separator2p = "Separator2p",
  Separator3p = "Separator3p",
  Mixer = "Mixer",
  Valve = "Valve",
  Compressor = "Compressor",
  CoolerHeater = "CoolerHeater",
  Pump = "Pump",
  Exchanger = "Exchanger",
  Expander = "Expander",
}

export enum Condition {
  Temperature = "Temperature",
  Pressure = "Pressure",
  VapourPhaseFraction = "VapourPhaseFraction",
  MolarFlow = "MolarFlow",
  MassFlow = "MassFlow",
  HeatFlow = "HeatFlow",
}

export enum Characteristic {
  ActualVolumeFlow = "ActualVolumeFlow",
  AdiabaticExponent = "AdiabaticExponent",
  HHVMassBasis = "HHVMassBasis",
  HeatCapacity = "HeatCapacity",
  KinematicVelocity = "KinematicVelocity",
  LHVMassBasis = "LHVMassBasis",
  MassDensity = "MassDensity",
  MassEnthalpy = "MassEnthalpy",
  HeatOfVaporization = "HeatOfVaporization",
  MolecularWeight = "MolecularWeight",
  PartialPressureOfCO2 = "PartialPressureOfCO2",
  PartialPressureOfH2S = "PartialPressureOfH2S",
  PhaseFractionVolumeBasis = "PhaseFractionVolumeBasis",
  PhaseFractionMassBasis = "PhaseFractionMassBasis",
  VapourFraction = "VapourFraction",
  ReifVapourPressure = "ReifVapourPressure",
  StdGasFlow = "StdGasFlow",
  SurfaceTenstion = "SurfaceTenstion",
  ThermalConductivity = "ThermalConductivity",
  TrueVapourPressure = "TrueVapourPressure",
  Viscosity = "Viscosity",
  ZFactor = "ZFactor",
}

export const schemeModels: Record<StreamType | DeviceType, SchemeModel> = {
  [StreamType.Mass]: {
    type: StreamType.Mass,
    label: "Поток",
    source: <FlowSVG />,
    width: 40,
    height: 24,
    [BindDirection.DeviceToStream]: [{ offset: { left: -22, top: 0 }, azimuth: 3 }],
    [BindDirection.StreamToDevice]: [{ offset: { left: 20, top: 0 }, azimuth: 1 }],
  },
  [DeviceType.Valve]: {
    type: DeviceType.Valve,
    label: "Клапан",
    source: <ValveSVG />,
    width: 36,
    height: 22,
    [BindDirection.StreamToDevice]: [{ offset: { left: -20, top: 0 }, azimuth: 3, label: "Вход" }],
    [BindDirection.DeviceToStream]: [{ offset: { left: 20, top: 0 }, azimuth: 1, label: "Выход" }],
  },
  [DeviceType.Mixer]: {
    type: DeviceType.Mixer,
    label: "Смеситель",
    source: <MixerSVG />,
    width: 38,
    height: 46,
    [BindDirection.StreamToDevice]: [
      { offset: { left: -21, top: -13 }, azimuth: 3 },
      { offset: { left: -21, top: 13 }, azimuth: 3 },
    ],
    [BindDirection.DeviceToStream]: [{ offset: { left: 19, top: 0 }, azimuth: 1, label: "Выход" }],
  },
  [DeviceType.Separator2p]: {
    label: "Сепаратор 2Ф",
    type: DeviceType.Separator2p,
    source: <Separator2phSVG />,
    width: 38,
    height: 84,
    [BindDirection.StreamToDevice]: [{ offset: { left: -21, top: 0 }, azimuth: 3, label: "Вход" }],
    [BindDirection.DeviceToStream]: [
      { offset: { left: 21, top: -20 }, azimuth: 1, label: "Легкие фрак." },
      { offset: { left: 21, top: 20 }, azimuth: 1, label: "Тяжелые фрак." },
    ],
  },
  [DeviceType.Separator3p]: {
    type: DeviceType.Separator3p,
    label: "Сепаратор 3Ф",
    source: <Separator3phSVG />,
    width: 84,
    height: 42,
    [BindDirection.StreamToDevice]: [{ offset: { left: -22, top: -23 }, azimuth: 0, label: "Вход" }],
    [BindDirection.DeviceToStream]: [
      { offset: { left: 22, top: -23 }, azimuth: 0, label: "Пар" },
      { offset: { left: 44, top: 0 }, azimuth: 1, label: "Легкие фрак." },
      { offset: { left: 22, top: 23 }, azimuth: 2, label: "Тяжелые фрак." },
    ],
  },
  [DeviceType.Compressor]: {
    type: DeviceType.Compressor,
    label: "Компрессор",
    source: <CompressorSVG />,
    width: 36,
    height: 36,
    [BindDirection.StreamToDevice]: [{ offset: { left: -20, top: 0 }, azimuth: 3, label: "Вход" }],
    [BindDirection.DeviceToStream]: [{ offset: { left: 20, top: 0 }, azimuth: 1, label: "Выход" }],
  },
  [DeviceType.Expander]: {
    type: DeviceType.Expander,
    label: "Детандер",
    source: <ExpanderSVG />,
    width: 36,
    height: 36,
    [BindDirection.StreamToDevice]: [{ offset: { left: -20, top: 0 }, azimuth: 3, label: "Вход" }],
    [BindDirection.DeviceToStream]: [{ offset: { left: 20, top: 0 }, azimuth: 1, label: "Выход" }],
  },
  [DeviceType.CoolerHeater]: {
    type: DeviceType.Compressor,
    label: "Охладитель/Нагреватель",
    source: <CoolerHeaterSVG />,
    width: 32,
    height: 32,
    [BindDirection.StreamToDevice]: [
      {
        offset: { left: -18, top: 0 },
        azimuth: 3,
        label: "Вход",
      },
    ],
    [BindDirection.DeviceToStream]: [
      {
        offset: { left: 18, top: 0 },
        azimuth: 1,
        label: "Выход",
      },
    ],
  },
  [DeviceType.Pump]: {
    type: DeviceType.Pump,
    label: "Насос",
    source: <PumpSVG />,
    width: 34,
    height: 32,
    [BindDirection.StreamToDevice]: [{ offset: { left: -18, top: 0 }, azimuth: 3, label: "Вход" }],
    [BindDirection.DeviceToStream]: [{ offset: { left: 18, top: 16 }, azimuth: 1, label: "Выход" }],
  },
  [DeviceType.Exchanger]: {
    type: DeviceType.Exchanger,
    label: "Противоточный теплообменник",
    source: <ExchangerSVG />,
    width: 84,
    height: 34,
    [BindDirection.StreamToDevice]: [
      { offset: { left: -33, top: -19 }, azimuth: 0, label: "Вход 1" },
      { offset: { left: -19, top: -19 }, azimuth: 0, label: "Вход 2" },
    ],
    [BindDirection.DeviceToStream]: [
      { offset: { left: -33, top: 19 }, azimuth: 2, label: "Выход 1" },
      { offset: { left: 22, top: 19 }, azimuth: 2, label: "Выход 2" },
    ],
  },
};
