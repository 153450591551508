import React from "react";
import styled, { css, keyframes } from "styled-components";
import * as Icons from "assets/icons";

const rotation = keyframes`
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface Props {
  size?: number;
  color?: string;
  style?: React.CSSProperties;
}

export const Loader = React.memo((props: Props) => {
  const { size = 24, color, style } = props;
  return (
    <Wrapper $color={color} style={style}>
      <Icons.Loader width={size} height={size} />
    </Wrapper>
  );
});

const Wrapper = styled.span<{ $color?: string }>(
  ({ theme: { colors }, $color }) => css`
    fill: ${$color || colors.content.tertiary};
    stroke: ${$color || colors.content.tertiary};
    display: flex;
    align-items: center;
    justify-content: center;
    animation-name: ${rotation};
    animation-duration: 2s;
    animation-iteration-count: infinite;

    > svg {
      display: block;
    }
  `,
);
