import { ReduxState } from "types";
import { IWorksheet } from "models";
import { Dispatch } from "redux";
import { ApiComponentPayload, ApiConditionsPayload } from "services/worksheet";
import * as worksheetApi from "services/worksheet";

const initialState: ReduxState["details"] = {
  massStreams: {},
  devices: {},
};

const SET_MASS_STREAM_WORKSHEET = "details/SET_MASS_STREAM_WORKSHEET";
const SET_DEVICE_WORKSHEET_LINKS = "details/SET_DEVICE_WORKSHEET_LINKS";

interface SetMassStreamWorksheetAction {
  type: typeof SET_MASS_STREAM_WORKSHEET;
  massStreamId: number;
  worksheet: IWorksheet | null;
}

interface SetDeviceWorksheetLinksAction {
  type: typeof SET_DEVICE_WORKSHEET_LINKS;
  deviceId: number;
  worksheetLinks: number[];
  conditions: IWorksheet["conditions"];
}

export type DetailsActions = SetMassStreamWorksheetAction | SetDeviceWorksheetLinksAction;

export default (state = initialState, action: DetailsActions) => {
  switch (action.type) {
    case SET_MASS_STREAM_WORKSHEET: {
      return {
        ...state,
        massStreams: {
          ...state.massStreams,
          [action.massStreamId]: {
            ...state.massStreams[action.massStreamId],
            worksheet: action.worksheet,
          },
        },
      };
    }

    case SET_DEVICE_WORKSHEET_LINKS: {
      return {
        ...state,
        devices: {
          ...state.devices,
          [action.deviceId]: {
            ...state.devices[action.deviceId],
            worksheetLinks: action.worksheetLinks,
            conditions: action.conditions,
          },
        },
      };
    }

    default:
      return state;
  }
};

export const loadMassStreamWorksheet =
  (projectId: number, massStreamId: number) => async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const token = getState().auth.token;
    if (!token) return;

    try {
      const worksheet = await worksheetApi.getMassStreamWorksheet(token, projectId, massStreamId);
      dispatch({
        type: SET_MASS_STREAM_WORKSHEET,
        massStreamId,
        worksheet,
      });
    } catch {
      dispatch({
        type: SET_MASS_STREAM_WORKSHEET,
        massStreamId,
        worksheet: null,
      });
    }
  };

export const loadDeviceWorksheet =
  (projectId: number, deviceId: number) => async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const token = getState().auth.token;
    if (!token) return;

    try {
      const data = await worksheetApi.getDeviceWorksheet(token, projectId, deviceId);
      data.streamWorksheets.forEach((sheet) => {
        dispatch({
          type: SET_MASS_STREAM_WORKSHEET,
          massStreamId: sheet.massStreamId,
          worksheet: sheet,
        });
      });

      dispatch({
        type: SET_DEVICE_WORKSHEET_LINKS,
        deviceId,
        worksheetLinks: data.streamWorksheets.map((item) => item.massStreamId),
        conditions: data.conditions,
      });
    } catch {}
  };

export const setMassStreamCondition =
  (projectId: number, massStreamId: number, payload: ApiConditionsPayload) =>
  async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const token = getState().auth.token;
    if (!token) return;

    const date = Date.now().toString();
    const key = `setDeviceCondition_${projectId}_${massStreamId}`;

    try {
      localStorage.setItem(key, date);
      const worksheet = await worksheetApi.putMassStreamConditions(token, projectId, massStreamId, [payload]);
      if (localStorage.getItem(key) !== date) return;

      dispatch({
        type: SET_MASS_STREAM_WORKSHEET,
        massStreamId,
        worksheet,
      });

      localStorage.removeItem(key);
    } catch {
      localStorage.getItem(key) === date && localStorage.removeItem(key);
    }
  };

export const setDeviceCondition =
  (projectId: number, deviceId: number, payload: ApiConditionsPayload) =>
  async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const token = getState().auth.token;
    if (!token) return;

    const date = Date.now().toString();
    const key = `setDeviceCondition_${projectId}_${deviceId}`;

    try {
      localStorage.setItem(key, date);
      const data = await worksheetApi.putDeviceConditions(token, projectId, deviceId, [payload]);
      if (localStorage.getItem(key) !== date) return;

      data.streamWorksheets.forEach((sheet) => {
        dispatch({
          type: SET_MASS_STREAM_WORKSHEET,
          massStreamId: sheet.massStreamId,
          worksheet: sheet,
        });
      });

      dispatch({
        type: SET_DEVICE_WORKSHEET_LINKS,
        deviceId,
        worksheetLinks: data.streamWorksheets.map((item) => item.massStreamId),
        conditions: data.conditions,
      });

      localStorage.removeItem(key);
    } catch {
      localStorage.getItem(key) === date && localStorage.removeItem(key);
    }
  };

export const setMassStreamComponent =
  (projectId: number, massStreamId: number, payload: ApiComponentPayload) =>
  async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const token = getState().auth.token;
    if (!token) return;

    const date = Date.now().toString();
    const key = `setMassStreamComponent_${projectId}_${massStreamId}`;

    try {
      localStorage.setItem(key, date);
      const worksheet = await worksheetApi.putMassStreamComponent(token, projectId, massStreamId, [payload]);
      if (localStorage.getItem(key) !== date) return;

      dispatch({
        type: SET_MASS_STREAM_WORKSHEET,
        massStreamId,
        worksheet,
      });

      localStorage.removeItem(key);
    } catch {
      localStorage.getItem(key) === date && localStorage.removeItem(key);
    }
  };
