import { IProject, IProjectData } from "models";
import { API_URL } from "consts.env";

export const getProjects = async (token: string): Promise<IProject[]> => {
  const response = await fetch(`${API_URL}/project`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};

export const postProject = async (token: string, name: string): Promise<IProject> => {
  const response = await fetch(`${API_URL}/project`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({ name }),
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};

export const putProject = async (token: string, payload: IProject): Promise<IProject> => {
  const response = await fetch(`${API_URL}/project`, {
    method: "PUT",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};

export const deleteProject = async (token: string, id: number): Promise<boolean> => {
  const response = await fetch(`${API_URL}/project?id=${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });

  if (response.status === 200) {
    return true;
  } else {
    throw await response.json();
  }
};

export const getProject = async (token: string, id: number): Promise<IProjectData> => {
  const response = await fetch(`${API_URL}/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};
