import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isDevice, isMassStream } from "utils";
import { setBinding, setSelected } from "reducers/editor";
import { removeDevice, removeMassLink, removeMassStream } from "reducers/project";
import { IMassLink } from "models";
import { EditorState, ProjectRouteParams, ReduxState } from "types";
import { CTRL_KEY } from "consts.env";

export const useCanvasKeyboard = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams<ProjectRouteParams>();
  const { selected, binding } = useSelector<ReduxState, EditorState>(({ editor }) => editor, shallowEqual);
  const massLinks = useSelector<ReduxState, IMassLink[]>(({ project }) => project?.massLinks || [], shallowEqual);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      switch (e.key) {
        case "c":
        case "с":
          if (!e[CTRL_KEY] || !selected) return;
          // TODO: COPY
          return;

        case "v":
        case "м":
          if (!e[CTRL_KEY] || !selected) return;
          // TODO: PASTE
          return;

        case "Backspace":
        case "Delete":
          if (!selected) return;
          if (!selected.direction) {
            isDevice(selected) && dispatch(removeDevice(+projectId, selected.id));
            isMassStream(selected) && dispatch(removeMassStream(+projectId, selected.id));
            return;
          }
          isDevice(selected) &&
            dispatch(
              removeMassLink(
                +projectId,
                massLinks.find(
                  (massLink) =>
                    massLink.direction === selected.direction &&
                    massLink.deviceId === selected.id &&
                    massLink.connectorIndex === selected.connectorIndex,
                )?.id,
              ),
            );
          isMassStream(selected) &&
            dispatch(
              removeMassLink(
                +projectId,
                massLinks.find(
                  (massLink) => massLink.direction === selected.direction && massLink.massStreamId === selected.id,
                )?.id,
              ),
            );
          return;

        case "Escape":
          if (!binding) return;
          dispatch(setBinding(undefined));
          dispatch(setSelected(undefined));
          return;

        default:
          return;
      }
    },
    [selected, binding, dispatch, massLinks, projectId],
  );

  return { onKeyDown };
};
