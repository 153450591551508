import styled, { css } from "styled-components";
import { ScrollbarProps } from "components/scroller/scoller.types";

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  justify-content: stretch;
  max-width: 100%;
  max-height: 100%;
`;

export const Container = styled.div<{ style?: React.CSSProperties }>`
  overflow: scroll;
  flex-grow: 1;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollShaft = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const getScrollbarStyle = (p: Pick<ScrollbarProps, "hasMargin" | "type">) => {
  const { hasMargin, type } = p;

  switch (type) {
    case "horizontal":
      return css`
        left: 0;
        bottom: 0;
        right: ${hasMargin ? 6 : 0}px;
        height: 8px;
      `;

    default:
      return css`
        top: 0;
        right: 0;
        bottom: ${hasMargin ? 6 : 0}px;
        width: 8px;
      `;
  }
};

export const Scrollbar = styled.div<Pick<ScrollbarProps, "isVisible" | "hasMargin" | "type"> & { isActive?: boolean }>(
  ({ theme: { colors, transitions }, isActive, isVisible, hasMargin, type }) => css`
    position: absolute;
    border: 2px solid transparent;
    border-radius: 4px;
    pointer-events: auto;
    z-index: 10;
    ${getScrollbarStyle({ hasMargin, type })};

    > ${ScrollShaft} {
      border-radius: 2px;
      background: ${colors.content.tertiary}66;
      opacity: ${isVisible ? 1 : 0};
      transition-property: opacity, background;
      transition-duration: ${transitions.short};
    }

    &:hover > ${ScrollShaft} {
      background: ${colors.content.tertiary};
    }

    ${isActive &&
    css`
      & > ${ScrollShaft}, &:hover > ${ScrollShaft} {
        background: ${colors.content.secondary};
      }
    `}
  `,
);
