import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Input } from "components";
import { saveDevice, saveMassStream } from "reducers/project";
import { updateProperties } from "properties/properties.helpers";
import { isDevice, isMassStream, useControlLoading } from "utils";
import { ProjectRouteParams } from "types";
import { IDevice, IMassStream } from "models";

interface Props {
  objectData?: IDevice | IMassStream;
  params: ProjectRouteParams;
}

export const PropertiesName = React.memo((props: Props) => {
  const { objectData, params } = props;
  const { projectId } = params;
  const dispatch = useDispatch();
  const [input, setInput] = useState(objectData?.name || "");
  useEffect(() => setInput(objectData?.name || ""), [objectData]);

  const handleInput = useCallback((value) => {
    setInput(value || "");
  }, []);

  const onChangeDispatched = useCallback(
    (value: string, name: string) => {
      isDevice(objectData) &&
        dispatch(saveDevice(+projectId, updateProperties<IDevice>(objectData as IDevice, value, name)));
      isMassStream(objectData) &&
        dispatch(saveMassStream(+projectId, updateProperties<IMassStream>(objectData as IMassStream, value, name)));
    },
    [objectData, projectId, dispatch],
  );

  const [isLoading, onChange] = useControlLoading(objectData?.name || "", onChangeDispatched);

  const handleChange = useCallback(
    (value: string, name?: string) => {
      if (!value) return setInput(objectData?.name || "");
      onChange(value, name);
    },
    [onChange, objectData?.name],
  );

  return <Input name="name" onChange={handleChange} onInput={handleInput} value={input} disabled={isLoading} ghost />;
});
