import React, { HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Scroller, Placeholder } from "components";
import { ariaAttributes, useControlLoading } from "utils";
import { keyboardDispatcher } from "project";
import * as Markup from "./select.styles";

export type SelectList = Nullable<{ label: string; value: string }[]>;

export interface SelectProps extends Omit<HTMLAttributes<HTMLInputElement>, "onChange" | "onInput"> {
  onChange: (value: string, e?: React.ChangeEvent) => void;
  value: string;
  disabled?: boolean;
  list: SelectList;
}

export const Select = React.memo((props: SelectProps) => {
  const { value, disabled, onChange: propsOnChange, list, ...restProps } = props;
  const [input, setInput] = useState("");
  const [query, setQuery] = useState("");
  const [cursor, setCursor] = useState(-1);
  const [isLoading, onChange] = useControlLoading<string>(value, propsOnChange);
  const wrapperRef = useRef<HTMLSpanElement | null>(null);
  const listRef = useRef<HTMLSpanElement | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isSelectedRef = useRef(false);
  const cursorRef = useRef(cursor);

  useEffect(() => {
    cursorRef.current = cursor;
  }, [cursor]);

  const filtered = useMemo(() => {
    return list?.filter((x) => x.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }, [list, query]);

  useEffect(() => {
    setInput(list?.find((item) => item.value === value)?.label || "");
    const nextCursor = list?.findIndex((item) => item.value === value) ?? -1;
    setCursor(nextCursor);
  }, [list, value]);

  const handleInput = useCallback((e) => {
    setInput(e.target.value);
    setQuery(e.target.value);
    setCursor(e.target.value ? 0 : -1);
  }, []);

  const handleItemClick = useCallback(
    (e) => {
      onChange(e.target.getAttribute("data-value"));
      setInput(e.target.innerText);
      setQuery("");
      wrapperRef.current?.focus();
    },
    [onChange, wrapperRef],
  );

  const handleItemPointerMove = useCallback((e) => {
    setCursor(+e.target.getAttribute("data-index"));
  }, []);

  const navigateList = useCallback(
    (index: number) => {
      const child = listRef.current?.children[index] as HTMLSpanElement;
      if (!child || !scrollRef.current) return index;
      const childRect = child.getBoundingClientRect();
      const rect = scrollRef.current.getBoundingClientRect();

      if (childRect.bottom > rect.bottom) {
        scrollRef.current.scrollTo(
          scrollRef.current.scrollLeft,
          scrollRef.current.scrollTop + childRect.bottom - rect.bottom,
        );
      }

      if (childRect.top < rect.top) {
        scrollRef.current.scrollTo(
          scrollRef.current.scrollLeft,
          scrollRef.current.scrollTop + childRect.top - rect.top,
        );
      }

      return index;
    },
    [scrollRef],
  );

  const handleKeyDown = useCallback(
    (e) => {
      keyboardDispatcher(e);

      switch (e.key) {
        case "Backspace":
        case "Delete":
          if (document.activeElement !== wrapperRef.current) return;
          onChange("", e);
          setInput("");
          setQuery("");
          setCursor(-1);
          wrapperRef.current?.focus();
          return;

        case "ArrowUp":
          e.preventDefault();
          setCursor((_cursor) => navigateList(_cursor > 0 ? _cursor - 1 : (filtered?.length || 1) - 1));
          return;
        case "ArrowDown":
          e.preventDefault();
          setCursor((_cursor) => navigateList(_cursor < (filtered?.length || 1) - 1 ? _cursor + 1 : 0));
          return;

        case "Enter":
          if (document.activeElement === wrapperRef.current) {
            inputRef.current?.focus();
            return;
          }

          const item = filtered?.[cursorRef.current];
          if (item) {
            onChange(item.value, e);
            setInput(item.label);
            setQuery("");
            isSelectedRef.current = true;
          }
          wrapperRef.current?.focus();
          return;

        case "Escape":
          wrapperRef.current?.focus();
          return;
        default:
          return;
      }
    },
    [onChange, filtered, cursorRef, wrapperRef, inputRef, isSelectedRef, navigateList],
  );

  const handleControlBlur = useCallback(
    (e) => {
      scrollRef.current?.scrollTo(0, 0);
      if (isSelectedRef.current) return;
      if (!e.target.value) return onChange("", e);
      setInput(list?.find((item) => item.value === value)?.label || "");
      setCursor(list?.findIndex((item) => item.value === value) ?? -1);
      setQuery("");
    },
    [isSelectedRef, scrollRef, onChange, list, value],
  );

  const handleControlFocus = useCallback(
    (e) => {
      isSelectedRef.current = false;
      const element = e.target;
      setTimeout(() => {
        element.setSelectionRange(0, e.target.value.length);
      });
      setCursor(navigateList);
    },
    [isSelectedRef, navigateList],
  );

  return (
    <Markup.Wrapper
      onKeyDown={handleKeyDown}
      onPointerDown={() => {
        isSelectedRef.current = true;
      }}
      tabIndex={0}
      ref={wrapperRef}
      {...ariaAttributes((!list || isLoading || disabled) && "disabled", isLoading && "busy")}
    >
      <Markup.Control
        value={input}
        onInput={handleInput}
        disabled={!list || isLoading || disabled}
        {...ariaAttributes(isLoading && "busy")}
        ref={inputRef}
        onBlur={handleControlBlur}
        onFocus={handleControlFocus}
        {...restProps}
      />
      <Markup.Popup tabIndex={1}>
        <Scroller style={{ padding: 1 }} ref={scrollRef}>
          <Markup.List ref={listRef}>
            {filtered?.length ? (
              filtered.map((item, key) => (
                <span
                  role="option"
                  key={item.value}
                  {...ariaAttributes(item.value === value && "selected", cursor === key && "current")}
                  data-value={item.value}
                  data-index={key}
                  onClick={handleItemClick}
                  onPointerMove={handleItemPointerMove}
                >
                  {item.label}
                </span>
              ))
            ) : (
              <Placeholder>Не найдено</Placeholder>
            )}
          </Markup.List>
        </Scroller>
      </Markup.Popup>
    </Markup.Wrapper>
  );
});
