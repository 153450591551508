import React, { useMemo } from "react";
import { Scroller } from "components/scroller";
import * as Markup from "components/modal/modal.styles";

export interface ModalBodyProps {
  children: React.ReactNode;
  width?: number;
  height?: number;
}

export const ModalBody = React.memo((props: ModalBodyProps) => {
  const { children, width, height } = props;

  const style = useMemo(
    () => ({
      minWidth: width ?? "unset",
      maxWidth: width ?? "unset",
      minHeight: height ?? "unset",
      maxHeight: height ?? "unset",
    }),
    [width, height],
  );

  return (
    <Markup.Body style={style}>
      <Scroller>
        <Markup.Content>{children}</Markup.Content>
      </Scroller>
    </Markup.Body>
  );
});
