import React, { useCallback, useEffect, useState } from "react";
import useEventListener from "@use-it/event-listener";

export interface ScollerContext {
  containerRect?: DOMRect;
  scrollRef?: React.RefObject<HTMLDivElement>;
}

export const scrollerContext = React.createContext<ScollerContext>({});

export const useScrollerProvider = (params: { scrollRef: React.RefObject<HTMLDivElement> }) => {
  const { scrollRef } = params;
  const { Provider } = scrollerContext;
  const [providerValue, setProviderValue] = useState<ScollerContext>({});

  const handleResize = useCallback(() => {
    setProviderValue((currentValue) =>
      scrollRef.current
        ? {
            ...currentValue,
            containerRect: scrollRef.current.getBoundingClientRect(),
          }
        : currentValue,
    );
  }, [scrollRef]);

  useEffect(handleResize, [handleResize]);
  useEventListener("resize", handleResize, window);
  useEffect(() => setProviderValue((currentValue) => ({ ...currentValue, scrollRef })), [scrollRef]);

  return { Provider, providerValue };
};
