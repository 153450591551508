import styled, { css } from "styled-components";

export const Project = styled.span(
  ({ theme: { colors, elevations, spacings, components } }) => css`
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    border-radius: ${components.button.borderRadius};
    color: ${colors.content.primary};
    background: ${colors.white};
    box-shadow: ${elevations.button.default};
    transition-property: box-shadow, transform, fill, stroke;
    transition-duration: 0.1s;
    text-decoration: none;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;
    gap: ${spacings.medium};

    &:hover {
      background: #ffffff;
      color: ${colors.content.accent};
      box-shadow: ${elevations.button.hover};
      transform: translateY(-1px);
    }

    &:active:not(:disabled) {
      transform: none;
      box-shadow: ${elevations.button.active};
    }
  `,
);

export const Delete = styled.span(
  ({ theme: { colors, spacings } }) => css`
    font-size: 16px;
    line-height: 16px;
    padding-bottom: 2px;
    position: absolute;
    right: ${spacings.medium + spacings.xsmall}px;
    bottom: ${spacings.medium + spacings.xsmall}px;
    background: ${colors.content.tertiary}44;
    color: ${colors.content.secondary};
    cursor: pointer;
    min-width: 20px;
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    z-index: 2;

    &:hover {
      background: ${colors.danger};
      color: ${colors.white};
    }
  `,
);
export const Name = styled.span(
  ({ theme: { typography, colors, spacings } }) => css`
    padding: ${spacings.large};
    padding-top: ${spacings.medium + spacings.small}px;
    font-weight: ${typography.fontWeight.bold};
    background-image: linear-gradient(to top, ${colors.white}00, ${colors.white} 20px);
    position: relative;
    z-index: 1;
  `,
);
export const Caption = styled.span(
  ({ theme: { typography, colors, spacings } }) => css`
    ${{ ...typography.caption }};
    color: ${colors.content.tertiary};
    font-weight: 400;
    padding: ${spacings.large} 50px ${spacings.medium + spacings.small}px ${spacings.large};
    background-image: linear-gradient(to bottom, ${colors.white}00, ${colors.white} 20px);
    position: relative;
    z-index: 1;
  `,
);

export const Container = styled.div`
  flex-basis: 100%;
  padding: 5% 0 2% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: ${(p) => p.theme.colors.page};
  left: 10%;
  top: 7%;

  > svg {
    width: 300px;
    height: 120px;
    filter: drop-shadow(0 1px 0 #ffffff);
    fill: ${(p) => p.theme.colors.content.primary};
  }
`;

export const Projects = styled.div`
  padding: 20px;
  width: 90%;
  max-width: 1400px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;
