import { API_URL } from "consts.env";
import { ApiResponse } from "services/types";

export const bindMassDevice = async (
  token: string,
  payload: {
    projectId: number;
    deviceId: number;
    massStreamId: number;
    connectorIndex: number;
  },
): Promise<ApiResponse> => {
  const { projectId, deviceId, massStreamId, connectorIndex } = payload;
  const response = await fetch(
    `${API_URL}/${projectId}/device/${deviceId}/massStream/${massStreamId}/link?connector=${connectorIndex}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    },
  );

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};

export const bindMassStream = async (
  token: string,
  payload: {
    projectId: number;
    deviceId: number;
    massStreamId: number;
    connectorIndex: number;
  },
): Promise<ApiResponse> => {
  const { projectId, deviceId, massStreamId, connectorIndex } = payload;
  const response = await fetch(
    `${API_URL}/${projectId}/massStream/${massStreamId}/device/${deviceId}/link?connector=${connectorIndex}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    },
  );

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};

export const deleteMassLink = async (token: string, projectId: number, id: number): Promise<ApiResponse> => {
  const response = await fetch(`${API_URL}/${projectId}/massLinks/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};
