import React, { useCallback, useEffect, useMemo, useState } from "react";
import { InputProps, Input } from "components/input";
import { useControlLoading } from "utils";

interface Props extends Omit<InputProps, "value" | "onChange"> {
  value: number | null;
  onChange: (value: number | null, name: string, id: number) => void;
  clearable?: boolean;
}

export const WorksheetInput = React.memo((props: Props) => {
  const { value, onChange: propsOnChange, disabled, onFocus, locked, clearable, ...restProps } = props;
  const [input, setInput] = useState(typeof value === "number" ? value.toString() : clearable ? "" : "0");
  useEffect(() => setInput(typeof value === "number" ? value.toString() : ""), [value]);
  const [isLoading, onChange] = useControlLoading<number | null>(value, propsOnChange);

  const handleInput = useCallback((val) => {
    const re = /^[-]?(\d+[.|,]?)?\d*?$/;
    if (!val) setInput("");
    if (re.test(val)) setInput(val.replace(",", "."));
  }, []);

  const handleChange = useCallback(
    (val: string, name, e) => {
      const numVal = val && typeof +val === "number" && !isNaN(+val) ? +val : clearable ? null : 0;
      !clearable && !val && setInput("0");
      onChange?.(numVal, name, +e.target.getAttribute("data-id"));
    },
    [onChange, clearable],
  );

  const handleFocus = useCallback(
    (e) => {
      onFocus?.(e);
      const element = e.target;
      window.setTimeout(() => {
        !locked && element?.setSelectionRange(0, e.target.value.length);
      });
    },
    [onFocus, locked],
  );

  return (
    <Input
      {...restProps}
      locked={locked}
      onFocus={handleFocus}
      disabled={disabled || isLoading}
      aria-busy={disabled || isLoading}
      value={input || ""}
      onChange={handleChange}
      onInput={handleInput}
      style={useMemo(() => ({ textAlign: "right", margin: 0, fontWeight: 700 }), [])}
    />
  );
});
