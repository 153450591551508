import React, { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
  isPrimary?: boolean;
}

export const Button = React.memo((props: ButtonProps) => {
  return <Container {...props} />;
});

const Container = styled.button<{ isPrimary?: boolean }>(
  ({ theme: { colors, spacings, typography, elevations, transitions, components }, isPrimary }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    padding: ${spacings.medium} ${spacings.large};
    line-height: ${typography.lineHeight};
    font-size: ${typography.fontSize};
    letter-spacing: ${typography.letterSpacing};
    color: ${colors.content.primary};
    background: ${colors.white};
    border: none;
    appearance: none;
    border-radius: ${components.button.borderRadius};
    gap: ${spacings.small};
    cursor: pointer;
    box-shadow: ${elevations.button.default};
    font-weight: ${isPrimary ? 500 : 400};
    transition-property: box-shadow, transform, color, background-color;
    transition-duration: ${transitions.button};

    &:hover {
      box-shadow: ${elevations.button.hover};
      transform: translateY(-0.5px);
      color: ${colors.accent};
    }

    &:focus-visible {
      box-shadow: ${elevations.button.hover}, 0 0 0 3px ${colors.accent}66;
      transform: translateY(-0.5px);
      color: ${colors.accent};
    }

    &:active {
      color: ${colors.content.primary};
      box-shadow: ${elevations.button.active};
      transform: none;
      background: ${colors.container.active.backgroundColor};
    }
  `,
);
