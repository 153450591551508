import { useCallback, useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  loadDeviceWorksheet,
  loadMassStreamWorksheet,
  setDeviceCondition,
  setMassStreamComponent,
  setMassStreamCondition,
} from "reducers/details";
import { BindDirection, Condition } from "const";
import { ProjectRouteParams, ReduxState } from "types";
import { IProjectData, IWorksheet } from "models";
import { WorksheetProps } from "components/worksheet/worksheet";

export const useWorksheets = (
  params: ProjectRouteParams,
): [
  {
    components: WorksheetProps;
    conditions: WorksheetProps;
    characteristics: WorksheetProps;
  },
  Nullable<WorksheetProps>,
] => {
  const { projectId, massStreamId, deviceId } = params;
  const dispatch = useDispatch();
  const projectData = useSelector<ReduxState, Nullable<IProjectData>>(({ project }) => project, shallowEqual);
  const massStreamIds = useSelector<ReduxState, number[]>(
    ({ project }) =>
      massStreamId
        ? [+massStreamId]
        : ((deviceId && project?.massLinks) || [])
            .filter((link) => link.deviceId.toString() === deviceId)
            .map((link) => link.massStreamId),
    shallowEqual,
  );

  useEffect(() => {
    massStreamId && dispatch(loadMassStreamWorksheet(+projectId, +massStreamId));
    deviceId && dispatch(loadDeviceWorksheet(+projectId, +deviceId));
  }, [dispatch, projectId, massStreamId, deviceId, massStreamIds]);

  const worksheets = useSelector<ReduxState, Nullable<Nullable<IWorksheet>[]>>(({ details }) => {
    return massStreamId
      ? [details.massStreams[+massStreamId]?.worksheet]
      : deviceId
      ? details.devices[+deviceId]?.worksheetLinks.map((id) => details.massStreams[id].worksheet)
      : undefined;
  }, shallowEqual);

  const deviceConditions = useSelector<ReduxState, Nullable<IWorksheet["conditions"]>>(
    ({ details }) => (deviceId ? details.devices[+deviceId]?.conditions : null),
    shallowEqual,
  );

  const handleComponentChange = useCallback(
    (feed: number | null, name: string, id: number) => {
      dispatch(setMassStreamComponent(+projectId, id, { feed, componentId: +name }));
    },
    [dispatch, projectId],
  );

  const handleConditionChange = useCallback(
    (value: number | null, name: string, id: number) => {
      dispatch(
        setMassStreamCondition(+projectId, id, {
          value,
          attributeType: name as Condition,
          measureType: null,
        }),
      );
    },
    [dispatch, projectId],
  );

  const handleDeviceConditionChange = useCallback(
    (value: number | null, name: string, id: number) => {
      dispatch(
        setDeviceCondition(+projectId, id, {
          value,
          attributeType: name as Condition,
          measureType: null,
        }),
      );
    },
    [dispatch, projectId],
  );

  return [
    useMemo(() => {
      if (!projectData || !worksheets)
        return {
          components: {},
          conditions: {},
          characteristics: {},
        };

      return {
        components: {
          list: projectData.massComponents.map((item) => ({
            name: item.id.toString(),
            label: item.name,
            measure: item.measure,
          })),
          data: worksheets.map((sheet) => ({
            id: sheet?.massStreamId,
            name: sheet?.massStreamName,
            disabled: Boolean(
              massStreamId &&
                projectData.massLinks.some(
                  (link) => link.massStreamId === +massStreamId && link.direction === BindDirection.DeviceToStream,
                ),
            ),
            values: projectData.massComponents.map((item) => ({
              value: sheet?.components[item.id]?.feed ?? null,
            })),
          })),
          onChange: handleComponentChange,
        },
        conditions: {
          list: projectData.conditions.map((item) => ({
            name: item.attributeType,
            label: item.name,
            measure: item.measure,
          })),
          data: worksheets.map((sheet) => ({
            id: sheet?.massStreamId,
            name: sheet?.massStreamName,
            values: projectData.conditions.map((item) => ({
              value: sheet?.conditions[item.attributeType].value ?? null,
              isCalculated: sheet?.conditions[item.attributeType].isCalculated,
              clearable: true,
            })),
          })),
          onChange: handleConditionChange,
        },
        characteristics: {
          list: projectData.characteristics.map((item) => ({
            name: item.attributeType,
            label: item.name,
            measure: item.measure,
          })),
          data: worksheets.map((sheet) => ({
            id: sheet?.massStreamId,
            name: sheet?.massStreamName,
            values: projectData.characteristics.map((item) => ({
              value: sheet?.characteristics[item.attributeType].value ?? null,
              isCalculated: sheet?.characteristics[item.attributeType].isCalculated,
            })),
          })),
        },
      };
    }, [projectData, massStreamId, worksheets, handleConditionChange, handleComponentChange]),

    useMemo(() => {
      if (!deviceId) return null;
      if (!deviceConditions) return null;
      if (!Object.values(deviceConditions).length) return null;

      return {
        list: Object.values(deviceConditions).map((item) => ({
          name: item.attributeType as string,
          label: item.name,
          measure: item.measure,
        })),
        data: [
          {
            id: +deviceId,
            values: Object.values(deviceConditions).map((item) => ({
              value: item.value,
              isCalculated: item.isCalculated,
            })),
          },
        ],
        onChange: handleDeviceConditionChange,
      };
    }, [deviceConditions, deviceId, handleDeviceConditionChange]),
  ];
};
