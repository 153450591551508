import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { ComponentsList, Toolbox } from "panels";
import { Canvas } from "canvas";
import { Properties } from "properties";
import { Scroller } from "components";
import { ProjectRouter } from "./project-router";
import { useKeyboard } from "./use-keyboard";
import { loadProjectData, unloadProjectData } from "reducers/project";
import { setBinding, setSelected, setViewportOffset, setZoom } from "reducers/editor";
import { ProjectRouteParams, ReduxState } from "types";
import { useSavePreview } from "project/use-save-preview";

export const Project = React.memo(() => {
  const { projectId } = useParams<ProjectRouteParams>();
  const hasUI = useSelector<ReduxState, boolean>(({ layout }) => Boolean(layout.hasUI));
  const dispatch = useDispatch();
  useKeyboard();
  useSavePreview();

  useEffect(() => {
    dispatch(loadProjectData(+projectId));
    const [left, top, zoom] = JSON.parse(localStorage.getItem(`preview_${projectId}`) || "{}").p || [0, 0, 1];
    dispatch(setZoom(zoom));
    dispatch(setViewportOffset({ left, top }));

    return () => {
      dispatch(unloadProjectData(+projectId));
      dispatch(setSelected(undefined));
      dispatch(setBinding(undefined));
    };
  }, [dispatch, projectId]);

  return (
    <Container key={projectId}>
      <ProjectRouter />
      <Canvas />
      <PanelLayout width={236} align={"left"} isVisible={hasUI}>
        <Scroller>
          <Toolbox />
          <ComponentsList />
        </Scroller>
      </PanelLayout>
      <PanelLayout width={420} align={"right"} isVisible={hasUI}>
        <Scroller>
          <Properties />
        </Scroller>
      </PanelLayout>
    </Container>
  );
});

const Container = styled.div`
  flex-basis: 100%;
  position: relative;
  overflow: hidden;
  padding: 8px 0;
`;

interface PanelLayoutProps {
  isVisible?: boolean;
  align: "left" | "right";
  width: number;
}

const PanelLayout = styled.div<PanelLayoutProps>(
  ({ theme, isVisible, align, width }) => css`
    width: ${width}px;
    position: absolute;
    top: 0;
    bottom: 0;
    ${align}: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: ${theme.colors.page};
    box-shadow: ${align === "left" ? 1 : -1}px 0 0 ${theme.colors.border.secondary};
    transition: transform;
    transition-duration: ${(p) => p.theme.transitions.short};

    ${!isVisible &&
    css`
      transform: translateX(${align === "left" ? -100 : 100}%);
    `};
  `,
);
