import { BindDirection, schemeModels } from "const";
import { ReduxState } from "types";
import { IMagnet } from "canvas/components/binding/binding";
import { isMassStream } from "utils";

const getInputOutputElements = (
  object: ReduxState["editor"]["binding"],
  magnet: IMagnet,
): [IMagnet, IMagnet] | undefined => {
  const modelParams = object && schemeModels[object.type]?.[object.direction];
  const bindProps = modelParams && modelParams[Math.min(object.connectorIndex, modelParams.length - 1)];
  if (!bindProps) return undefined;
  const sourceMagnet = {
    ...object,
    ...bindProps,
  };

  return sourceMagnet.direction === BindDirection.StreamToDevice ? [sourceMagnet, magnet] : [magnet, sourceMagnet];
};

export const getFlowPosition = (source: ReduxState["editor"]["binding"], magnet: IMagnet) => {
  if (isMassStream(magnet) || isMassStream(source)) return;

  const [inputEl, outputEl] = getInputOutputElements(source, magnet) || [];
  if (!inputEl || !outputEl) return;

  switch (outputEl.azimuth) {
    case 0:
      return {
        left: outputEl.position.left + outputEl.offset.left + 50,
        top: outputEl.position.top + outputEl.offset.top - 50,
      };
    case 1:
      return {
        left: outputEl.position.left + outputEl.offset.left + 50,
        top: outputEl.position.top + outputEl.offset.top,
      };

    case 2:
      return {
        left: outputEl.position.left + outputEl.offset.left + 50,
        top: outputEl.position.top + outputEl.offset.top + 50,
      };

    case 3: {
      switch (inputEl.azimuth) {
        case 0:
          return {
            left: (inputEl.position.left + outputEl.position.left) / 2,
            top: inputEl.position.top + inputEl.offset.top + 50,
          };

        case 1:
          return {
            left: (inputEl.position.left + outputEl.position.left) / 2,
            top: inputEl.position.top + inputEl.offset.top + (outputEl.position.top > inputEl.position.top ? 50 : -50),
          };

        case 2:
          return {
            left: (inputEl.position.left + outputEl.position.left) / 2,
            top: inputEl.position.top + inputEl.offset.top - 50,
          };

        case 3:
          return {
            left: (inputEl.position.left + outputEl.position.left) / 2,
            top: inputEl.position.top + inputEl.offset.top,
          };

        default:
          return;
      }
    }

    default:
      return;
  }
};

export const isMagnetMatches = (m: IMagnet, left: number, top: number) => {
  const _left = m.position.left + m.offset.left;
  const _top = m.position.top + m.offset.top;
  return _left - 16 <= left && _left + 16 >= left && _top - 16 <= top && _top + 16 >= top;
};
