import { IDevice, IMassStream } from "models";
import { DeviceType } from "const";

export const isDevice = (item?: Pick<IMassStream | IDevice, "type">) => {
  if (!item?.type) return false;
  return (
    item?.type === DeviceType.Valve ||
    item?.type === DeviceType.Mixer ||
    item?.type === DeviceType.Separator2p ||
    item?.type === DeviceType.Separator3p ||
    item?.type === DeviceType.Compressor ||
    item?.type === DeviceType.CoolerHeater ||
    item?.type === DeviceType.Pump ||
    item?.type === DeviceType.Exchanger ||
    item?.type === DeviceType.Expander
  );
};
