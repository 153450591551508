import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ObjectImage, Panel, PanelTabs, Worksheet, useWorksheets } from "components";
import { Icons } from "assets";
import { PropertiesName } from "./properties-name";
import { usePropertiesData } from "./use-properties-data";
import { addObjectModal } from "reducers/layout";
import { ProjectRouteParams } from "types";

const tabs = [
  { value: "components", label: "Компоненты" },
  { value: "conditions", label: "Параметры" },
  { value: "characteristics", label: "Свойства" },
];

export const Properties = React.memo(() => {
  const dispatch = useDispatch();
  const params = useParams<ProjectRouteParams>();
  const { projectId, massStreamId, deviceId, tab } = params;
  const objectData = usePropertiesData(params);

  const handleExpand = useCallback(() => {
    dispatch(addObjectModal({ massStreamId, projectId, deviceId, tab, page: "properties" }, objectData));
  }, [dispatch, massStreamId, projectId, deviceId, tab, objectData]);

  const [workSheetData, deviceConditions] = useWorksheets(params);
  const buttonProps = useMemo(
    () => ({
      icon: <Icons.Modal />,
      onClick: handleExpand,
    }),
    [handleExpand],
  );

  return (
    <>
      <Panel title={objectData ? <PropertiesName objectData={objectData} params={params} /> : " "}>
        <ObjectImage params={params} objectData={objectData} />
      </Panel>
      {deviceConditions && (
        <Panel>
          <Worksheet {...deviceConditions} secondary />
        </Panel>
      )}
      <Panel
        button={buttonProps}
        title={<PanelTabs disabled={!massStreamId && !deviceId} items={tabs} name={"tab"} default={"components"} />}
        disabled={!massStreamId && !deviceId}
      >
        {(tab === "components" || !tab) && <Worksheet {...workSheetData.components} />}
        {tab === "conditions" && <Worksheet {...workSheetData.conditions} />}
        {tab === "characteristics" && <Worksheet {...workSheetData.characteristics} />}
      </Panel>
    </>
  );
});
