import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { DashboardProject } from "./dashboard-project";
import { DashboardProjectCreate } from "./dashboard-project-create";
import { Loader } from "components";
import { ReactComponent as Logo } from "assets/logo.svg";
import { ReduxState } from "types";
import { IProject } from "models";
import * as Markup from "./dashboard.styles";

export const Dashboard = React.memo(() => {
  const projects = useSelector<ReduxState, Nullable<IProject[]>>(({ datum }) => datum.projects, shallowEqual);
  const sorted = useMemo(() => {
    if (!projects) return projects;

    return projects.sort((a, b) => (new Date(a.lastUpdateTimestamp) > new Date(b.lastUpdateTimestamp) ? -1 : 1));
  }, [projects]);

  return (
    <Markup.Container>
      {sorted ? (
        <Markup.Projects>
          <DashboardProjectCreate />
          {sorted.map((project) => (
            <DashboardProject {...project} key={project.id} />
          ))}
        </Markup.Projects>
      ) : (
        <Loader size={48} style={{ flexGrow: 1 }} />
      )}
      <Logo />
    </Markup.Container>
  );
});
