import React, { useCallback, useEffect, useRef, useState } from "react";
import useEventListener from "@use-it/event-listener";
import { Scrollbar } from "./scrollbar";
import { ScrollbarProps } from "components/scroller/scoller.types";

interface Props {
  scrollRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const Scrollbars = React.memo((props: Props) => {
  const { scrollRef } = props;
  const [verticalParams, setVerticalParams] = useState<Pick<ScrollbarProps, "size" | "position">>();
  const [horizontalParams, setHorizontalParams] = useState<Pick<ScrollbarProps, "size" | "position">>();
  const [isVisible, setVisible] = useState(false);
  const [isHovered, setHovered] = useState(false);

  const timeoutRef = useRef(0);
  useEffect(() => () => clearTimeout(timeoutRef.current), [timeoutRef]);

  const handleScroll = useCallback(
    (e = undefined) => {
      if (!scrollRef.current) return;

      if (e) {
        clearTimeout(timeoutRef.current);
        setVisible(true);
        timeoutRef.current = window.setTimeout(() => setVisible(false), 1000);
      }

      setVerticalParams(
        scrollRef.current.scrollHeight > scrollRef.current.clientHeight
          ? {
              size: scrollRef.current.clientHeight / scrollRef.current.scrollHeight,
              position: scrollRef.current.scrollTop / (scrollRef.current.scrollHeight - scrollRef.current.clientHeight),
            }
          : undefined,
      );

      setHorizontalParams(
        scrollRef.current.scrollWidth > scrollRef.current.clientWidth
          ? {
              size: scrollRef.current.clientWidth / scrollRef.current.scrollWidth,
              position: scrollRef.current.scrollLeft / (scrollRef.current.scrollWidth - scrollRef.current.clientWidth),
            }
          : undefined,
      );
    },
    [scrollRef, timeoutRef],
  );

  const onScrollbarMove = useCallback(
    (params: { position: number; type: ScrollbarProps["type"] }) => {
      const { position, type } = params;
      if (!scrollRef.current) return;
      if (type === "horizontal") {
        scrollRef.current.scrollLeft = position * (scrollRef.current.scrollWidth - scrollRef.current.clientWidth);
      } else {
        scrollRef.current.scrollTop = position * (scrollRef.current.scrollHeight - scrollRef.current.clientHeight);
      }

      handleScroll();
    },
    [scrollRef, handleScroll],
  );

  const handlePointerMove = useCallback(() => {
    clearTimeout(timeoutRef.current);
    setVisible(true);
    timeoutRef.current = window.setTimeout(() => setVisible(false), 1000);
  }, [timeoutRef]);

  useEffect(handleScroll, [handleScroll]);
  useEventListener("scroll", handleScroll, scrollRef.current);
  useEventListener("resize", handleScroll, window);
  useEventListener("pointermove", handlePointerMove, window);

  if (!verticalParams && !horizontalParams) return null;

  return (
    <>
      {verticalParams && (
        <Scrollbar
          {...verticalParams}
          type={"vertical"}
          hasMargin={Boolean(horizontalParams)}
          isVisible={isVisible || isHovered}
          setHovered={setHovered}
          onScrollbarMove={onScrollbarMove}
        />
      )}
      {horizontalParams && (
        <Scrollbar
          {...horizontalParams}
          type={"horizontal"}
          hasMargin={Boolean(verticalParams)}
          isVisible={isVisible || isHovered}
          setHovered={setHovered}
          onScrollbarMove={onScrollbarMove}
        />
      )}
    </>
  );
});
