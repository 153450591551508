import React, { Dispatch, SetStateAction } from "react";
import { Position, Size } from "types";

export interface ModalContext {
  setSize: Dispatch<SetStateAction<Size | undefined>>;
  onMove: (position: Position) => void;
  onMoveEnd: (position: Position) => void;
  onClose: () => void;
  path: string;
  position: Position;
}

export const modalContext = React.createContext<ModalContext>({
  setSize: () => null,
  onMove: () => null,
  onMoveEnd: () => null,
  path: "",
  position: { left: 0, top: 0 },
  onClose: () => null,
});
