import React, { useCallback, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Loader, Panel, Placeholder } from "components";
import { Icons } from "assets";
import { addModal } from "reducers/layout";
import { loadComponents } from "reducers/datum";
import { ProjectRouteParams, ReduxState } from "types";
import { IComponent } from "models";
import * as Markup from "components/worksheet/worksheet.styles";

export const ComponentsList = React.memo(() => {
  const dispatch = useDispatch();
  const { projectId } = useParams<ProjectRouteParams>();
  const projectComponents = useSelector<ReduxState, Nullable<IComponent[]>>(
    ({ project }) => project?.massComponents,
    shallowEqual,
  );

  const handleShowModal = useCallback(() => {
    dispatch(
      addModal({
        path: "components-list",
        params: { projectId, page: "components-list" },
        position: { left: 40, top: 40 },
      }),
    );
  }, [dispatch, projectId]);

  useEffect(() => {
    dispatch(loadComponents());
  }, [dispatch]);

  const buttonProps = useMemo(() => {
    return projectComponents?.length
      ? {
          icon: <Icons.Edit />,
          onClick: handleShowModal,
        }
      : undefined;
  }, [projectComponents, handleShowModal]);

  if (!projectComponents) {
    return (
      <Panel title="Компоненты">
        <Loader style={{ minHeight: 70 }} />
      </Panel>
    );
  }

  return (
    <Panel title="Компоненты" button={buttonProps}>
      {projectComponents.length ? (
        <Markup.List>
          <Markup.Column>
            {projectComponents.map((item) => (
              <Row as={"label"} key={item.id}>
                <Markup.Cell>{item.name}</Markup.Cell>
                <Formula>{item.chemicalFormula}</Formula>
              </Row>
            ))}
          </Markup.Column>
        </Markup.List>
      ) : (
        <>
          <Placeholder>Список компонентов пуст</Placeholder>
          <Button onClick={handleShowModal}>Редактировать</Button>
        </>
      )}
    </Panel>
  );
});

const Row = styled(Markup.Row)`
  display: grid;
  grid-template-columns: 1fr auto;

  &:last-child > * {
    box-shadow: none;
  }
`;

const Formula = styled(Markup.Cell)(
  ({ theme: { colors } }) => css`
    font-weight: 400;
    color: ${colors.content.tertiary};
  `,
);
