import styled, { css } from "styled-components";

export const Container = styled.div<{ scale: number }>(
  ({ theme: { colors }, scale }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: ${(scale / 1.5) * 160}px;
    fill: ${colors.content.tertiary};
    stroke: ${colors.content.primary};
    margin-bottom: 10px;

    > svg {
      flex-basis: 100%;
    }
  `,
);

export const Inputs = styled.div<{ count?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => (p.count && p.count > 1 ? "space-between" : "center")};
  min-width: 100px;
  flex-grow: 1;
  margin-top: -16px;
  gap: 12px;
  position: relative;
`;
