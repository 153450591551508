import React, { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { ToolButton, Panel } from "components";
import { createDevice, createMassStream } from "reducers/project";
import { isMassStream, isDevice } from "utils";
import { schemeModels, DeviceType, StreamType } from "const";
import { Position, ProjectRouteParams, ReduxState } from "types";
import { IDevice } from "models";
import * as ToolbarIcons from "assets/toolbar";

export const Toolbox = React.memo(() => {
  const dispatch = useDispatch();
  const { projectId } = useParams<ProjectRouteParams>();
  const offset = useSelector<ReduxState, Position>(({ editor }) => editor.offset, shallowEqual);
  const devices = useSelector<ReduxState, IDevice[]>(({ project }) => project?.devices || [], shallowEqual);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const type = e.currentTarget.getAttribute("name") as DeviceType | StreamType | null;
      if (!type) return;

      let left = -offset.left;
      const model = schemeModels[type];
      if (!model) return;

      const safeSpace = [model.width / 2 + 20, model.height / 2 + 20];

      while (
        devices.find((item) => {
          const itemModel = schemeModels[item.type];
          if (!itemModel) return false;

          return (
            item.position.left + itemModel.width / 2 > left - safeSpace[0] &&
            item.position.left - itemModel.width / 2 < left + safeSpace[0] &&
            item.position.top + itemModel.height / 2 > -offset.top - safeSpace[1] &&
            item.position.top - itemModel.height / 2 < -offset.top + safeSpace[1]
          );
        })
      ) {
        left += safeSpace[0];
      }

      const payload = {
        type,
        position: {
          left,
          top: -offset.top,
        },
      };

      isMassStream({ type }) && dispatch(createMassStream(+projectId, { ...payload }));
      isDevice({ type }) && dispatch(createDevice(+projectId, payload as IDevice));
    },
    [dispatch, offset, devices, projectId],
  );

  const handleDrag = useCallback((e: React.DragEvent<HTMLButtonElement>) => {
    const type = e.currentTarget.getAttribute("name") as DeviceType | StreamType | null;
    if (!type) return;
    if (isMassStream({ type })) {
      e.dataTransfer.setData("action", "createFlow");
      e.dataTransfer.setData("type", type);
    }

    if (isDevice({ type })) {
      e.dataTransfer.setData("action", "createComponent");
      e.dataTransfer.setData("type", type);
    }
  }, []);

  return (
    <Panel title="Инструменты">
      <Buttons>
        <ToolButton
          name={StreamType.Mass}
          onClick={handleClick}
          icon={<ToolbarIcons.Flow />}
          onDragStart={handleDrag}
        />
        <ToolButton
          name={DeviceType.Mixer}
          onClick={handleClick}
          icon={<ToolbarIcons.Mixer />}
          onDragStart={handleDrag}
        />
        <ToolButton
          name={DeviceType.Separator2p}
          onClick={handleClick}
          icon={<ToolbarIcons.Separator2ph />}
          onDragStart={handleDrag}
        />
        <ToolButton
          name={DeviceType.Separator3p}
          onClick={handleClick}
          icon={<ToolbarIcons.Separator3ph />}
          onDragStart={handleDrag}
        />
        <ToolButton
          name={DeviceType.Valve}
          onClick={handleClick}
          icon={<ToolbarIcons.Valve />}
          onDragStart={handleDrag}
        />
        <ToolButton
          name={DeviceType.Compressor}
          onClick={handleClick}
          icon={<ToolbarIcons.Compressor />}
          onDragStart={handleDrag}
        />
        <ToolButton
          name={DeviceType.CoolerHeater}
          onClick={handleClick}
          icon={<ToolbarIcons.CoolerHeater />}
          onDragStart={handleDrag}
        />
        <ToolButton
          name={DeviceType.Pump}
          onClick={handleClick}
          icon={<ToolbarIcons.Pump />}
          onDragStart={handleDrag}
        />
        <ToolButton
          name={DeviceType.Exchanger}
          onClick={handleClick}
          icon={<ToolbarIcons.Exchanger />}
          onDragStart={handleDrag}
        />
        <ToolButton
          name={DeviceType.Expander}
          onClick={handleClick}
          icon={<ToolbarIcons.Expander />}
          onDragStart={handleDrag}
        />
      </Buttons>
    </Panel>
  );
});

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
