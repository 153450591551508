import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ScrollbarProps } from "components/scroller/scoller.types";
import * as Markup from "components/scroller/scroller.styles";

export const Scrollbar = React.memo((props: ScrollbarProps) => {
  const { size, position, type = "vertical", setHovered, onScrollbarMove, ...restProps } = props;
  const onPointerEnter = useCallback(() => setHovered?.(true), [setHovered]);
  const onPointerLeave = useCallback(() => setHovered?.(false), [setHovered]);
  const scrollbarRef = useRef<HTMLDivElement | null>(null);
  const shaftRef = useRef<HTMLSpanElement | null>(null);
  const positionRef = useRef<number>(position);
  const [cursorStart, setCursorStart] = useState<{ pageX: number; pageY: number }>();
  const [style, setStyle] = useState<React.CSSProperties>();
  const userSelectStyleRef = useRef("");

  useEffect(() => {
    setStyle((_style) => {
      if (!scrollbarRef.current) return _style;

      if (type === "horizontal") {
        const sizePx = Math.max(40, size * scrollbarRef.current.clientWidth);
        const positionPx = (scrollbarRef.current.clientWidth - sizePx) * position;
        return { width: sizePx, left: positionPx };
      }

      const sizePx = Math.max(40, size * scrollbarRef.current.clientHeight);
      const positionPx = (scrollbarRef.current.clientHeight - sizePx) * position;
      return { height: sizePx, top: positionPx };
    });
  }, [size, position, type, scrollbarRef]);

  const handlePointerMove = useCallback(
    (e) => {
      if (!cursorStart || !scrollbarRef.current) return;
      const containerSize =
        type === "horizontal" ? scrollbarRef.current.clientWidth : scrollbarRef.current.clientHeight;
      const shaftSize = Math.max(40, size * containerSize);
      const delta = type === "horizontal" ? e.pageX - cursorStart.pageX : e.pageY - cursorStart.pageY;

      onScrollbarMove?.({ position: positionRef.current + delta / (containerSize - shaftSize), type });
    },
    [positionRef, cursorStart, scrollbarRef, size, type, onScrollbarMove],
  );

  const handlePointerUp = useCallback(() => {
    document.body.style.userSelect = userSelectStyleRef.current;
    setCursorStart(undefined);
  }, [userSelectStyleRef]);

  useEffect(() => {
    if (!cursorStart || !onScrollbarMove) return;
    document.addEventListener("pointermove", handlePointerMove);
    document.addEventListener("pointerup", handlePointerUp);

    return () => {
      document.removeEventListener("pointermove", handlePointerMove);
      document.removeEventListener("pointerup", handlePointerUp);
    };
  }, [onScrollbarMove, cursorStart, positionRef, handlePointerMove, handlePointerUp]);

  const onPointerDown = useMemo(
    () =>
      onScrollbarMove
        ? (e: React.MouseEvent<HTMLDivElement>) => {
            if (!scrollbarRef.current) return;
            if (document.body.style.userSelect) userSelectStyleRef.current = document.body.style.userSelect;
            document.body.style.userSelect = "none";

            if (e.target !== shaftRef.current) {
              const rect = scrollbarRef.current.getBoundingClientRect();
              const containerSize =
                type === "horizontal" ? scrollbarRef.current.clientWidth : scrollbarRef.current.clientHeight;
              const shaftSize = Math.max(40, size * containerSize);
              const nextPosition =
                type === "horizontal"
                  ? (e.pageX - rect.left - shaftSize / 2) / (containerSize - shaftSize)
                  : (e.pageY - rect.top - shaftSize / 2) / (containerSize - shaftSize);
              onScrollbarMove({ position: nextPosition, type });
              positionRef.current = nextPosition;
            } else {
              positionRef.current = position;
            }

            setCursorStart({ pageX: e.pageX, pageY: e.pageY });
          }
        : undefined,
    [scrollbarRef, shaftRef, type, size, position, onScrollbarMove, userSelectStyleRef],
  );

  return (
    <Markup.Scrollbar
      type={type}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onPointerDown={onPointerDown}
      {...restProps}
      ref={scrollbarRef}
      isActive={Boolean(cursorStart)}
    >
      <Markup.ScrollShaft style={style} ref={shaftRef} />
    </Markup.Scrollbar>
  );
});
