import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
  name?: string;
  disabled?: boolean;
}

export const ToolButton = React.memo(
  React.forwardRef((props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const { name, icon, disabled, children, onClick, onDragStart } = props;

    return (
      <Container name={name} ref={ref} {...{ disabled, onClick, onDragStart }} draggable={Boolean(onDragStart)}>
        {icon}
        {children}
      </Container>
    );
  }),
);

const Container = styled.button`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 0;
  border: none;
  appearance: none;
  border-radius: 6px;
  background: transparent;
  margin: -1px 0;
  gap: 8px;
  fill: ${(p) => p.theme.colors.content.tertiary};
  stroke: #000000;
  transition-property: box-shadow, transform, fill, stroke;
  transition-duration: 0.1s;

  &:hover:not(:disabled) {
    background: #ffffff;
    fill: ${(p) => p.theme.colors.accent};
    box-shadow: 0 3px 6px -2px #00000033;
    transform: translateY(-0.5px);
  }

  &:active:not(:disabled) {
    transform: none;
    background: #00000011;
    box-shadow: none;
  }

  &:disabled {
    fill: #aaaaaa66;
    stroke: #aaaaaa66;
    box-shadow: none;
  }
`;
