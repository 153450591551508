import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { isDevice, isMassStream } from "utils";
import { setSelected } from "reducers/editor";
import { ProjectRouteParams, ReduxState, EditorState } from "types";
import { IDevice, IMassStream } from "models";

export const ProjectRouter = React.memo(() => {
  const { projectId, massStreamId, deviceId, tab } = useParams<ProjectRouteParams>();
  const dispatch = useDispatch();
  const [massStreams, devices] = useSelector<ReduxState, [Nullable<IMassStream[]>, Nullable<IDevice[]>]>(
    ({ project }) => [project?.massStreams, project?.devices],
    shallowEqual,
  );
  const selected = useSelector<ReduxState, EditorState["selected"]>(({ editor }) => editor.selected, shallowEqual);
  const [isLoaded, setLoaded] = useState(false);

  const redirectTo = useMemo(() => {
    if (selected && isLoaded) {
      if (isMassStream(selected) && String(selected.id) !== massStreamId) {
        return `/project/${projectId}/ms${selected.id}/${tab || "components"}`;
      }
      if (isDevice(selected) && String(selected.id) !== deviceId) {
        return `/project/${projectId}/d${selected.id}/${tab || "components"}`;
      }
    } else {
      return `/project/${projectId}`;
    }

    return null;
  }, [selected, isLoaded, tab, projectId, deviceId, massStreamId]);

  useEffect(() => {
    if (isLoaded) return;
    if (selected || !(devices || massStreams)) return;

    const device = deviceId && devices?.find((item) => item.id === +deviceId);
    const massStream = massStreamId && massStreams?.find((item) => item.id === +massStreamId);
    device && dispatch(setSelected(device));
    massStream && dispatch(setSelected(massStream));

    setLoaded(true);
  }, [dispatch, selected, devices, deviceId, isLoaded, massStreams, massStreamId]);

  return redirectTo && isLoaded ? <Redirect to={`${redirectTo}${window.location.search}`} /> : null;
});
