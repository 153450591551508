import React, { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ReduxState, EditorState } from "types";
import { setBinding, setTargetBingind, setSelected } from "reducers/editor";
import { ariaAttributes } from "utils";
import { BindDirection } from "const";
import { IMassStream, IDevice } from "models";
import { ReactComponent as Connector } from "assets/connector.svg";

type Props = {
  parent: IDevice | IMassStream;
  index: number;
  direction: BindDirection;
  left: number;
  top: number;
  azimuth: number;
  isBinded?: boolean;
  isSelected?: boolean;
  ephemeral?: boolean;
};

export const Binder = React.memo((props: Props) => {
  const { left, top, index, direction, parent, isBinded, isSelected, azimuth, ephemeral } = props;
  const dispatch = useDispatch();
  const binding = useSelector<ReduxState, EditorState["binding"]>(({ editor }) => editor.binding, shallowEqual);
  const isDisabled = useMemo(() => Boolean(binding), [binding]);

  const onPointerLeave = useCallback(
    (e: React.PointerEvent<SVGElement>) => {
      if (!isSelected || isDisabled || e.buttons === 0) return;
      dispatch(setSelected(undefined));

      const payload = { ...parent, direction, connectorIndex: index };
      dispatch(isBinded ? setTargetBingind(payload) : setBinding(payload));
    },
    [dispatch, isSelected, isDisabled, isBinded, parent, direction, index],
  );

  const onPointerDown = useCallback(
    (e: React.PointerEvent) => {
      e.stopPropagation();
      if (isDisabled) return;
      dispatch(
        setSelected({
          id: parent.id,
          position: parent.position,
          type: parent.type,
          name: parent.name,
          direction: direction,
          connectorIndex: index,
        }),
      );
    },
    [dispatch, parent, direction, index, isDisabled],
  );

  const onPointerUp = useCallback(
    (e: React.PointerEvent) => {
      e.stopPropagation();
      if (isBinded) return;

      dispatch(
        setBinding(
          isDisabled
            ? undefined
            : {
                ...parent,
                direction: direction,
                connectorIndex: index,
              },
        ),
      );
    },
    [dispatch, index, direction, parent, isBinded, isDisabled],
  );

  const onClick = useCallback((e) => e.stopPropagation(), []);

  return (
    <Container
      isBinded={isBinded}
      ephemeral={ephemeral}
      {...ariaAttributes(isSelected && "selected", isDisabled && "disabled")}
      {...{ onPointerLeave, onPointerDown, onPointerUp, onClick }}
      transform={`translate(${left - 5}, ${top - 5}) rotate(${(azimuth || 0) * 90} 5 5)`}
    >
      <Connector />
      <rect x={-1} y={2} width="12" height="12" fill="#000000" opacity="0" stroke="none" />
    </Container>
  );
});

const Container = styled.g<{ isBinded?: boolean; ephemeral?: boolean }>(
  ({ theme: { colors }, isBinded, ephemeral }) => css`
    stroke-width: 1;
    cursor: pointer;
    stroke: ${colors.content.primary};
    fill: ${colors.content.tertiary};
    stroke-opacity: 0;
    fill-opacity: 0.5;
    transition-property: fill, stroke, fill-opacity, stroke-opacity;
    opacity: ${ephemeral ? 0 : 1};

    *:hover > &:not([aria-selected]),
    *[aria-selected] > &:not([aria-selected]) {
      stroke-width: 1.4;
      opacity: 1;
    }

    *[aria-selected] > & {
      fill: ${colors.accent};
    }

    &:hover,
    &[aria-selected] {
      fill: ${colors.accent};
      stroke: ${colors.accent};
      stroke-opacity: 1;
      fill-opacity: 0;
    }

    &[aria-selected] {
      stroke-width: 3;
    }

    ${!isBinded &&
    css`
      &:hover,
      *[aria-selected] > &:hover {
        stroke-width: 1.4;
        fill: ${colors.edit};
        stroke: ${colors.edit};
      }
    `};

    &[aria-disabled],
    &[aria-disabled]:hover {
      stroke: ${colors.content.primary};
      stroke-opacity: 0;
      fill-opacity: 0.5;
      stroke-width: 1;
      pointer-events: none;
    }
  `,
);
