import { closeProject, openProject } from "reducers/layout";
import { ReduxState } from "types";
import { IComponent, IProject } from "models";
import { Dispatch } from "redux";
import * as projectsApi from "services/projects";
import * as componentsApi from "services/components";

const initialState: ReduxState["datum"] = {
  components: undefined,
  projects: undefined,
};

const SET_PROJECTS = "datum/SET_PROJECTS";
const ADD_PROJECT = "datum/ADD_PROJECT";
const UPDATE_PROJECT = "datum/UPDATE_PROJECT";
const DELETE_PROJECT = "datum/DELETE_PROJECT";
const SET_COMPONENTS = "datum/SET_COMPONENTS";

interface SetProjectsAction {
  type: typeof SET_PROJECTS;
  projects: IProject[];
}

interface AddProjectAction {
  type: typeof ADD_PROJECT;
  payload: IProject;
}

interface UpdateProjectAction {
  type: typeof UPDATE_PROJECT;
  payload: IProject;
}

interface DeleteProjectAction {
  type: typeof DELETE_PROJECT;
  id: number;
}

interface SetComponentsAction {
  type: typeof SET_COMPONENTS;
  data: Nullable<IComponent[]>;
}

export type DatumActions =
  | SetProjectsAction
  | UpdateProjectAction
  | AddProjectAction
  | DeleteProjectAction
  | SetComponentsAction;

export default (state = initialState, action: DatumActions) => {
  switch (action.type) {
    case SET_PROJECTS:
      return {
        ...state,
        projects: action.projects,
      };

    case ADD_PROJECT:
      return {
        ...state,
        projects: [...(state.projects || []), action.payload],
      };

    case UPDATE_PROJECT:
      return {
        ...state,
        projects: state.projects?.map((item) => (item.id === action.payload.id ? action.payload : item)),
      };

    case DELETE_PROJECT:
      return {
        ...state,
        projects: state.projects?.filter((item) => item.id !== action.id),
      };

    case SET_COMPONENTS:
      return {
        ...state,
        components: action.data || null,
      };

    default:
      return state;
  }
};

export const createProject = (history: any) => async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
  try {
    const projects = getState().datum.projects;
    const index: number = (projects || []).reduce((prev: number, current: IProject) => {
      return current.name.indexOf("Безымянный-") > -1 && !isNaN(+current.name.slice("Безымянный-".length))
        ? Math.max(prev, +current.name.slice("Безымянный-".length) + 1)
        : prev;
    }, 1);
    const token = getState().auth.token;
    if (!token) return;

    const project: IProject = await projectsApi.postProject(token, `Безымянный-${index}`);
    dispatch(openProject({ id: project.id }));
    dispatch({ type: ADD_PROJECT, payload: project });

    history.push(`/project/${project.id}`);
  } catch (error: any) {
    alert(error?.title || "Неизвестная ошибка");
  }
};

export const saveProject =
  (id: number, payload: Partial<IProject>) => async (dispatch: Dispatch<DatumActions>, getState: () => ReduxState) => {
    const project = getState().datum.projects?.find((item) => item.id === id);
    if (!project) return;

    try {
      const token = getState().auth.token;
      if (!token) return;

      const data = await projectsApi.putProject(token, { ...project, ...payload });
      dispatch({ type: UPDATE_PROJECT, payload: data });
    } catch {}
  };

export const loadProjects = () => async (dispatch: Dispatch<DatumActions>, getState: () => ReduxState) => {
  try {
    const token = getState().auth.token;
    if (!token) return;

    const projects = await projectsApi.getProjects(token);
    dispatch({ type: SET_PROJECTS, projects });
  } catch {}
};

export const deleteProject = (id: number) => async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
  try {
    const token = getState().auth.token;
    if (!token) return;

    await projectsApi.deleteProject(token, id);
    dispatch({ type: DELETE_PROJECT, id });
    dispatch(closeProject(id));
  } catch (error: any) {
    alert(error?.message || "Неизвестная ошибка");
  }
};

export const loadComponents = () => async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
  const token = getState().auth.token;
  if (!token) return;

  try {
    const data = await componentsApi.getComponents(token);
    dispatch({ type: SET_COMPONENTS, data });
  } catch {
    dispatch({ type: SET_COMPONENTS, data: null });
  }
};
