import React from "react";
import styled, { css } from "styled-components";

interface Props {
  value?: any;
}

export const Value = React.memo((props: Props) => {
  return <Container>{props.value === null ? "–" : props.value}</Container>;
});

const Container = styled.span(
  ({ theme: { spacings } }) => css`
    padding: ${spacings.xsmall} ${spacings.medium};
    font-weight: inherit;
  `,
);
