import { LayoutState, ModalData, ModalParams, Position, ProjectMeta, ReduxState, Size } from "types";
import { Dispatch } from "redux";
import { IDevice, IMassStream } from "models";

const initialState: LayoutState = {
  modals: [],
  openedProjects: [],
  editorPosition: undefined,
  hasUI: true,
  modalPositions: {},
};

const ADD_MODAL = "layout/ADD_MODAL";
const CLOSE_MODAL = "layout/CLOSE_MODAL";
const OPEN_PROJECT = "layout/OPEN_PROJECT";
const CLOSE_PROJECT = "layout/CLOSE_PROJECT";
const SET_EDITOR_POSITION = "layout/SET_EDITOR_POSITION";
const SET_MODAL_POSITION = "layout/SET_MODAL_POSITION";
const TOGGLE_UI = "layout/TOGGLE_UI";

interface AddModalAction {
  type: typeof ADD_MODAL;
  payload: ModalData;
}

interface CloseModalAction {
  type: typeof CLOSE_MODAL;
  path: string;
}

interface OpenProjectAction {
  type: typeof OPEN_PROJECT;
  payload: ProjectMeta;
}

interface CloseProjectAction {
  type: typeof CLOSE_PROJECT;
  id: number;
}

interface SetEditorPositionAction {
  type: typeof SET_EDITOR_POSITION;
  payload: Size & Position;
}

interface SetModalPositionAction {
  type: typeof SET_MODAL_POSITION;
  path: string;
  position: Position;
}

interface ToggleUIAction {
  type: typeof TOGGLE_UI;
}

export type LayoutActions =
  | AddModalAction
  | CloseModalAction
  | OpenProjectAction
  | CloseProjectAction
  | SetEditorPositionAction
  | SetModalPositionAction
  | ToggleUIAction;

export default (state = initialState, action: LayoutActions) => {
  switch (action.type) {
    case ADD_MODAL:
      return {
        ...state,
        modals: [...state.modals.filter((item) => item.path !== action.payload.path), action.payload],
      };

    case CLOSE_MODAL:
      return {
        ...state,
        modals: state.modals.filter((item) => item.path !== action.path),
      };

    case OPEN_PROJECT: {
      return {
        ...state,
        openedProjects: state.openedProjects.find((item) => item.id === action.payload.id)
          ? state.openedProjects.map((item) => (item.id === action.payload.id ? action.payload : item))
          : [...state.openedProjects, action.payload],
      };
    }

    case CLOSE_PROJECT:
      return {
        ...state,
        openedProjects: state.openedProjects.filter((item) => item.id !== action.id),
      };

    case SET_EDITOR_POSITION:
      return {
        ...state,
        editorPosition: action.payload,
      };

    case SET_MODAL_POSITION:
      return {
        ...state,
        modalPositions: {
          ...state.modalPositions,
          [action.path]: action.position,
        },
      };

    case TOGGLE_UI:
      return {
        ...state,
        hasUI: !state.hasUI,
      };

    default:
      return state;
  }
};

export const addModal = (payload: ModalData) => (dispatch: Dispatch<LayoutActions>, getState: () => ReduxState) => {
  const currentPosition = getState().layout.modalPositions[payload.path];
  dispatch({ type: ADD_MODAL, payload: { ...payload, position: currentPosition || payload.position } });
};

export const addObjectModal =
  (params: ModalParams, objectData?: IDevice | IMassStream) =>
  (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const { offset, zoom } = getState().editor;
    const { editorPosition } = getState().layout;

    const path = params.massStreamId
      ? `/properties/massStream/${params.massStreamId}`
      : params.deviceId && `/properties/device/${params.deviceId}`;

    if (!path) return;

    const objectPosition =
      objectData && editorPosition
        ? {
            left: Math.min(
              window.innerWidth - 820,
              Math.max(
                20,
                (objectData.position.left - offset.left) * zoom + editorPosition.left + editorPosition.width / 2 - 400,
              ),
            ),
            top: Math.min(
              window.innerHeight - 465,
              Math.max(
                0,
                (objectData.position.top - offset.top) * zoom + editorPosition.top + editorPosition.height / 2 - 210,
              ),
            ),
          }
        : undefined;

    dispatch(addModal({ path, params, position: objectPosition }));
  };

export const closeModal = (path: ModalData["path"]): CloseModalAction => ({ type: CLOSE_MODAL, path });

export const openProject = (payload: ProjectMeta): OpenProjectAction => ({ type: OPEN_PROJECT, payload });

export const closeProject = (id: number, history?: any) => (dispatch: Dispatch<LayoutActions>) => {
  dispatch({ type: CLOSE_PROJECT, id });
  history?.replace("/");
};

export const setEditorPosition = (payload: Size & Position): SetEditorPositionAction => ({
  type: SET_EDITOR_POSITION,
  payload,
});

export const setModalPosition = (path: string, position: Position): SetModalPositionAction => ({
  type: SET_MODAL_POSITION,
  path,
  position,
});

export const toggleUI = () => ({ type: TOGGLE_UI });
