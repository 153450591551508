import { Link, useRouteMatch } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { Input } from "components";
import { Icons } from "assets";
import { saveProject } from "reducers/datum";
import { IProject } from "models";
import { ProjectMeta, ReduxState } from "types";

export interface Props extends Omit<ProjectMeta, "id"> {
  id?: number;
  children?: React.ReactNode;
  onClose?: (id?: number) => void;
}

export const NavbarButton = React.memo((props: Props) => {
  const { id, onClose, children, url: propsUrl } = props;
  const data = useSelector<ReduxState, Nullable<IProject>>(
    ({ datum }) => datum.projects?.find((item) => item.id === id),
    shallowEqual,
  );

  const dispatch = useDispatch();
  const url = useMemo(() => propsUrl || (id ? `/project/${id}` : "/"), [propsUrl, id]);
  const [isEditing, setEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const match = useRouteMatch(url);

  useEffect(() => {
    isEditing && inputRef.current?.focus();
  }, [isEditing, inputRef]);

  const handleClose = useMemo(
    () =>
      onClose
        ? (e: React.MouseEvent) => {
            e.preventDefault();
            onClose(id);
          }
        : undefined,
    [onClose, id],
  );

  const onDoubleClick = useMemo(() => {
    return id && !isEditing
      ? () => {
          setEditing(true);
        }
      : undefined;
  }, [id, isEditing]);

  const handleBlur = useCallback(() => {
    setEditing(false);
  }, []);

  const handleChange = useCallback(
    (value: string) => {
      id && value && dispatch(saveProject(id, { name: value }));
    },
    [id, dispatch],
  );

  const isActive = useMemo(() => Boolean(match) && Boolean(id || match?.isExact), [match, id]);

  return (
    <Button to={url} as={!isActive ? Link : undefined} onDoubleClick={onDoubleClick}>
      {isEditing ? (
        <Input value={data?.name || ""} onChange={handleChange} onBlur={handleBlur} ref={inputRef} />
      ) : (
        <>
          {data?.name || children || "Загрузка..."}
          {handleClose && (
            <Close>
              <Icons.Close width={16} height={16} onClick={handleClose} />
            </Close>
          )}
        </>
      )}
    </Button>
  );
});

const Close = styled.span(
  ({ theme: { spacings } }) => css`
    display: flex;
    height: 24px;
    max-width: 24px;
    min-width: 24px;
    justify-content: center;
    align-items: center;
    margin-right: -${spacings.small};
    cursor: pointer;
  `,
);

const Button = styled.span(
  ({ theme: { colors, spacings, transitions, components } }) => css`
    text-decoration: none;
    padding: 0 ${spacings.medium};
    gap: ${spacings.xsmall};
    background: transparent;
    color: ${colors.white};
    fill: ${colors.white};
    stroke: ${colors.white};
    display: flex;
    align-items: center;
    transition: background, color;
    transition-duration: ${transitions.short};
    user-select: none;
    min-height: ${components.control.height + 2}px;

    > ${Close} {
      fill: ${colors.content.tertiary};

      &:hover {
        fill: ${colors.white};
      }
    }

    span& {
      cursor: default;
      color: ${colors.content.primary};
      fill: ${colors.content.primary};
      stroke: ${colors.content.primary};
      background: ${colors.page};

      > ${Close}:hover {
        fill: ${colors.content.primary};
      }
    }
  `,
);
