import React, { useCallback } from "react";

interface Params<T> {
  innerRef: React.MutableRefObject<T | null>;
  ref: React.ForwardedRef<T>;
}

export const useInnerRef = <T>({ innerRef, ref }: Params<T>) =>
  useCallback(
    (node: T) => {
      // eslint-disable-next-line no-param-reassign
      innerRef.current = node;
      if (!ref) return;
      if (typeof ref === "function") {
        ref(node);
        return;
      }
      // eslint-disable-next-line no-param-reassign
      ref.current = node;
    },
    [ref, innerRef],
  );
