import styled, { css } from "styled-components";

export const Object = styled.g(
  (p) => css`
    stroke: ${p.theme.colors.content.primary};
    fill: ${p.theme.colors.content.tertiary};
    stroke-width: 1;

    &:hover {
      stroke-width: 1.4;
    }

    &[aria-selected] {
      fill: ${p.theme.colors.accent};
      stroke-width: 1.4;
    }

    &[aria-disabled] {
      pointer-events: none;
    }
  `,
);

export const Text = styled.text`
  fill: ${(p) => p.theme.colors.content.primary};
  stroke: none;

  font-size: 12px;
  pointer-events: none;
`;

export const Container = styled.div`
  overflow: hidden;
  background: ${(p) => p.theme.colors.white};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  user-select: none;

  > svg {
    display: block;
    outline: none;
  }
`;
