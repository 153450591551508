import React, { useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { DeviceType, schemeModels, StreamType } from "const";
import { theme } from "theme";

export const DashboardProjectPreview = React.memo((props: { id: number }) => {
  const { id } = props;

  const [lines, objects, position] = useMemo(() => {
    const { l, o, p } = JSON.parse((id && localStorage.getItem(`preview_${id}`)) || "{}");
    return [l, o, p || [0, 0, 1]];
  }, [id]);

  const { colors } = useTheme() as typeof theme;

  return (
    <Wrapper>
      {lines || objects ? (
        <svg viewBox={`0 0 ${(window.innerWidth * 0.5) / position[2]} ${(window.innerHeight * 0.6) / position[2]}`}>
          <g
            transform={`translate(${(window.innerWidth * 0.31) / position[2] - position[0]}, ${
              (window.innerHeight * 0.3) / position[2] - position[1]
            })`}
          >
            <g fill="none">
              {lines?.map((dots: [number, number][], index: number) => (
                <path key={index} d={`M${dots.map(([l, t]) => `${l},${t}`).join(" ")}`} />
              ))}
            </g>
            {objects?.map((obj: [DeviceType | StreamType, number, number], index: number) => {
              const model = schemeModels[obj[0]];
              if (!model) return null;
              return (
                <g key={index} transform={`translate(${obj[1] - model.width / 2}, ${obj[2] - model.height / 2})`}>
                  {model.source}
                </g>
              );
            })}
          </g>
        </svg>
      ) : (
        <MissingPreview>
          <svg viewBox="0 0 351 351" width={100} height={100} fill={colors.border.tertiary}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M171.5 91.9113L241.507 132.445L234.493 144.561L171.5 108.089L112 142.539V210.461L171.5 244.911L234.493 208.439L241.507 220.555L171.5 261.089L98 218.532V134.468L171.5 91.9113Z"
            />
            <g>
              <path d="M171.5 80C159.902 80 150.5 89.402 150.5 101C150.5 112.598 159.902 122 171.5 122C183.098 122 192.5 112.598 192.5 101C192.5 89.402 183.098 80 171.5 80Z" />
              <path d="M106 118C94.402 118 85 127.402 85 139C85 150.598 94.402 160 106 160C117.598 160 127 150.598 127 139C127 127.402 117.598 118 106 118Z" />
              <path d="M85 214C85 202.402 94.402 193 106 193C117.598 193 127 202.402 127 214C127 225.598 117.598 235 106 235C94.402 235 85 225.598 85 214Z" />
              <path d="M171.5 231C159.902 231 150.5 240.402 150.5 252C150.5 263.598 159.902 273 171.5 273C183.098 273 192.5 263.598 192.5 252C192.5 240.402 183.098 231 171.5 231Z" />
              <path d="M216 214C216 202.402 225.402 193 237 193C248.598 193 258 202.402 258 214C258 225.598 248.598 235 237 235C225.402 235 216 225.598 216 214Z" />
              <path d="M237 118C225.402 118 216 127.402 216 139C216 150.598 225.402 160 237 160C248.598 160 258 150.598 258 139C258 127.402 248.598 118 237 118Z" />
              <path d="M150.5 176.5C150.5 164.902 159.902 155.5 171.5 155.5C183.098 155.5 192.5 164.902 192.5 176.5C192.5 188.098 183.098 197.5 171.5 197.5C159.902 197.5 150.5 188.098 150.5 176.5Z" />
            </g>
          </svg>
        </MissingPreview>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.span`
  position: absolute;
  left: 0;
  top: 30px;
  bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    fill: ${(p) => p.theme.colors.content.secondary};
    stroke: ${(p) => p.theme.colors.content.primary};
    stroke-width: 1.5;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const MissingPreview = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  > svg {
    margin: -30px 0;
  }
`;
