import React, { useCallback } from "react";
import styled, { css, useTheme } from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createProject } from "reducers/datum";
import { theme } from "theme";
import * as Markup from "./dashboard.styles";

export const DashboardProjectCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCreateProject = useCallback(() => {
    dispatch(createProject(history));
  }, [dispatch, history]);

  const { colors } = useTheme() as typeof theme;

  return (
    <Container onClick={handleCreateProject}>
      <svg viewBox="0 0 351 351" width={140} height={140}>
        <path
          fill={colors.content.primary}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M171.5 91.9113L241.507 132.445L234.493 144.561L171.5 108.089L112 142.539V210.461L171.5 244.911L234.493 208.439L241.507 220.555L171.5 261.089L98 218.532V134.468L171.5 91.9113Z"
        />
        <g>
          <path d="M171.5 80C159.902 80 150.5 89.402 150.5 101C150.5 112.598 159.902 122 171.5 122C183.098 122 192.5 112.598 192.5 101C192.5 89.402 183.098 80 171.5 80Z" />
          <path d="M106 118C94.402 118 85 127.402 85 139C85 150.598 94.402 160 106 160C117.598 160 127 150.598 127 139C127 127.402 117.598 118 106 118Z" />
          <path d="M85 214C85 202.402 94.402 193 106 193C117.598 193 127 202.402 127 214C127 225.598 117.598 235 106 235C94.402 235 85 225.598 85 214Z" />
          <path d="M171.5 231C159.902 231 150.5 240.402 150.5 252C150.5 263.598 159.902 273 171.5 273C183.098 273 192.5 263.598 192.5 252C192.5 240.402 183.098 231 171.5 231Z" />
          <path d="M216 214C216 202.402 225.402 193 237 193C248.598 193 258 202.402 258 214C258 225.598 248.598 235 237 235C225.402 235 216 225.598 216 214Z" />
          <path d="M237 118C225.402 118 216 127.402 216 139C216 150.598 225.402 160 237 160C248.598 160 258 150.598 258 139C258 127.402 248.598 118 237 118Z" />
          <path d="M150.5 176.5C150.5 164.902 159.902 155.5 171.5 155.5C183.098 155.5 192.5 164.902 192.5 176.5C192.5 188.098 183.098 197.5 171.5 197.5C159.902 197.5 150.5 188.098 150.5 176.5Z" />
        </g>
      </svg>
      Новый проект
    </Container>
  );
};

const Container = styled(Markup.Project)(
  ({ theme: { typography } }) => css`
    font-weight: ${typography.fontWeight.bold};
    justify-content: center;
    align-items: center;
    fill: #00bde7;

    > svg {
      margin: -20px 0;
    }

    &:hover {
      fill: #00bde7;
    }
  `,
);
