import React, { useCallback, useContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { Button, ModalBody, ModalFooter, ModalHeader, modalContext } from "components";
import { ariaAttributes } from "utils";
import { saveComponents } from "reducers/project";
import { IComponent } from "models";
import { ProjectRouteParams, ReduxState } from "types";
import * as Markup from "components/worksheet/worksheet.styles";

export const ModalComponentsList = React.memo((params: ProjectRouteParams) => {
  const { projectId } = params;
  const dispatch = useDispatch();
  const [checked, setChecked] = useState<number[]>([]);
  const { onClose } = useContext(modalContext) || {};
  const components = useSelector<ReduxState, Nullable<IComponent[]>>(({ datum }) => datum.components, shallowEqual);
  const projectComponents = useSelector<ReduxState, Nullable<IComponent[]>>(
    ({ project }) => project?.massComponents,
    shallowEqual,
  );

  useEffect(() => {
    projectComponents && setChecked(projectComponents.map((item) => item.id));
  }, [projectComponents]);

  const handleToggle = useCallback((e) => {
    const id = +e.target.name;
    setChecked((_checked) => (_checked.includes(id) ? _checked.filter((item) => item !== id) : [..._checked, id]));
  }, []);

  const handleSave = useCallback(() => {
    dispatch(saveComponents(+projectId, checked));
    onClose();
  }, [dispatch, checked, onClose, projectId]);

  return (
    <>
      <ModalHeader title={"Cписок компонентов"} />
      <ModalBody width={400} height={window.innerHeight - 180}>
        <List isDisabled={!projectComponents}>
          <Markup.Column>
            {components?.map((item) => (
              <Row as={"label"} key={item.id} {...ariaAttributes(checked.includes(item.id) && "selected")}>
                <Markup.Cell>
                  <input
                    type="checkbox"
                    name={item.id.toString()}
                    onChange={handleToggle}
                    checked={checked.includes(item.id)}
                  />
                </Markup.Cell>
                <Markup.Cell>{item.name}</Markup.Cell>
                <Markup.Cell>
                  <Formula>{item.chemicalFormula}</Formula>
                </Markup.Cell>
              </Row>
            ))}
          </Markup.Column>
        </List>
      </ModalBody>
      <ModalFooter>
        <Button isPrimary onClick={handleSave}>
          Сохранить
        </Button>
        <Button onClick={onClose}>Отменить</Button>
      </ModalFooter>
    </>
  );
});

const List = styled(Markup.List)`
  display: flex;
`;

const Row = styled(Markup.Row)(
  ({ theme: { colors } }) => css`
    display: grid;
    grid-template-columns: 40px 1fr auto;

    &[aria-selected] {
      color: ${colors.accent};
      font-weight: 700;
    }
  `,
);

const Formula = styled.span(
  ({ theme: { colors } }) => css`
    font-weight: 400;
    color: ${colors.content.tertiary};
  `,
);
