import React, { useMemo } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { ariaAttributes, createPathURL } from "utils";
import { Container, Tab } from "components/panel-tabs/panel-tabs.styles";

interface Props {
  items: {
    label: string;
    value: string;
  }[];
  name: string;
  default: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  value?: string;
}

export const PanelTabs = React.memo((props: Props) => {
  const { items, name, disabled, onChange, value, default: propsDefault } = props;
  const match = useRouteMatch<any>();
  const selected = useMemo(
    () => (onChange ? value : match.params[name]) || propsDefault,
    [onChange, value, propsDefault, name, match],
  );
  const isDisabled = useMemo(
    () => disabled || (onChange ? !value : !match.params[name]),
    [name, match, disabled, onChange, value],
  );

  return (
    <Container {...ariaAttributes(isDisabled && "disabled")}>
      {items.map((tab) =>
        onChange ? (
          <Tab
            {...ariaAttributes(selected === tab.value && "current")}
            key={tab.value}
            onClick={() => onChange(tab.value)}
          >
            {tab.label}
          </Tab>
        ) : (
          <Tab
            {...ariaAttributes(selected === tab.value && "current")}
            key={tab.value}
            to={`${createPathURL(match.path, { ...match.params, [name]: tab.value })}${window.location.search}`}
            as={Link}
          >
            {tab.label}
          </Tab>
        ),
      )}
    </Container>
  );
});
