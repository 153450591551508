import React, { useCallback, useRef } from "react";
import * as Markup from "./worksheet.styles";
import { Flow } from "assets/toolbar";
import { Scroller, Value } from "components";
import { WorksheetInput } from "./worksheet-input";
import styled, { css } from "styled-components";

export interface WorksheetProps {
  secondary?: boolean;
  data?: {
    name?: string | null;
    id?: number;
    disabled?: boolean;
    values: {
      value: number | null;
      clearable?: boolean;
      isCalculated?: boolean;
      disabled?: boolean;
    }[];
  }[];
  list?: {
    name: string;
    label: React.ReactNode;
    measure: string | null;
  }[];
  onChange?: (value: number | null, name: string, massStreamId: number) => void;
}

export const Worksheet = React.memo((props: WorksheetProps) => {
  const { data, list, onChange, secondary } = props;
  const dataRef = useRef<HTMLSpanElement | null>(null);

  const handleNextFocus = useCallback((e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      const element = e.target as HTMLElement;
      const focusEl = (element.parentElement?.nextElementSibling?.firstChild ||
        element.parentElement?.parentElement?.nextElementSibling?.querySelectorAll("input")?.[0] ||
        element.parentElement?.parentElement?.parentElement?.querySelectorAll("input")?.[0]) as HTMLElement | undefined;

      if (focusEl === element) return;
      focusEl?.focus();
    }
  }, []);

  if (!list || !data) return null;

  return (
    <Markup.List secondary={secondary}>
      <Markup.Column>
        {!secondary && <Markup.Cell>Наименование</Markup.Cell>}
        {list.map((item, index) => (
          <Markup.Cell key={index}>
            <span title={typeof item.label === "string" ? item.label : undefined}>{item.label}</span>
            {item.measure && <Measure>{item.measure}</Measure>}
          </Markup.Cell>
        ))}
      </Markup.Column>
      <Markup.ScrollerWrapper count={data.length}>
        <Scroller key={String(data.map((item) => item.id).join(","))}>
          <Markup.Data ref={dataRef}>
            {data.map((item, col) => (
              <Markup.Column key={col}>
                {!secondary && (
                  <Markup.Cell head>
                    <Flow />
                    <span title={item.name || undefined}>{item.name} </span>
                  </Markup.Cell>
                )}
                {list.map(({ name }, index) => (
                  <Markup.Cell key={index}>
                    {onChange ? (
                      <WorksheetInput
                        data-id={item.id}
                        name={name}
                        value={item.values[index].value}
                        clearable={item.values[index].clearable}
                        onChange={onChange}
                        onKeyDown={handleNextFocus}
                        locked={item.values[index].isCalculated}
                        disabled={item.disabled || item.values[index].disabled}
                      />
                    ) : (
                      <Value value={item.values[index].value} />
                    )}
                  </Markup.Cell>
                ))}
              </Markup.Column>
            ))}
          </Markup.Data>
        </Scroller>
      </Markup.ScrollerWrapper>
    </Markup.List>
  );
});

const Measure = styled.span(
  ({ theme: { typography, colors, spacings } }) => css`
    ${{ ...typography.measure }};
    color: ${colors.content.secondary};
    padding: 1px ${spacings.xsmall};
    margin: 0 ${spacings.small};
    white-space: nowrap;
    background: ${colors.border.tertiary};
    border-radius: 2px;
  `,
);
