import { useMemo } from "react";
import { LineProps } from "canvas/components/lines/line";
import { BindDirection } from "const";

const SAFE_AREA_SIZE = 10;

const matrix: { [key: number]: { [key: number]: number[] } } = {
  0: {
    0: [3, 3, 1, 1],
    1: [3, 4, 1, 2],
    2: [4, 4, 2, 2],
    3: [4, 3, 2, 1],
  },
  1: {
    0: [3, 2, 1, 4],
    1: [3, 1, 1, 3],
    2: [4, 1, 2, 3],
    3: [4, 2, 2, 4],
  },
  2: {
    0: [2, 2, 4, 4],
    1: [2, 1, 4, 3],
    2: [1, 1, 3, 3],
    3: [1, 2, 3, 4],
  },
  3: {
    0: [2, 3, 4, 1],
    1: [2, 4, 4, 2],
    2: [1, 4, 3, 2],
    3: [1, 3, 3, 1],
  },
};

export const calculateLines = (props: Pick<LineProps, "direction" | "stream" | "device">) => {
  const dots = (
    props.direction === BindDirection.StreamToDevice ? [props.stream, props.device] : [props.device, props.stream]
  ).map((d) => ({
    left: d.position.left + d.offset.left,
    top: d.position.top + d.offset.top,
    azimuth: d.azimuth,
  }));

  const addDot = (array: [number, number][], l: number, t: number): [number, number] => [
    array[array.length - 1][0] + l,
    array[array.length - 1][1] + t,
  ];

  const addConnection = (type: number) => {
    switch (type) {
      case 1:
        before.push(addDot(before, getLast(after)[0] - getLast(before)[0], 0));
        return;
      case 2:
        before.push(addDot(before, (getLast(after)[0] - getLast(before)[0]) / 2, 0));
        after.push(addDot(after, getLast(before)[0] - getLast(after)[0], 0));
        return;
      case 3:
        before.push(addDot(before, 0, getLast(after)[1] - getLast(before)[1]));
        return;
      case 4:
        before.push(addDot(before, 0, (getLast(after)[1] - getLast(before)[1]) / 2));
        after.push(addDot(after, 0, getLast(before)[1] - getLast(after)[1]));
        return;
      default:
        before.push(addDot(before, getLast(after)[0] - getLast(before)[0], getLast(after)[1] - getLast(before)[1]));
    }
  };
  const getLast = (array: [number, number][]): [number, number] => array[array.length - 1];

  const before: [number, number][] = [[dots[0].left, dots[0].top]];
  const after: [number, number][] = [[dots[1].left, dots[1].top]];
  const nodes: number[] = [];

  if (dots[0].azimuth % 2 === 1) before.push(addDot(before, (2 - dots[0].azimuth) * SAFE_AREA_SIZE, 0));
  if (dots[0].azimuth % 2 === 0) before.push(addDot(before, 0, (dots[0].azimuth - 1) * SAFE_AREA_SIZE));
  if (dots[1].azimuth % 2 === 1) after.push(addDot(after, (2 - dots[1].azimuth) * SAFE_AREA_SIZE, 0));
  if (dots[1].azimuth % 2 === 0) after.push(addDot(after, 0, (dots[1].azimuth - 1) * SAFE_AREA_SIZE));

  if (Math.abs(getLast(before)[0] - getLast(after)[0]) < 4 || Math.abs(getLast(before)[1] - getLast(after)[1]) < 4) {
    nodes.push(0);
  } else {
    const matrixIndex =
      getLast(before)[0] >= getLast(after)[0]
        ? getLast(before)[1] >= getLast(after)[1]
          ? 0
          : 3
        : getLast(before)[1] >= getLast(after)[1]
        ? 1
        : 2;

    addConnection(matrix[dots[0].azimuth][dots[1].azimuth][matrixIndex]);
  }

  nodes.forEach((t) => addConnection(t));

  return [...before, ...after.reverse()];
};

export const useLinePath = (props?: Pick<LineProps, "direction" | "stream" | "device">) =>
  useMemo(() => {
    if (!props) return "";
    const dots = calculateLines(props);

    return `M${dots.map(([l, t]) => `${l},${t}`).join(" ")}`;
  }, [props]);
