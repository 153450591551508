import styled, { css } from "styled-components";

export const Container = styled.div(
  ({ theme: { spacings, colors } }) => css`
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin: -${spacings.xmedium + 1}px -${spacings.xmedium} 2px -${spacings.xmedium};
    padding-top: ${spacings.xsmall + 1}px;
    box-shadow: inset 0 -1px 0 ${colors.border.secondary};
    background: ${colors.page};
    overflow: hidden;

    &[aria-disabled] {
      box-shadow: inset 0 -1px 0 ${colors.border.tertiary};
    }
  `,
);

export const Tab = styled.span(
  ({ theme: { colors, spacings } }) => css`
    padding: ${spacings.medium} ${spacings.xmedium + 1}px;
    cursor: pointer;
    color: inherit;
    text-decoration: none;

    &:first-child {
      margin-left: -1px;
    }

    &[aria-current] {
      box-shadow: inset 0 -1px 0 ${colors.page}, inset 0 0 0 1px ${colors.border.secondary};
    }

    *[aria-disabled] > & {
      color: ${colors.content.tertiary};
      pointer-events: none;

      &[aria-current] {
        box-shadow: inset 0 -1px 0 ${colors.page}, inset 0 0 0 1px ${colors.border.tertiary};
      }
    }
  `,
);
