import React from "react";
import styled, { css } from "styled-components";

interface Props {
  title?: React.ReactNode;
  children?: React.ReactNode;
  button?: {
    icon?: React.ReactNode;
    onClick: () => void;
  };

  disabled?: boolean;
}

export const Panel = React.memo((props: Props) => {
  const { title, children, button, disabled } = props;

  return (
    <Container disabled={disabled}>
      {title && (
        <Header>
          <Title>{title}</Title>
          {button && (
            <Expander disabled={disabled} onClick={button.onClick}>
              {button.icon}
            </Expander>
          )}
        </Header>
      )}
      {children}
    </Container>
  );
});

const Container = styled.div<{ disabled?: boolean }>(
  ({ theme: { colors, spacings }, disabled }) => css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: ${spacings.xmedium};
    gap: ${spacings.medium};
    border-top: 1px solid ${colors.border.secondary};
    background: ${colors.page};
    color: ${disabled ? colors.content.tertiary : colors.content.primary};

    &:first-child {
      border-top: none;
    }
  `,
);

const Title = styled.span`
  flex-grow: 1;

  > input {
    margin-top: -4px;
    margin-bottom: -4px;
  }
`;

const Header = styled.div(
  ({ theme: { colors, typography } }) => css`
    font-size: ${typography.fontSize};
    line-height: ${typography.lineHeight};
    font-weight: 700;
    color: ${colors.content.primary};
    flex-grow: 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  `,
);

const Expander = styled.button(
  ({ theme: { colors, spacings, typography } }) => css`
    appearance: none;
    padding: 0;
    margin: -${(24 - typography.lineHeight) / 2}px -${spacings.xsmall} -24px;
    background: none;
    outline: none;
    border: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    right: 0;
    fill: ${colors.content.secondary};

    &:hover {
      fill: ${colors.content.primary};
    }

    &:disabled {
      fill: ${colors.content.tertiary};
      cursor: default;
    }
  `,
);
