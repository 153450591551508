import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useEventListener from "@use-it/event-listener";
import { useLinePath } from "../lines/use-line-path";
import { BindingMagnet } from "./binding-magnet";
import { ReactComponent as Connector } from "assets/connector.svg";
import { getTargetDirection, isDevice, isMassStream } from "utils";
import { getFlowPosition, isMagnetMatches } from "./binding.helpers";
import { setBinding, setSelected } from "reducers/editor";
import { createBind, createBindedStream } from "reducers/project";
import { BindDirection, DeviceType, schemeModels, StreamType } from "const";
import { EditorState, Position, ProjectRouteParams, ReduxState, Size } from "types";
import { IDevice, IMassLink, IMassStream } from "models";
import { ILineDot, LineProps } from "canvas/components";
import { useTheme } from "styled-components";
import { theme } from "theme";

export interface IMagnet {
  id: number;
  type: DeviceType | StreamType;
  position: Position;
  offset: Position;
  azimuth: number;
  connectorIndex: number;
  direction: BindDirection;
}

export const Binding = React.memo((props: { canvasRef: React.RefObject<any> }) => {
  const dispatch = useDispatch();
  const { projectId } = useParams<ProjectRouteParams>();
  const { canvasRef } = props;
  const devices = useSelector<ReduxState, IDevice[]>(({ project }) => project?.devices || [], shallowEqual);
  const massStreams = useSelector<ReduxState, IMassStream[]>(({ project }) => project?.massStreams || [], shallowEqual);
  const massLinks = useSelector<ReduxState, IMassLink[]>(({ project }) => project?.massLinks || [], shallowEqual);
  const { binding, offset, zoom } = useSelector<ReduxState, EditorState>(({ editor }) => editor, shallowEqual);
  const editorPosition = useSelector<ReduxState, Nullable<Position & Size>>(
    ({ layout }) => layout.editorPosition,
    shallowEqual,
  );
  const [startDot, setStartDot] = useState<ILineDot>();
  const [endDot, setEndDot] = useState<ILineDot>();
  const [magnet, setMagnet] = useState<IMagnet>();
  const [streamPosition, setStreamPosition] = useState<Position>();
  const streamModel = useMemo(() => isDevice(binding) && schemeModels[StreamType.Mass], [binding]);

  const startMagnet = useMemo(() => {
    if (!binding) return undefined;
    const modelParams = schemeModels[binding.type]?.[binding.direction];
    const bindProps = modelParams && modelParams[Math.min(binding.connectorIndex, modelParams.length - 1)];
    return bindProps && { ...binding, ...bindProps };
  }, [binding]);

  const magnets = useMemo(() => {
    if (!binding?.direction) return [];
    const _magnets: IMagnet[] = [];
    const deviceDirection = isDevice(binding) ? getTargetDirection(binding.direction) : binding.direction;

    deviceDirection &&
      devices.forEach((device) => {
        if (device.id === binding.id && device.type === binding.type) return;
        const model = schemeModels[device.type];
        if (!model) return;
        const deviceLinks = massLinks.filter(
          (item) => item.deviceId === device.id && item.direction === deviceDirection,
        );

        deviceLinks.forEach((item) => {
          _magnets.push({
            id: item.deviceId,
            type: device.type,
            connectorIndex: item.connectorIndex,
            direction: deviceDirection,
            position: device.position,
            ...model[deviceDirection][Math.min(item.connectorIndex, model[deviceDirection].length - 1)],
          });
        });
      });

    !isMassStream(binding) &&
      massStreams.forEach((massStream) => {
        if (
          massLinks.find(
            (link) =>
              link.direction === binding.direction &&
              link.massStreamId === massStream.id &&
              link.deviceId === binding.id,
          )
        )
          return;

        const modelParams = schemeModels[StreamType.Mass]?.[binding.direction];
        modelParams &&
          _magnets.push({
            id: massStream.id,
            type: massStream.type,
            position: massStream.position,
            connectorIndex: 0,
            direction: binding.direction,
            ...modelParams[0],
          });
      });

    return _magnets;
  }, [binding, devices, massStreams, massLinks]);

  useEffect(() => {
    if (binding && (!isMassStream(binding) || magnet)) {
      document.body.style.cursor = "pointer";
    }

    return () => {
      document.body.style.cursor = "default";
    };
  }, [binding, magnet]);

  const handlePointerMove = useCallback(
    (e) => {
      if (!editorPosition || !binding || !startMagnet) return;
      const { direction, connectorIndex, id, type, ...restBind } = binding;

      const left = (e.pageX - editorPosition.left - editorPosition.width / 2) * (1 / zoom) + offset.left;
      const top = (e.pageY - editorPosition.top - editorPosition.height / 2) * (1 / zoom) + offset.top;

      const modelParams = schemeModels[type]?.[direction];
      const bindProps = modelParams && modelParams[Math.min(connectorIndex, modelParams.length - 1)];

      setStartDot(!bindProps || isMagnetMatches(startMagnet, left, top) ? undefined : { ...restBind, ...bindProps });

      const _magnet = magnets.find((m) => isMagnetMatches(m, left, top));
      setStreamPosition(
        _magnet
          ? isDevice(_magnet) && isDevice(binding)
            ? getFlowPosition(binding, _magnet)
            : undefined
          : { left, top },
      );

      setEndDot(
        _magnet || {
          position: { left, top },
          ...(streamModel
            ? {
                ...streamModel[binding.direction][0],
                offset: {
                  ...streamModel[binding.direction][0].offset,
                  left:
                    streamModel[binding.direction][0].offset.left +
                    (binding.direction === BindDirection.StreamToDevice ? -10 : 10),
                },
              }
            : { offset: { left: 0, top: 0 }, azimuth: binding.direction === BindDirection.StreamToDevice ? 3 : 1 }),
        },
      );

      setMagnet(_magnet);
    },
    [zoom, offset, editorPosition, magnets, binding, startMagnet, streamModel],
  );

  const handleClick = useCallback(() => {
    if (!editorPosition || !binding || !startDot) return;

    if (magnet && isDevice(magnet) !== isDevice(binding)) {
      const massStream = isMassStream(magnet) ? magnet : binding;
      const device = isDevice(magnet) ? magnet : binding;
      dispatch(setSelected(undefined));
      return dispatch(createBind(+projectId, binding.direction, massStream.id, device.id, device.connectorIndex));
    }

    if (isDevice(binding) && streamPosition) {
      const deviceFrom = binding.direction === BindDirection.DeviceToStream ? binding : magnet;
      const deviceTo = binding.direction === BindDirection.DeviceToStream ? magnet : binding;
      dispatch(setSelected(undefined));
      return dispatch(createBindedStream(+projectId, { deviceFrom, deviceTo, streamPosition }));
    }

    dispatch(setBinding(undefined));
  }, [dispatch, magnet, binding, editorPosition, projectId, streamPosition, startDot]);

  useEffect(() => {
    return () => {
      setEndDot(undefined);
      setMagnet(undefined);
      setStartDot(undefined);
    };
  }, [binding]);

  useEventListener("pointermove", handlePointerMove, (binding && canvasRef.current) ?? null);
  useEventListener("pointerup", handleClick, (binding && canvasRef.current) ?? null);

  const lines: Pick<LineProps, "direction" | "stream" | "device">[] = useMemo(() => {
    if (!binding || !endDot || !startDot) return [];
    const targetDirection = getTargetDirection(binding.direction);
    if (!targetDirection) return [];

    if (isDevice(binding) && streamPosition) {
      if (!magnet) return [{ direction: binding.direction, device: startDot, stream: endDot }];
      if (isMassStream(magnet)) return [{ direction: binding.direction, device: startDot, stream: magnet }];

      if (!streamModel) return [];
      return [
        {
          direction: binding.direction,
          device: startDot,
          stream: {
            position: streamPosition,
            ...streamModel[binding.direction][0],
            offset: {
              ...streamModel[binding.direction][0].offset,
              left:
                streamModel[binding.direction][0].offset.left +
                (binding.direction === BindDirection.DeviceToStream ? 10 : -10),
            },
          },
        },
        {
          direction: targetDirection,
          device: magnet,
          stream: {
            position: streamPosition,
            ...streamModel[targetDirection][0],
            offset: {
              ...streamModel[targetDirection][0].offset,
              left:
                streamModel[targetDirection][0].offset.left -
                (binding.direction === BindDirection.DeviceToStream ? 10 : -10),
            },
          },
        },
      ];
    }

    if (magnet) return [{ direction: targetDirection, stream: startDot, device: magnet }];

    return [{ direction: targetDirection, stream: startDot, device: endDot }];
  }, [startDot, endDot, streamPosition, streamModel, magnet, binding]);

  const path0 = useLinePath(lines[0]);
  const path1 = useLinePath(lines[1]);

  const { colors } = useTheme() as typeof theme;

  if (!binding) return null;

  return (
    <g>
      <g fill="none">
        {path0 && <path stroke={colors.edit} strokeDasharray={4} d={path0} />}
        {path1 && <path stroke={colors.edit} strokeDasharray={4} d={path1} />}
      </g>
      {streamPosition && streamModel && lines.length && (
        <g
          fill={`${colors.edit}`}
          transform={`translate(${streamPosition.left - streamModel.width / 4}, ${
            streamPosition.top - streamModel.height / 4
          }) scale(0.5 0.5)`}
        >
          <path d="M26.5973 0.0846412C26.9603 -0.0750412 27.3834 -0.0051344 27.6757 0.262835L39.6757 11.2628C39.8824 11.4522 40 11.7197 40 12C40 12.2803 39.8824 12.5477 39.6757 12.7371L27.6757 23.7371C27.3834 24.0051 26.9603 24.075 26.5973 23.9153C26.2343 23.7557 26 23.3966 26 23V18H1C0.447715 18 0 17.5523 0 17V6.99999C0 6.4477 0.447715 5.99999 1 5.99999H26V0.99999C26 0.603422 26.2343 0.244324 26.5973 0.0846412Z" />
        </g>
      )}
      {startMagnet && (
        <g
          transform={`translate(${startMagnet.position.left + startMagnet.offset.left - 5} ${
            startMagnet.position.top + startMagnet.offset.top - 5
          }) rotate(${startMagnet.azimuth * 90} 5 5)`}
          fill={colors.edit}
          stroke={colors.edit}
          strokeWidth={3}
          fillOpacity={0}
        >
          <Connector />
        </g>
      )}
      {magnets.map((m, index) => m !== magnet && <BindingMagnet key={index} {...m} />)}
      {magnet && <BindingMagnet {...magnet} isActive />}
    </g>
  );
});
