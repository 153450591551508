import { shallowEqual, useSelector } from "react-redux";
import { ProjectRouteParams, ReduxState } from "types";
import { IDevice, IMassStream } from "models";

export const usePropertiesData = (params: ProjectRouteParams) => {
  return useSelector<ReduxState, IMassStream | IDevice | undefined>(
    (state) =>
      params.deviceId
        ? state.project?.devices?.find((item) => item.id.toString() === params.deviceId)
        : params.massStreamId
        ? state.project?.massStreams?.find((item) => item.id.toString() === params.massStreamId)
        : undefined,
    shallowEqual,
  );
};
