import { Dispatch } from "redux";
import * as massLinksApi from "services/massLinks";
import { updateProjectEntities } from "reducers/project/project";
import { BindDirection } from "const";
import { Position, ReduxState } from "types";
import { setBinding, setSelected } from "reducers/editor";
import { postMassStream } from "services/massStreams";
import { ApiAction, EntityType } from "services/types";
import { IMassStream } from "models";

export const removeMassLink =
  (projectId: number, id?: number) => async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    if (!id) return;

    const token = getState().auth.token;
    if (!token) return;

    try {
      const response = await massLinksApi.deleteMassLink(token, projectId, id);
      dispatch(updateProjectEntities(projectId, response));
    } catch {}
  };

export const unbindDevice =
  (projectId: number, direction: BindDirection, deviceId: number, index: number) =>
  (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const massLinks = getState().project?.massLinks || [];
    const id = massLinks.find(
      (item) => item.deviceId === deviceId && item.direction === direction && item.connectorIndex === index,
    )?.id;
    id && dispatch(removeMassLink(projectId, id));
  };

export const createBind =
  (projectId: number, direction: BindDirection, massStreamId: number, deviceId: number, connectorIndex: number) =>
  async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const token = getState().auth.token;
    if (!token) return;

    dispatch(setBinding(undefined));

    const payload = {
      projectId,
      massStreamId,
      deviceId,
      connectorIndex,
    };

    try {
      const response = await (direction === BindDirection.StreamToDevice
        ? massLinksApi.bindMassStream(token, payload)
        : massLinksApi.bindMassDevice(token, payload));
      dispatch(updateProjectEntities(projectId, response));
    } catch {}
  };

export const createBindedStream =
  (
    projectId: number,
    payload: {
      deviceFrom?: { id: number; connectorIndex: number };
      deviceTo?: { id: number; connectorIndex: number };
      streamPosition: Position;
    },
  ) =>
  async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const token = getState().auth.token;
    if (!token) return;

    const { streamPosition, deviceFrom, deviceTo } = payload;
    dispatch(setBinding(undefined));

    if (!streamPosition) return;

    try {
      let response = await postMassStream(token, projectId, { position: streamPosition });
      const massStream = response.find(
        (item) => item.action === ApiAction.Create && item.entityType === EntityType.MassStream,
      )?.data as IMassStream | undefined;

      if (!massStream) return dispatch(updateProjectEntities(projectId, response));

      if (deviceFrom) {
        response = [
          ...response,
          ...(await massLinksApi.bindMassDevice(token, {
            projectId,
            deviceId: deviceFrom.id,
            massStreamId: massStream.id,
            connectorIndex: deviceFrom.connectorIndex,
          })),
        ];
      }
      if (deviceTo) {
        response = [
          ...response,
          ...(await massLinksApi.bindMassStream(token, {
            projectId,
            deviceId: deviceTo.id,
            massStreamId: massStream.id,
            connectorIndex: deviceTo.connectorIndex,
          })),
        ];
      }
      dispatch(updateProjectEntities(projectId, response));
      dispatch(setSelected(massStream));
    } catch {
      dispatch(setSelected(undefined));
    }
  };
