import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export const createQuery = function (params?: { [key: string]: any }) {
  const items = Object.keys(params || {})
    .map(function (key: string) {
      return !params?.[key] && params?.[key] !== false && params?.[key] !== 0 && params?.[key] !== ""
        ? null
        : `${key}=${
            Array.isArray(params[key])
              ? params[key].map((x: any) => encodeURIComponent(x)).join(",")
              : encodeURIComponent(params[key])
          }`;
    })
    .filter((x) => x !== null);

  if (items.length === 0) {
    return "";
  }

  return "?" + items.join("&");
};

export const parseQuery = (query: string) => {
  const res: Record<string, string> = {};
  if (!query) return res;

  const items = query.substr(1).split("&");
  items.forEach((item) => {
    const pairs = item.split("=");
    res[decodeURIComponent(pairs[0])] = decodeURIComponent(pairs[1]);
  });

  return res;
};

export const useLocationQuery = () => {
  const search = useLocation().search;

  return useMemo(() => parseQuery(search), [search]);
};
