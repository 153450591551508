import React, { useCallback, useEffect, useRef, useState } from "react";
import { authenticationContext } from "authentification/index";
import { User, UserManager, WebStorageStateStore } from "oidc-client";
import { Loader } from "components";
import { AUTH_URL } from "consts.env";
import { Container } from "dashboard/dashboard.styles";
import { ReactComponent as Logo } from "assets/logo.svg";
import { AppContainer } from "app/app";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ReduxState } from "types";
import { setUser } from "reducers/auth";
import { useHistory } from "react-router-dom";

export const Authentication = React.memo((props: React.PropsWithChildren<any>) => {
  const [userManager, setUserManager] = useState<UserManager>();
  const searchRef = useRef(window.location.search);
  const { Provider: AuthProvider } = authenticationContext;
  const history = useHistory();
  const userState = useSelector<ReduxState, Nullable<User>>(({ auth }) => auth.user, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    setUserManager(
      (currentManager) =>
        currentManager ||
        new UserManager({
          authority: `${AUTH_URL}`,
          scope: "openid profile roles",
          client_id: "chemingway-client",
          redirect_uri: `${window.location.origin}/authentication/login-callback`,
          silent_redirect_uri: `${window.location.origin}/authentication/login-callback`,
          post_logout_redirect_uri: `${window.location.origin}`,
          response_type: "code",
          automaticSilentRenew: true,
          monitorSession: false,
          includeIdTokenInSilentRenew: true,
          accessTokenExpiringNotificationTime: 75,
          loadUserInfo: true,
          userStore: new WebStorageStateStore({ store: window.localStorage }),
        }),
    );

    return () => {
      setUserManager(undefined);
    };
  }, [searchRef]);

  const handleLogin = useCallback(async () => {
    if (!userManager) return;

    /** Callback */
    if (window.location.pathname === "/authentication/login-callback") {
      try {
        const user = await userManager.signinRedirectCallback();
        if (user) {
          dispatch(setUser(user));
          history.replace(localStorage.getItem("returnURL") || "/");
          localStorage.removeItem("returnURL");
        }
      } catch {}
    } else {
      const user = await userManager.getUser();

      if (user && !user.expired) {
        dispatch(setUser(user));
      } else {
        localStorage.setItem("returnURL", `${window.location.pathname}${searchRef.current}`);
        userManager.signinRedirect();
      }
    }
  }, [dispatch, userManager, history]);

  useEffect(() => {
    handleLogin();
  }, [handleLogin]);

  return (
    <AuthProvider value={{ user: userState }}>
      {userState ? (
        props.children
      ) : (
        <AppContainer>
          <Container>
            <Loader size={48} style={{ flexGrow: 1 }} />
            <Logo />
          </Container>
        </AppContainer>
      )}
    </AuthProvider>
  );
});
