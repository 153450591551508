import React, { useCallback } from "react";
import styled, { css } from "styled-components";
import { BindDirection } from "const";
import { Select, SelectProps } from "components/select";

interface Props extends Omit<SelectProps, "onChange" | "value"> {
  label: string;
  direction: BindDirection;
  connectorIndex: number;
  onChange: (massStreamId: Nullable<number>, direction: BindDirection, index: number) => void;
  value: Nullable<number>;
}

export const StreamSelect = React.memo((props: Props) => {
  const { value, onChange, direction, connectorIndex, label, ...restProps } = props;

  const handleChange = useCallback(
    (val: string) => {
      onChange(val ? +val : null, direction, connectorIndex);
    },
    [direction, connectorIndex, onChange],
  );

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Select value={value?.toString() || ""} onChange={handleChange} {...restProps} />
    </Wrapper>
  );
});

const Label = styled.span(
  ({
    theme: {
      colors,
      typography: { caption },
    },
  }) => css`
    font-size: ${caption.fontSize};
    line-height: ${caption.lineHeight};
    color: ${colors.content.secondary};
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
);

const Wrapper = styled.span(
  ({ theme: { spacings } }) => css`
    position: relative;
    outline: none;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    gap: ${spacings.xsmall};
  `,
);
