import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Binder } from "../binder";
import { schemeModels } from "const";
import { ariaAttributes } from "utils";
import { useObjectHandlers } from "canvas/use-object-handlers";
import { IDevice, IMassLink } from "models";
import { EditorState, ReduxState } from "types";
import * as Markup from "../../canvas.styles";

export const Device = React.memo((props: IDevice) => {
  const { id, type, name, position: defaultPosition } = props;
  const massLinks = useSelector<ReduxState, IMassLink[]>(
    ({ project }) => (project?.massLinks || []).filter((item) => item.deviceId === id),
    shallowEqual,
  );
  const selected = useSelector<ReduxState, EditorState["selected"]>(({ editor }) => editor.selected, shallowEqual);
  const binding = useSelector<ReduxState, EditorState["binding"]>(({ editor }) => editor.binding, shallowEqual);
  const model = useMemo(() => schemeModels[type], [type]);
  const [isSelected, isBindSelected, position] = useMemo(
    () =>
      selected && selected.id === id && selected.type === type
        ? [true, Boolean(selected.direction), selected.position]
        : [false, false, defaultPosition],
    [selected, id, type, defaultPosition],
  );

  const handlers = useObjectHandlers(props, defaultPosition);

  if (!model) return null;

  return (
    <Markup.Object
      {...ariaAttributes(isSelected && !isBindSelected && !binding && "selected", binding && "disabled")}
      role="device"
      key={id}
      transform={`translate(${position.left - model.width / 2}, ${position.top - model.height / 2})`}
      {...handlers}
    >
      {model.source}
      {name && (
        <Markup.Text
          textAnchor="middle"
          x={model.width / 2}
          y={model.height + 12}
          style={{ fontSize: 10, width: model.width }}
        >
          {name}
        </Markup.Text>
      )}
      {massLinks.map((item) => {
        const modelParams = model[item.direction][Math.min(item.connectorIndex, model[item.direction].length - 1)];
        return (
          <Binder
            key={item.id}
            parent={props}
            direction={item.direction}
            azimuth={modelParams.azimuth}
            left={model.width / 2 + modelParams.offset.left}
            top={model.height / 2 + modelParams.offset.top}
            index={item.connectorIndex}
            isBinded={Boolean(item.massStreamId)}
            isSelected={
              isBindSelected &&
              selected?.direction === item.direction &&
              selected?.connectorIndex === item.connectorIndex
            }
          />
        );
      })}
    </Markup.Object>
  );
});
