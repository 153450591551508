import React from "react";
import { DeviceType, StreamType } from "const";
import * as ToolIcons from "assets/toolbar";

export const getToolIcon = (type?: DeviceType | StreamType): React.ReactNode => {
  switch (type) {
    case StreamType.Mass:
      return <ToolIcons.Flow />;
    case DeviceType.Mixer:
      return <ToolIcons.Mixer />;
    case DeviceType.Valve:
      return <ToolIcons.Valve />;
    case DeviceType.Separator2p:
      return <ToolIcons.Separator2ph />;
    case DeviceType.Separator3p:
      return <ToolIcons.Separator3ph />;
    default:
      return null;
  }
};
