import styled, { css } from "styled-components";

export const Column = styled.span`
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding-top: 1px;
  padding-bottom: 1px;
  flex-grow: 1;
  min-width: 170px;
`;

export const Row = styled.span`
  display: flex;
  gap: 1px;
  flex-grow: 1;
  width: 100%;
`;

export const Cell = styled.span<{ head?: boolean }>(
  ({ theme: { colors, typography, spacings }, head }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: ${typography.lineHeight};
    height: ${spacings.xsmall * 2 + typography.lineHeight}px;
    box-shadow: 0 1px 0 ${colors.border.tertiary};

    > span {
      display: block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > input {
      margin: -${spacings.xsmall} 0;
    }

    > svg {
      min-width: 20px;
      width: 20px;
      height: 20px;
      fill: ${colors.content.tertiary};
      stroke: ${colors.content.primary};
      stroke-width: 2px;
      margin-right: 2px;
    }

    ${head &&
    css`
      font-weight: 700;
      padding-right: ${spacings.medium};
    `};
  `,
);

export const ScrollerWrapper = styled.span<{ count?: number }>`
  min-width: ${(p) => Math.min(p.count || 1, 2) * 91}px;
  flex-grow: 0;
`;

export const Data = styled.span<{ count?: number }>`
  display: flex;
  gap: 1px;

  > ${Column} {
    max-width: 90px;
    min-width: 90px;

    > ${Cell} {
      justify-content: flex-end;
    }

    &:last-child > ${Cell} {
      margin-right: 1px;
    }

    &:first-child > ${Cell} {
      margin-left: 1px;
    }
  }
`;

export const List = styled.div<{ isDisabled?: boolean; secondary?: boolean }>(
  (p) => css`
    margin: 0;
    display: ${p.secondary ? "inline-flex" : "flex"};

    ${p.isDisabled &&
    css`
      color: ${p.theme.colors.content.tertiary};
      pointer-events: none;
    `};

    ${p.secondary &&
    css`
      ${Cell} {
        box-shadow: none;
      }
    `};
  `,
);
