import { useCallback, useEffect, useRef, useState } from "react";

export const useControlLoading = <T = string>(
  value: T,
  onChange: (value: T, ...rest: any) => void,
): [boolean, (value: T, ...rest: any) => void] => {
  const timerRef = useRef(0);
  const [isLoading, setLoading] = useState(false);
  const valueRef = useRef(value);

  useEffect(() => {
    valueRef.current = value;
    setLoading(false);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, timerRef]);

  const handleChange = useCallback(
    (val: T, ...rest: any) => {
      if (val !== valueRef.current) {
        setLoading(true);
        timerRef.current = window.setTimeout(() => setLoading(false), 5000);
      }
      onChange(val, ...rest);
    },
    [valueRef, onChange],
  );

  return [isLoading, handleChange];
};
