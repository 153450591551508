const emptyObject = {};

export const parseLocationQuery = (query: string) => {
  if (!query) return emptyObject;
  const obj: Record<string, string> = {};
  query
    .slice(1)
    .split("&")
    .forEach((param) => {
      const [key, value] = param.split("=");
      obj[key] = value;
    });

  return obj;
};
