import { isDevice, isMassStream } from "utils";
import { ReduxState } from "types";
import { IComponent, IProjectData } from "models";
import { Dispatch } from "redux";
import { DeviceType, StreamType } from "const";
import { ApiAction, ApiResponse, EntityType } from "services/types";
import * as projectsApi from "services/projects";
import * as componentsApi from "services/components";

const initialState = null as ReduxState["project"];

const SET_DATA = "project/SET_DATA";
const SET_DATA_COMPONENTS = "project/SET_DATA_COMPONENTS";
const UPDATE_DATA_ENTITIES = "project/UPDATE_DATA_ENTITIES";
const SORT_SELECTED = "project/SORT_SELECTED";

interface SetDataAction {
  type: typeof SET_DATA;
  data: Nullable<IProjectData>;
}

interface UpdateDataEntitiesAction {
  type: typeof UPDATE_DATA_ENTITIES;
  payload: ApiResponse;
}

interface SetDataComponentsAction {
  type: typeof SET_DATA_COMPONENTS;
  payload: IComponent[];
}

interface SortSelectedAction {
  type: typeof SORT_SELECTED;
  payload: {
    id: number;
    type: DeviceType | StreamType;
  };
}

export type ProjectActions = SetDataAction | UpdateDataEntitiesAction | SetDataComponentsAction | SortSelectedAction;

export default (state = initialState, action: ProjectActions) => {
  switch (action.type) {
    case SET_DATA:
      return action.data
        ? {
            ...action.data,
            massLinks: action.data.massLinks.sort((a, b) => (a.id > b.id ? 1 : -1)),
          }
        : null;

    case SET_DATA_COMPONENTS:
      if (!state) return state;
      return {
        ...state,
        massComponents: action.payload,
      };

    case UPDATE_DATA_ENTITIES:
      if (!state) return state;

      const devices = action.payload.filter((item) => item.entityType === EntityType.Device);
      const massStreams = action.payload.filter((item) => item.entityType === EntityType.MassStream);
      const massLinks = action.payload.filter((item) => item.entityType === EntityType.MassLink);

      const deviceIds = devices.map((item) => item.data.id);
      const massStreamIds = massStreams.map((item) => item.data.id);
      const massLinksIds = massLinks.map((item) => item.data.id);

      return {
        ...state,
        devices: [
          ...state.devices.filter((item) => !deviceIds.includes(item.id)),
          ...devices
            .filter((item) => item.action === ApiAction.Update || item.action === ApiAction.Create)
            .map(({ data }) => data),
        ],
        massStreams: [
          ...state.massStreams.filter((item) => !massStreamIds.includes(item.id)),
          ...massStreams
            .filter((item) => item.action === ApiAction.Update || item.action === ApiAction.Create)
            .map(({ data }) => data),
        ],
        massLinks: [
          ...state.massLinks.filter((item) => !massLinksIds.includes(item.id)),
          ...massLinks
            .filter((item) => item.action === ApiAction.Update || item.action === ApiAction.Create)
            .map(({ data }) => data),
        ].sort((a, b) => (a.id > b.id ? 1 : -1)),
      } as IProjectData;

    case SORT_SELECTED: {
      if (!state) return state;

      return {
        ...state,
        devices: isDevice(action.payload)
          ? state.devices.sort((item) => (item.id === action.payload.id ? 1 : -1))
          : state.devices,
        massStreams: isMassStream(action.payload)
          ? state.massStreams.sort((item) => (item.id === action.payload.id ? 1 : -1))
          : state.massStreams,
      };
    }

    default:
      return state;
  }
};

export const loadProjectData = (id: number) => async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
  try {
    const token = getState().auth.token;
    if (!token) return;

    const data = await projectsApi.getProject(token, id);
    dispatch({ type: SET_DATA, data });
  } catch {
    dispatch({ type: SET_DATA, data: null });
  }
};

export const unloadProjectData = (projectId: number) => async (dispatch: Dispatch<any>, getState: () => ReduxState) =>
  getState().project?.project?.id === projectId && dispatch({ type: SET_DATA, data: undefined });

export const updateProjectEntities =
  (projectId: number, payload: ApiResponse) => async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    getState().project?.project.id === projectId && dispatch({ type: UPDATE_DATA_ENTITIES, payload });
  };

export const saveComponents =
  (projectId: number, componentIds: number[]) => async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const token = getState().auth.token;
    if (!token) return;

    try {
      const payload = await componentsApi.saveProjectComponents(token, projectId, componentIds);
      dispatch({ type: SET_DATA_COMPONENTS, payload });
    } catch {
      dispatch({ type: SET_DATA_COMPONENTS, payload: null });
    }
  };

export const sortSelected = (payload: { id: number; type: DeviceType | StreamType }) => ({
  type: SORT_SELECTED,
  payload,
});
