import { User } from "oidc-client";
import { ReduxState } from "types";

const initialState: ReduxState["auth"] = {
  token: undefined,
  user: undefined,
};

const SET_USER = "auth/SET_USER";

interface SetUserAction {
  type: typeof SET_USER;
  payload: Nullable<User>;
}

export type AuthActions = SetUserAction;

export default (state = initialState, action: AuthActions) => {
  switch (action.type) {
    case SET_USER:
      return {
        user: action.payload,
        token: action.payload?.access_token,
      };

    default:
      return state;
  }
};

export const setUser = (payload: Nullable<User>) => ({ type: SET_USER, payload });
