import styled, { css } from "styled-components";

export const List = styled.span(
  ({ theme: { colors, components } }) => css`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;

    > span {
      cursor: pointer;
      padding: 2px 7px;
      outline: none;
      border-radius: ${components.button.borderRadius - 2}px;

      &[aria-current] {
        background: ${colors.accent}33;
      }

      &[aria-selected],
      &:active {
        background: ${colors.accent};
        color: ${colors.white};
      }
    }
  `,
);

export const Popup = styled.span(
  ({ theme: { colors, elevations, components } }) => css`
    position: absolute;
    width: 100%;
    max-height: 200px;
    align-items: stretch;
    background: ${colors.white};
    z-index: 100;
    margin-top: 1px;
    border-radius: ${components.button.borderRadius};
    box-shadow: ${elevations.button.hover};
    display: flex;
    opacity: 0;
    visibility: hidden;
    pointer-events: auto;
    transition: opacity 0.05s, visibility 0.05s;
    outline: none;

    &:focus,
    &:focus-within {
      opacity: 1;
      visibility: visible;
    }
  `,
);

export const Control = styled.input(
  ({
    theme: {
      spacings,
      typography,
      components: { control },
    },
  }) => css`
    appearance: none;
    -webkit-appearance: none;
    display: block;
    width: 100%;
    font-size: inherit;
    padding: ${spacings.xsmall} ${spacings.medium + 10}px ${spacings.xsmall} ${spacings.medium};
    line-height: ${typography.lineHeight};
    min-height: ${control.height};
    max-height: ${control.height};
    font-family: inherit;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    box-shadow: 0 0 0 1px ${control.default.borderColor};
    background: ${control.default.backgroundColor};
    color: ${control.default.color};
    pointer-events: auto;
    font-weight: 700;

    &:hover {
      box-shadow: 0 0 0 1px ${control.hover.borderColor};
    }

    &:disabled {
      color: ${control.disabled.color};
      box-shadow: 0 0 0 1px ${control.default.borderColor};
      cursor: default;
    }
  `,
);

export const Wrapper = styled.span(
  ({
    theme: {
      colors,
      components: { control },
    },
  }) => css`
    position: relative;
    outline: none;

    &:focus-within > ${Popup} {
      opacity: 1;
      visibility: visible;
    }

    &:focus > ${Popup} {
      opacity: 0;
      visibility: hidden;
    }

    &:focus-within > ${Control}:not(:disabled) {
      box-shadow: 0 0 0 1px ${control.focus.borderColor};
      color: ${control.focus.color};
      background: ${control.focus.backgroundColor};
    }

    &:focus > ${Control}:not(:disabled) {
      box-shadow: 0 0 0 1px ${control.hover.borderColor};
      background: ${control.default.backgroundColor};
      color: ${control.default.color};
    }

    &:after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-top: 5px solid ${colors.content.tertiary};
      right: 7px;
      bottom: 6px;
    }

    &:hover:after {
      border-top-color: ${control.hover.borderColor};
    }

    &:focus-within:after {
      border-top-color: ${colors.content.primary};
    }

    &:focus:after {
      border-top-color: ${control.hover.borderColor};
    }

    &[aria-disabled]:after,
    &[aria-disabled]:hover:after,
    &[aria-disabled]:focus:after,
    &[aria-disabled]:focus-within:after {
      border-top-color: ${control.default.borderColor};
    }

    &[aria-busy="true"] {
      background-color: ${control.default.backgroundColor};
    }
  `,
);
