import React from "react";
import styled, { css } from "styled-components";
import { useLinePath } from "./use-line-path";
import { IMassLink } from "models";
import { StreamType } from "const";
import { Position } from "types";

export interface ILineDot {
  position: Position;
  offset: Position;
  azimuth: number;
}

export interface LineProps extends IMassLink {
  type: StreamType;
  stream: ILineDot;
  device: ILineDot;
}

export const Line = React.memo((props: LineProps) => {
  return (
    <g>
      <Path d={useLinePath(props)} />
    </g>
  );
});

const Path = styled.path(
  ({ theme: { colors } }) => css`
    stroke: ${colors.content.tertiary};
    stroke-width: 1;
    stroke-opacity: 0.7;
  `,
);
