import { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeZoom, setSelected, setViewportOffset, zoomToFit } from "reducers/editor";
import { Position, ReduxState, EditorState } from "types";
import { CTRL_KEY } from "consts.env";

export const useCanvasPosition = () => {
  const dispatch = useDispatch();
  const { binding, offset, zoom } = useSelector<ReduxState, EditorState>(({ editor }) => editor, shallowEqual);
  const dragCursorPositionRef = useRef<Position>();
  const cursorPositionRef = useRef<Position>();
  const viewportOffsetRef = useRef<Position | null>();
  const [isDrag, setDrag] = useState(false);
  const hasMovedRef = useRef(false);
  const dblClickTimeoutRef = useRef(0);

  useEffect(() => {
    if (!isDrag) {
      viewportOffsetRef.current = offset;
    }
  }, [isDrag, offset]);

  useEffect(() => {
    return () => {
      clearTimeout(dblClickTimeoutRef.current);
    };
  }, [dblClickTimeoutRef]);

  return {
    onPointerDown: useCallback(
      (ev: React.PointerEvent) => {
        if (binding) return;
        if (ev.button !== 0) return;
        dragCursorPositionRef.current = {
          left: ev.pageX,
          top: ev.pageY,
        };

        setDrag(true);
        hasMovedRef.current = false;

        const handlePointerMove = (e: PointerEvent) => {
          hasMovedRef.current =
            Math.abs(e.pageX - (dragCursorPositionRef.current?.left || 0)) > 3 ||
            Math.abs(e.pageY - (dragCursorPositionRef.current?.top || 0)) > 3;

          hasMovedRef.current &&
            dispatch(
              setViewportOffset({
                left:
                  (viewportOffsetRef.current?.left || 0) -
                  (e.pageX - (dragCursorPositionRef.current?.left || 0)) * (1 / zoom),
                top:
                  (viewportOffsetRef.current?.top || 0) -
                  (e.pageY - (dragCursorPositionRef.current?.top || 0)) * (1 / zoom),
              }),
            );
        };

        const handlePointerUp = () => {
          setDrag(false);
          !hasMovedRef.current && dispatch(setSelected(undefined));
          document.removeEventListener("pointermove", handlePointerMove);
          document.removeEventListener("pointerup", handlePointerUp);
        };

        document.addEventListener("pointermove", handlePointerMove);
        document.addEventListener("pointerup", handlePointerUp);
      },
      [dispatch, dragCursorPositionRef, viewportOffsetRef, binding, zoom, hasMovedRef],
    ),
    onPointerMove: useCallback(
      (e: React.PointerEvent) => {
        cursorPositionRef.current = {
          left: e.pageX,
          top: e.pageY,
        };
      },
      [cursorPositionRef],
    ),
    onPointerUp: useCallback(
      (e) => {
        if (e.button !== 1) return;

        if (dblClickTimeoutRef.current) {
          dispatch(zoomToFit());
          clearTimeout(dblClickTimeoutRef.current);
          dblClickTimeoutRef.current = 0;
          return;
        }

        dblClickTimeoutRef.current = window.setTimeout(() => {
          dblClickTimeoutRef.current = 0;
        }, 300);
      },
      [dispatch],
    ),
    onWheel: useCallback(
      (e: React.WheelEvent) => {
        if (e[CTRL_KEY]) return;
        dispatch(changeZoom(-e.deltaY, cursorPositionRef.current));
      },
      [dispatch, cursorPositionRef],
    ),
  };
};
