import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { NavbarButton } from "panels/navbar/navbar-button";
import { Icons } from "assets";
import { ProjectMeta, ReduxState } from "types";
import { loadProjects } from "reducers/datum";
import { closeProject, openProject } from "reducers/layout";
import { useHistory } from "react-router-dom";
import { IProject } from "models";

export const Navbar = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const openedProjects = useSelector<ReduxState, ProjectMeta[]>(({ layout }) => layout.openedProjects, shallowEqual);
  const projects = useSelector<ReduxState, Nullable<IProject[]>>(({ datum }) => datum.projects, shallowEqual);
  const isLoadedRef = useRef(false);

  useEffect(() => {
    dispatch(loadProjects());
  }, [dispatch]);

  useLayoutEffect(() => {
    const savedOpened: ProjectMeta[] = JSON.parse(localStorage.getItem("openedProjects") || "[]");
    savedOpened.forEach((item) => {
      dispatch(openProject(item));
    });
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("openedProjects", JSON.stringify(openedProjects));
  }, [dispatch, openedProjects]);

  useEffect(() => {
    if (!projects || isLoadedRef.current) return;
    isLoadedRef.current = true;
    openedProjects.forEach((opened) => {
      if (!projects.some((project) => project.id === opened.id)) {
        dispatch(closeProject(opened.id, history));
      }
    });
  }, [openedProjects, projects, isLoadedRef, dispatch, history]);

  const handleClose = useCallback((id?: number) => id && dispatch(closeProject(id, history)), [dispatch, history]);

  return (
    <Container>
      <NavbarButton url="/">
        <Icons.Projects width={20} height={20} />
      </NavbarButton>
      {openedProjects.map((item) => (
        <NavbarButton key={item.id} {...item} onClose={handleClose} />
      ))}
    </Container>
  );
});

const Container = styled.div(
  ({ theme: { components, typography } }) => css`
    min-height: ${components.control.height + 2}px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    line-height: ${typography.lineHeight};
    background: ${components.navbar.background};
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
  `,
);
