import { IDevice } from "models";
import { API_URL } from "consts.env";
import { ApiResponse } from "services/types";

export const postDevice = async (
  token: string,
  projectId: number,
  payload: Pick<IDevice, "position" | "type">,
): Promise<ApiResponse> => {
  const response = await fetch(`${API_URL}/${projectId}/device`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({ ...payload, name: "1" }),
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};

export const putDevice = async (token: string, projectId: number, payload: IDevice): Promise<ApiResponse> => {
  const response = await fetch(`${API_URL}/${projectId}/device/${payload.id}`, {
    method: "PUT",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};

export const deleteDevice = async (token: string, projectId: number, id: number): Promise<ApiResponse> => {
  const response = await fetch(`${API_URL}/${projectId}/device/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};
