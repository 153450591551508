import { API_URL } from "consts.env";
import { IComponent } from "models";

export const getComponents = async (token: string): Promise<IComponent[]> => {
  const response = await fetch(`${API_URL}/component`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};

export const saveProjectComponents = async (token: string, projectId: number, componentIds: number[]) => {
  const response = await fetch(`${API_URL}/${projectId}/component`, {
    method: "PUT",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify(componentIds),
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};
