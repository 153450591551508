import { IDevice, IMassLink, IMassStream } from "models";

export type ApiMassStream = {
  action: ApiAction;
  entityType: typeof EntityType.MassStream;
  data: IMassStream;
};
export type ApiDevice = { action: ApiAction; entityType: typeof EntityType.Device; data: IDevice };
export type ApiMassLink = { action: ApiAction; entityType: typeof EntityType.MassLink; data: IMassLink };

export enum ApiAction {
  Create = "Create",
  Update = "Update",
  Delete = "Delete",
}

export enum EntityType {
  Device = "Device",
  MassLink = "MassLink",
  MassStream = "MassStream",
}

export type ApiResponse = (ApiMassLink | ApiMassStream | ApiDevice)[];
