import { useCallback, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setBinding, setSelected, updateObjectPosition } from "reducers/editor";
import { isDevice, isMassStream } from "utils";
import { addObjectModal } from "reducers/layout";
import { Position, ProjectRouteParams, ReduxState } from "types";
import { IDevice, IMassStream } from "models";

export const useObjectHandlers = (props: IMassStream | IDevice, position: Position) => {
  const { projectId } = useParams<ProjectRouteParams>();
  const dispatch = useDispatch();
  const zoom = useSelector<ReduxState, number>(({ editor }) => editor.zoom, shallowEqual);
  const isDisabled = useSelector<ReduxState, boolean>(({ editor }) => Boolean(editor.binding));
  const hasMovedRef = useRef(false);

  return {
    onPointerDown: useCallback(
      (ev: React.PointerEvent) => {
        if (ev.button !== 0) return;
        ev.stopPropagation();

        if (isDisabled) return;
        const { id, type, name } = props;
        const { pageX, pageY } = ev;
        const start = { pageX, pageY };
        let currentPosition = position;
        dispatch(setSelected({ id, position, name, type }));
        dispatch(setBinding(undefined));
        hasMovedRef.current = false;

        const handlePointerMove = (e: PointerEvent) => {
          hasMovedRef.current = Math.abs(e.pageX - start.pageX) > 3 || Math.abs(e.pageY - start.pageY) > 3;
          if (!hasMovedRef.current) return;

          currentPosition = {
            left: position.left + (e.pageX - start.pageX) * (1 / zoom),
            top: position.top + (e.pageY - start.pageY) * (1 / zoom),
          };
          dispatch(setSelected({ id, type, name, position: currentPosition }));
        };

        const handlePointerUp = () => {
          hasMovedRef.current && dispatch(updateObjectPosition(+projectId, props, currentPosition));
          document.removeEventListener("pointermove", handlePointerMove);
          document.removeEventListener("pointerup", handlePointerUp);
        };

        document.addEventListener("pointermove", handlePointerMove);
        document.addEventListener("pointerup", handlePointerUp);
      },
      [projectId, zoom, position, props, dispatch, isDisabled, hasMovedRef],
    ),
    onDoubleClick: useCallback(() => {
      if (isDisabled || hasMovedRef.current) return;
      dispatch(
        addObjectModal(
          {
            page: "properties",
            massStreamId: isMassStream(props) ? String(props.id) : undefined,
            deviceId: isDevice(props) ? String(props.id) : undefined,
            projectId,
            tab: "info",
          },
          props,
        ),
      );
    }, [props, dispatch, projectId, isDisabled]),
    onClick: useCallback((e) => e.stopPropagation(), []),
  };
};
