import { CTRL_KEY } from "consts.env";

export const keyboardDispatcher = (e: KeyboardEvent) => {
  switch (e.key) {
    case "!":
      if (!e.shiftKey) return;
      e.stopPropagation();
      return;

    case "0":
    case "-":
    case "=":
      if (!e[CTRL_KEY]) return;
      e.preventDefault();
      e.stopPropagation();
      return;

    case "\\":
      if (!e[CTRL_KEY]) return;
      e.stopPropagation();
      return;

    default:
      return;
  }
};
