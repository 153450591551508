import React, { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalWindow, ModalWindowProps } from "./modal-window";
import { ModalData, Position, ReduxState } from "types";
import { setModalPosition } from "reducers/layout";

export const ModalContainer = React.memo(() => {
  const dispatch = useDispatch();
  const modals = useSelector<ReduxState, ModalData[]>(({ layout }) => layout.modals, shallowEqual);
  const [items, setItems] = useState<Omit<ModalWindowProps, "onMove" | "onFocus">[]>([]);
  const defaultPositionRef = useRef({ left: 0, top: 0 });

  useEffect(() => {
    if (modals.length === 0) {
      defaultPositionRef.current = { left: 0, top: 0 };
    }
  }, [modals]);

  const getModalPosition = useCallback(() => {
    defaultPositionRef.current = {
      left: (defaultPositionRef.current.left % 400) + 20,
      top: (defaultPositionRef.current.top % 300) + 20,
    };
    return defaultPositionRef.current;
  }, [defaultPositionRef]);

  useEffect(() => {
    /**
     * 1. Переоткрытие. В стейте есть последний элемент из редакса:
     *    Переносим элемент в конец.
     * 2. Добавление. В стейте нет элемента, который есть в редаксе:
     *    Добавляем новый элемент, высчитываем позицию окна
     * 3. Удаление. В редаксе нет элемента, который есть в стейте:
     *    Фильтруем стейт
     */
    const modalPaths = modals.map((m) => m.path);
    const lastModal = modals[modals.length - 1];

    setItems((_items) => {
      const filtered = _items.filter((item) => modalPaths.includes(item.path));
      const itemPaths = filtered.map((i) => i.path);

      if (lastModal && itemPaths.find((path) => path === lastModal.path)) {
        return [
          ...filtered.filter((i) => i.path !== lastModal.path),
          filtered.find((i) => i.path === lastModal.path) as ModalWindowProps,
        ];
      }

      if (lastModal && !itemPaths.find((path) => path === lastModal.path)) {
        return [...filtered, { ...lastModal, position: lastModal.position || getModalPosition() }];
      }

      return filtered;
    });
  }, [modals, getModalPosition]);

  const handleMove = useCallback(
    (path: string, position: Position) => {
      dispatch(setModalPosition(path, position));
      setItems((_items) => _items.map((item) => (item.path === path ? { ...item, position } : item)));
    },
    [dispatch],
  );

  const handleFocus = useCallback((path: string) => {
    setItems((_items) => [
      ..._items.filter((item) => item.path !== path),
      ..._items.filter((item) => item.path === path),
    ]);
  }, []);

  return (
    <>
      {items.map((item) => (
        <ModalWindow key={item.path} {...item} onMove={handleMove} onFocus={handleFocus} />
      ))}
    </>
  );
});
