import React, { useCallback, useContext } from "react";
import { Icons } from "assets";
import { modalContext, ModalContext } from "components/modal/modal.context";
import * as Markup from "./modal.styles";

export interface ModalHeaderProps {
  title: React.ReactNode;
  tabs?: React.ReactNode;
}

export const ModalHeader = React.memo((props: ModalHeaderProps) => {
  const { title, tabs } = props;
  const { onMove, onMoveEnd, onClose, position } = useContext<ModalContext>(modalContext) || {};

  const onPointerDown = useCallback(
    (e: React.PointerEvent) => {
      const initial = { left: e.pageX, top: e.pageY };

      const handlePointerMove = (event: PointerEvent) => {
        onMove({
          left: position.left + event.pageX - initial.left,
          top: position.top + event.pageY - initial.top,
        });
      };

      const handlePointerUp = (event: PointerEvent) => {
        onMoveEnd({
          left: position.left + event.pageX - initial.left,
          top: position.top + event.pageY - initial.top,
        });

        document.removeEventListener("pointermove", handlePointerMove);
        document.removeEventListener("pointerup", handlePointerUp);
      };

      document.addEventListener("pointermove", handlePointerMove);
      document.addEventListener("pointerup", handlePointerUp);
    },
    [onMove, onMoveEnd, position],
  );

  return (
    <Markup.Header onPointerDown={onPointerDown}>
      <Markup.Title>{title}</Markup.Title>
      <Markup.HeaderTabs>{tabs}</Markup.HeaderTabs>
      <Markup.Close onClick={onClose}>
        <Icons.Close />
      </Markup.Close>
    </Markup.Header>
  );
});
