import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import throttle from "lodash/throttle";
import { Position, ReduxState } from "types";

const handleReplaceHistory = throttle((offset: Position, zoom: number, url: string) => {
  window.history.replaceState(
    {},
    "",
    `${url}?p=${Math.round(offset.left)};${Math.round(offset.top)};${(zoom || 1).toFixed(2)}`,
  );
}, 250);

export const useLocationQuery = () => {
  const [offset, zoom] = useSelector<ReduxState, [Position, number]>(
    ({ editor }) => [editor.offset, editor.zoom],
    shallowEqual,
  );
  const url = useRouteMatch().url;

  useEffect(() => {
    handleReplaceHistory(offset, zoom, url);
  }, [offset, zoom, url]);
};
