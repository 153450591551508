import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { Scrollbar } from "components/scroller/components/scrollbar";
import { IDevice, IMassStream } from "models";
import { EditorState, LayoutState, ReduxState } from "types";
import { Bounds, combineBounds, getBoundsSize, getProjectBounds } from "utils";

export const Scrollbars = React.memo(() => {
  const { offset, zoom, selected } = useSelector<ReduxState, EditorState>(({ editor }) => editor, shallowEqual);
  const { editorPosition, hasUI } = useSelector<ReduxState, LayoutState>(({ layout }) => layout, shallowEqual);
  const devices = useSelector<ReduxState, Nullable<IDevice[]>>(({ project }) => project?.devices, shallowEqual);
  const massStreams = useSelector<ReduxState, Nullable<IMassStream[]>>(
    ({ project }) => project?.massStreams,
    shallowEqual,
  );
  const bounds = useMemo(() => getProjectBounds({ devices, massStreams, selected }), [devices, massStreams, selected]);

  const [viewportSize, fullSize, viewportOffset] = useMemo(() => {
    const viewportBounds: Bounds = [
      {
        left: offset.left - ((editorPosition?.width ?? 0) / 2 + (hasUI ? 236 : 0)) / zoom,
        top: offset.top - (editorPosition?.height ?? 0) / 2 / zoom,
      },
      {
        left: offset.left + ((editorPosition?.width ?? 0) / 2 - (hasUI ? 420 : 0)) / zoom,
        top: offset.top + (editorPosition?.height ?? 0) / 2 / zoom,
      },
    ];

    const fullBounds = combineBounds(viewportBounds, bounds);
    if (!fullBounds) return [];

    return [
      getBoundsSize(viewportBounds),
      getBoundsSize(fullBounds),
      {
        top: viewportBounds[0].top - fullBounds[0].top,
        left: viewportBounds[0].left - fullBounds[0].left,
      },
    ];
  }, [editorPosition, offset, bounds, hasUI, zoom]);

  const [verticalProps, horizontalProps] = useMemo(() => {
    if (!fullSize || !viewportSize || !viewportOffset)
      return [
        { size: 1, position: 0, isVisible: false },
        { size: 1, position: 0, isVisible: false },
      ];

    const verticalSize = viewportSize.height / fullSize.height;
    const horizontalSize = viewportSize.width / fullSize.width;

    return [
      !isNaN(verticalSize) && verticalSize < 1
        ? {
            size: verticalSize,
            position: viewportOffset.top / (fullSize.height - viewportSize.height),
            isVisible: true,
          }
        : { size: 1, position: 0, isVisible: false },
      !isNaN(horizontalSize) && horizontalSize < 1
        ? {
            size: horizontalSize,
            position: viewportOffset.left / (fullSize.width - viewportSize.width),
            isVisible: true,
          }
        : { size: 1, position: 0, isVisible: false },
    ];
  }, [fullSize, viewportSize, viewportOffset]);

  const styleOverrides: React.CSSProperties = useMemo(() => ({ pointerEvents: "none" }), []);

  return (
    <ScrollbarsWrapper hasUI={hasUI}>
      <Scrollbar {...verticalProps} hasMargin type={"vertical"} style={styleOverrides} />
      <Scrollbar {...horizontalProps} hasMargin type={"horizontal"} style={styleOverrides} />
    </ScrollbarsWrapper>
  );
});

const ScrollbarsWrapper = styled.div<{ hasUI: boolean }>(
  ({ hasUI }) => css`
    position: absolute;
    left: ${hasUI ? 236 : 0}px;
    top: 0;
    right: ${hasUI ? 420 : 0}px;
    bottom: 0;
    pointer-events: none;
  `,
);
