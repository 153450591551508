import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { BindDirection, DeviceType, schemeModels, StreamType } from "const";
import { ReduxState, EditorState } from "types";
import { IDevice, IMassLink, IMassStream } from "models";
import { ILineDot, LineProps } from "canvas/components/lines/line";
import { isDevice, isMassStream } from "utils";

export const useRenderLines = (withSelected = true) => {
  const devices = useSelector<ReduxState, IDevice[]>(({ project }) => project?.devices || [], shallowEqual);
  const massStreams = useSelector<ReduxState, IMassStream[]>(({ project }) => project?.massStreams || [], shallowEqual);
  const massLinks = useSelector<ReduxState, IMassLink[]>(({ project }) => project?.massLinks || [], shallowEqual);
  const selected = useSelector<ReduxState, EditorState["selected"]>(({ editor }) => editor.selected, shallowEqual);
  const [lines, setLines] = useState<LineProps[]>([]);

  useEffect(() => {
    if (!massLinks.length) return setLines([]);

    setLines(
      massLinks
        .filter((massLink) => massLink.deviceId && massLink.massStreamId)
        .map((massLink) => {
          const massStream = massStreams.find((item) => item.id === massLink.massStreamId);
          const device = devices.find((item) => item.id === massLink.deviceId);
          if (!massStream || !device) return null;

          const bindIndex =
            device.type === DeviceType.Mixer && massLink.direction === BindDirection.StreamToDevice
              ? Math.min(1, massLink.connectorIndex)
              : massLink.connectorIndex;

          const streamDot: ILineDot = {
            ...schemeModels[StreamType.Mass][massLink.direction][0],
            position: massStream.position,
          };

          const deviceDot: ILineDot = {
            ...schemeModels[device.type][massLink.direction][bindIndex],
            position: device.position,
          };

          return {
            ...massLink,
            type: StreamType.Mass,
            stream: streamDot,
            device: deviceDot,
          };
        })
        .filter((line) => line) as LineProps[],
    );
  }, [devices, massStreams, massLinks]);

  useEffect(() => {
    if (!withSelected || !selected) return;
    setLines((_lines) =>
      _lines.map((line) => {
        if (isMassStream(selected) && line.massStreamId === selected.id) {
          return {
            ...line,
            stream: {
              ...line.stream,
              position: selected.position,
            },
          };
        }

        if (isDevice(selected) && line.deviceId === selected.id) {
          return {
            ...line,
            device: {
              ...line.device,
              position: selected.position,
            },
          };
        }

        return line;
      }),
    );
  }, [selected, withSelected]);

  return lines;
};
