import styled, { css } from "styled-components";
import { Container as PanelTabs, Tab } from "../panel-tabs/panel-tabs.styles";

export const Window = styled.div(
  ({ theme: { colors, elevations, components } }) => css`
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    color: ${colors.content.primary};
    min-height: 300px;
    min-width: 400px;
    background: ${colors.page};
    z-index: 1000;
    box-shadow: ${elevations.modal.default};
    border-radius: ${components.button.borderRadius};
    padding: 3px;
    overflow: hidden;

    &:last-child {
      background: ${colors.border.tertiary};
      box-shadow: ${elevations.modal.active};
    }
  `,
);

export const Header = styled.div(
  ({ theme: { spacings, colors } }) => css`
    padding: ${spacings.medium} ${spacings.xlarge} ${spacings.medium};
    box-shadow: inset 0 -1px 0 ${colors.border.tertiary};
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
  `,
);

export const Title = styled.div(
  ({ theme: { spacings, colors } }) => css`
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: ${spacings.small};
    min-height: 24px;

    > svg {
      margin: -${spacings.xmedium};
      margin-right: 0;
      width: 32px;
      fill: ${colors.accent};
      stroke: ${colors.content.primary};
    }
  `,
);

export const HeaderTabs = styled.div(
  ({ theme: { spacings, colors } }) => css`
    flex-grow: 1;
    padding: 0 30px;
    font-weight: 500;
    z-index: 1;

    > ${PanelTabs} {
      margin: -${spacings.small} 0 -${spacings.medium} 0;
      box-shadow: none;
      background: none;
      padding-left: 1px;

      > ${Tab} {
        border-radius: 6px 6px 0 0;

        &:first-child {
          margin: 0;
        }

        &[aria-current] {
          background: ${colors.page};
          box-shadow: 0 0 0 1px ${colors.border.tertiary};
        }
      }
    }
  `,
);

export const Footer = styled.div(
  ({ theme: { spacings } }) => css`
    display: flex;
    padding: ${spacings.medium} ${spacings.xlarge};
    justify-content: center;
    align-items: center;
    gap: ${spacings.medium};
  `,
);

export const Close = styled.span(
  ({ theme: { spacings, colors } }) => css`
    width: 24px;
    height: 24px;
    cursor: pointer;
    fill: ${colors.content.secondary};
    margin: -${spacings.xsmall};
    margin-right: -${spacings.xmedium};

    &:hover {
      fill: ${colors.content.primary};
    }
  `,
);

export const Body = styled.div`
  flex-basis: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  background: ${(p) => p.theme.colors.page};
  border-radius: ${(p) => p.theme.components.button.borderRadius / 2}px;
`;

export const Content = styled.div(
  ({ theme: { spacings } }) => css`
    padding: ${spacings.large} ${spacings.xlarge};
  `,
);
