import React, { useCallback, useMemo } from "react";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import thunk from "redux-thunk";
import { Project } from "project";
import { Dashboard } from "dashboard";
import { Navbar } from "panels";
import { ModalContainer } from "components/modal/modal-container";
import { Authentication } from "authentification";
import datumReducer from "reducers/datum";
import editorReducer from "reducers/editor";
import projectReducer from "reducers/project";
import detailsReducer from "reducers/details";
import layoutReducer from "reducers/layout";
import authReducer from "reducers/auth";
import { theme } from "theme";
import { ReduxState } from "types";

export const App = React.memo(() => {
  const composeEnhancers =
    process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

  const store = useMemo(
    () =>
      createStore(
        combineReducers<ReduxState>({
          details: detailsReducer,
          datum: datumReducer,
          project: projectReducer,
          editor: editorReducer,
          layout: layoutReducer,
          auth: authReducer,
        }),
        composeEnhancers(applyMiddleware(thunk)),
      ),
    [composeEnhancers],
  );

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Authentication>
            <AppContainer>
              <Navbar />
              <Switch>
                <Route
                  path={[
                    "/project/:projectId/d:deviceId/:tab?",
                    "/project/:projectId/ms:massStreamId/:tab?",
                    "/project/:projectId",
                  ]}
                  component={Project}
                />
                <Route path="/" exact component={Dashboard} />
                <Redirect to="/" />
              </Switch>
            </AppContainer>
            <ModalContainer />
          </Authentication>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
});

export const AppContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: ${(p) => p.theme.components.control.height + 2}px; ;
`;
