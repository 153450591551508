import { setBinding, setSelected } from "reducers/editor";
import { updateProjectEntities } from "reducers/project";
import { ApiAction, EntityType } from "services/types";
import { Position, ReduxState } from "types";
import { Dispatch } from "redux";
import { IMassStream } from "models";
import * as streamsApi from "services/massStreams";

export const createMassStream =
  (projectId: number, payload: { position: Position }) =>
  async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const token = getState().auth.token;
    if (!token) return;

    try {
      const response = await streamsApi.postMassStream(token, projectId, payload);
      dispatch(updateProjectEntities(projectId, response));
    } catch {
      dispatch(setSelected(undefined));
      dispatch(setBinding(undefined));
    }
  };

export const updateMassStreamPosition =
  (projectId: number, payload: IMassStream) => async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const token = getState().auth.token;
    if (!token) return;

    const currentData = getState().project?.massStreams.find((item) => item.id === payload.id);
    try {
      dispatch(
        updateProjectEntities(projectId, [
          { entityType: EntityType.MassStream, action: ApiAction.Update, data: payload },
        ]),
      );
      const response = await streamsApi.putMassStream(token, projectId, payload);
      dispatch(updateProjectEntities(projectId, response));
    } catch {
      dispatch(setSelected(undefined));
      dispatch(setBinding(undefined));

      currentData &&
        dispatch(
          updateProjectEntities(projectId, [
            { entityType: EntityType.MassStream, action: ApiAction.Update, data: currentData },
          ]),
        );
    }
  };

export const saveMassStream =
  (projectId: number, payload: IMassStream) => async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const token = getState().auth.token;
    if (!token) return;

    try {
      const response = await streamsApi.putMassStream(token, projectId, payload);
      dispatch(updateProjectEntities(projectId, response));
    } catch {}
  };

export const removeMassStream =
  (projectId: number, id: number) => async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const token = getState().auth.token;
    if (!token) return;

    const currentData = getState().project?.massStreams.find((item) => item.id === id);

    try {
      dispatch(
        updateProjectEntities(projectId, [
          { entityType: EntityType.MassStream, action: ApiAction.Delete, data: { id } as IMassStream },
        ]),
      );

      dispatch(setSelected(undefined));
      dispatch(setBinding(undefined));
      const response = await streamsApi.deleteMassStream(token, projectId, id);
      dispatch(updateProjectEntities(projectId, response));
    } catch {
      if (!currentData) return;
      dispatch(
        updateProjectEntities(projectId, [
          { entityType: EntityType.MassStream, action: ApiAction.Create, data: currentData },
        ]),
      );
      dispatch(setSelected(currentData));
    }
  };
