import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import { deleteProject } from "reducers/datum";
import { openProject } from "reducers/layout";
import { IProject } from "models";
import { DashboardProjectPreview } from "dashboard/dashboard-project-preview";
import * as Markup from "./dashboard.styles";

export const DashboardProject = (props: IProject) => {
  const { id, name, lastUpdateTimestamp } = props;
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(openProject({ id }));
  }, [dispatch, id]);

  const handleDelete = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      confirm("Удалить проект?") && dispatch(deleteProject(id));
    },
    [dispatch, id],
  );

  return (
    <Markup.Project as={Link} to={`/project/${id}`} onClick={handleClick}>
      <Markup.Delete onClick={handleDelete}>–</Markup.Delete>
      <DashboardProjectPreview id={id} />
      <Markup.Name>{name}</Markup.Name>
      <Markup.Caption>Обновлён: {format(new Date(lastUpdateTimestamp), "dd.MM.yyyy, HH:mm")}</Markup.Caption>
    </Markup.Project>
  );
};
