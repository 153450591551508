import React from "react";
import { Line } from "./line";
import { useRenderLines } from "./use-render-lines";

export const Lines = React.memo(() => {
  const lines = useRenderLines();

  return (
    <g fill="none" strokeWidth="1" stroke="#444444">
      {lines.map((line) => (
        <Line {...line} key={`${line.type}_${line.id}`} />
      ))}
    </g>
  );
});
