import React, { useCallback, useLayoutEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import useEventListener from "@use-it/event-listener";
import { Binding, Device, Lines, MassStream, Scrollbars } from "./components";
import { setEditorPosition } from "reducers/layout";
import { useCanvasPosition } from "./use-canvas-position";
import { useCanvasDrop } from "./use-canvas-drop";
import { useCanvasKeyboard } from "canvas/use-canvas-keyboard";
import { useLocationQuery } from "./use-location-query";
import { IDevice, IMassStream } from "models";
import { ReduxState, EditorState, Size, Position } from "types";
import * as Markup from "./canvas.styles";
import { Loader } from "components";

export const Canvas = React.memo(() => {
  const dispatch = useDispatch();
  const devices = useSelector<ReduxState, IDevice[] | undefined>(({ project }) => project?.devices, shallowEqual);
  const massStreams = useSelector<ReduxState, IMassStream[] | undefined>(
    ({ project }) => project?.massStreams,
    shallowEqual,
  );
  const { offset, zoom } = useSelector<ReduxState, EditorState>(({ editor }) => editor, shallowEqual);
  const hasUI = useSelector<ReduxState, boolean>(({ layout }) => Boolean(layout.hasUI));
  const editorPosition = useSelector<ReduxState, Nullable<Size & Position>>(
    ({ layout }) => layout.editorPosition,
    shallowEqual,
  );
  const containerRef = useRef<HTMLDivElement | null>(null);

  useLocationQuery();

  const handleResize = useCallback(() => {
    if (!containerRef.current) return;
    const { width, height, left, top } = containerRef.current.getBoundingClientRect();
    dispatch(setEditorPosition({ left, top, width, height }));
  }, [dispatch, containerRef]);

  useLayoutEffect(handleResize, [handleResize]);
  useEventListener("resize", handleResize);

  return (
    <Markup.Container ref={containerRef} {...useCanvasPosition()} {...useCanvasDrop()} {...useCanvasKeyboard()}>
      {massStreams && devices ? (
        <>
          <svg
            tabIndex={0}
            width={editorPosition?.width}
            height={editorPosition?.height}
            viewBox={`0 0 ${(editorPosition?.width || 0) / zoom} ${(editorPosition?.height || 0) / zoom}`}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              transform={`translate(${(editorPosition?.width || 0) / 2 / zoom - offset.left}, ${
                (editorPosition?.height || 0) / 2 / zoom - offset.top
              })`}
            >
              <Lines />

              {devices?.map((device) => (
                <Device {...device} key={device.id} />
              ))}

              {massStreams?.map((massStream) => (
                <MassStream {...massStream} key={massStream.id} />
              ))}

              <Binding canvasRef={containerRef} />
            </g>
          </svg>
          <Scrollbars />
        </>
      ) : (
        <Loader style={{ height: "100%", margin: hasUI ? "0 420px 0 236px" : undefined }} />
      )}
    </Markup.Container>
  );
});
