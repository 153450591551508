import React from "react";
import { ModalData } from "types";
import { ModalComponentsList, ModalProperties } from "modals";

export const ModalContent = (props: ModalData["params"]) => {
  const { page, ...restProps } = props;

  switch (page) {
    case "properties":
      return <ModalProperties {...restProps} />;
    case "components-list":
      return <ModalComponentsList {...restProps} />;
    default:
      return null;
  }
};
