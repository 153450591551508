import { EditorState, Position, Size } from "types";
import { IDevice, IMassStream } from "models";
import { schemeModels } from "const";

export type Bounds = [Position, Position];

export const getProjectBounds = (params: {
  devices: Nullable<IDevice[]>;
  massStreams: Nullable<IMassStream[]>;
  selected: EditorState["selected"];
}) => {
  const { devices, massStreams, selected } = params;

  if (!devices || !massStreams) return null;
  if (!devices.length && !massStreams.length) return null;

  const deviceBounds = devices
    .map((item) => getItemBound(item.position, schemeModels[item.type]))
    .reduce(
      (prevBound, currentBound) => combineBounds(prevBound, currentBound),
      getItemBound(selected?.position, selected && schemeModels[selected?.type]),
    );

  return massStreams
    .map((item) => getItemBound(item.position, schemeModels[item.type]))
    .reduce((prevBound, currentBound) => combineBounds(prevBound, currentBound), deviceBounds);
};

export const getItemBound = (position: Nullable<Position>, size: Nullable<Size>): Nullable<Bounds> => {
  if (!position || !size) return null;
  return [
    {
      left: position.left - size.width / 2,
      top: position.top - size.height / 2,
    },
    {
      left: position.left + size.width / 2,
      top: position.top + size.height / 2,
    },
  ];
};

export const combineBounds = (boundsA: Nullable<Bounds>, boundsB: Nullable<Bounds>): Nullable<Bounds> => {
  if (!boundsA || !boundsB) return boundsA || boundsB;
  return [
    {
      left: Math.min(boundsA[0].left, boundsB[0].left),
      top: Math.min(boundsA[0].top, boundsB[0].top),
    },
    {
      left: Math.max(boundsA[1].left, boundsB[1].left),
      top: Math.max(boundsA[1].top, boundsB[1].top),
    },
  ];
};

export const getBoundsSize = (bounds: Nullable<Bounds>): Nullable<Size> => {
  if (!bounds) return null;
  return {
    width: bounds[1].left - bounds[0].left,
    height: bounds[1].top - bounds[0].top,
  };
};
