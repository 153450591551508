export const getKeys = function (name: string): (string | number)[] {
  if (!name) {
    return [];
  }

  return name
    .replace(/\[/g, ".")
    .split(".")
    .map((x) => (x.substr(-1) === "]" ? Number(x.substr(0, x.length - 1)) : String(x)));
};

export const updateStateByKeys = <T>(partial: any, value: any, keys: (number | string)[]): T => {
  if (!keys.length) return value;

  return typeof keys[0] === "string"
    ? {
        ...partial,
        [keys[0]]: updateStateByKeys(partial && partial[keys[0]], value, keys.slice(1)),
      }
    : [
        ...(partial || []).slice(0, keys[0]),
        ...new Array(Math.max(0, keys[0] - (partial || []).length)),
        updateStateByKeys(partial && partial[keys[0]], value, keys.slice(1)),
        ...(partial || []).slice(keys[0] + 1),
      ];
};

export const updateProperties = <T>(state: T, value: any, name?: string): T => {
  if (!name) return state;
  return updateStateByKeys<T>(state, value, getKeys(name));
};
