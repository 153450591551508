import React, { useState } from "react";
import { ModalBody, ModalHeader, ObjectImage, PanelTabs, Worksheet, useWorksheets } from "components";
import { usePropertiesData } from "properties";
import { getToolIcon } from "utils";
import { ProjectRouteParams } from "types";

const tabs = [
  { value: "info", label: "Инфо" },
  { value: "components", label: "Компоненты" },
  { value: "conditions", label: "Параметры" },
  { value: "characteristics", label: "Свойства" },
];

export const ModalProperties = React.memo((params: ProjectRouteParams) => {
  const [tab, setTab] = useState(params.tab || "info");
  const objectData = usePropertiesData(params);
  const [workSheetData, deviceConditions] = useWorksheets(params);

  return (
    <>
      <ModalHeader
        title={
          objectData ? (
            <>
              {getToolIcon(objectData.type)}
              {objectData.name}
            </>
          ) : null
        }
        tabs={<PanelTabs items={tabs} name={"tab"} default={"info"} value={tab} onChange={setTab} />}
      />
      <ModalBody width={800} height={400} key={tab}>
        {tab === "info" && <ObjectImage params={params} scale={2.5} objectData={objectData} />}
        {tab === "info" && deviceConditions && <Worksheet {...deviceConditions} secondary />}
        {(tab === "components" || !tab) && <Worksheet {...workSheetData.components} />}
        {tab === "conditions" && <Worksheet {...workSheetData.conditions} />}
        {tab === "characteristics" && <Worksheet {...workSheetData.characteristics} />}
      </ModalBody>
    </>
  );
});
