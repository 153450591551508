import { updateProjectEntities } from "reducers/project";
import { setBinding, setSelected } from "reducers/editor";
import { ApiAction, EntityType } from "services/types";
import { DeviceType } from "const";
import { Position, ReduxState } from "types";
import { Dispatch } from "redux";
import { IDevice } from "models";
import * as devicesApi from "services/devices";

export const createDevice =
  (projectId: number, payload: { type: DeviceType; position: Position }) =>
  async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const token = getState().auth.token;
    if (!token) return;

    try {
      const response = await devicesApi.postDevice(token, projectId, payload);
      dispatch(updateProjectEntities(projectId, response));

      const selected = response.find(
        (item) => item.action === ApiAction.Create && item.entityType === EntityType.Device,
      )?.data as IDevice | undefined;
      selected &&
        dispatch(
          setSelected({
            id: selected.id,
            type: selected.type,
            position: selected.position,
            name: selected.name,
          }),
        );
    } catch {
      dispatch(setSelected(undefined));
      dispatch(setBinding(undefined));
    }
  };

export const updateDevicePosition =
  (projectId: number, payload: IDevice) => async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const token = getState().auth.token;
    if (!token) return;

    const currentData = getState().project?.devices.find((item) => item.id === payload.id);
    try {
      dispatch(
        updateProjectEntities(projectId, [
          {
            entityType: EntityType.Device,
            action: ApiAction.Update,
            data: payload,
          },
        ]),
      );

      const response = await devicesApi.putDevice(token, projectId, payload);
      dispatch(updateProjectEntities(projectId, response));
    } catch {
      dispatch(setSelected(undefined));
      dispatch(setBinding(undefined));

      currentData &&
        dispatch(
          updateProjectEntities(projectId, [
            {
              entityType: EntityType.Device,
              action: ApiAction.Update,
              data: currentData,
            },
          ]),
        );
    }
  };

export const saveDevice =
  (projectId: number, payload: IDevice) => async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const token = getState().auth.token;
    if (!token) return;

    try {
      const response = await devicesApi.putDevice(token, projectId, payload);
      dispatch(updateProjectEntities(projectId, response));
    } catch {}
  };

export const removeDevice =
  (projectId: number, id: number) => async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const token = getState().auth.token;
    if (!token) return;

    const currentData = getState().project?.devices.find((item) => item.id === id);

    try {
      dispatch(
        updateProjectEntities(projectId, [
          {
            entityType: EntityType.Device,
            action: ApiAction.Delete,
            data: { id } as IDevice,
          },
        ]),
      );

      dispatch(setSelected(undefined));
      dispatch(setBinding(undefined));
      const response = await devicesApi.deleteDevice(token, projectId, id);
      dispatch(updateProjectEntities(projectId, response));
    } catch {
      if (!currentData) return;
      dispatch(
        updateProjectEntities(projectId, [
          {
            entityType: EntityType.Device,
            action: ApiAction.Create,
            data: currentData,
          },
        ]),
      );
      dispatch(setSelected(currentData));
    }
  };
