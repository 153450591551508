import { BindDirection } from "const";

export const getTargetDirection = (type?: BindDirection) => {
  switch (type) {
    case BindDirection.StreamToDevice:
      return BindDirection.DeviceToStream;
    case BindDirection.DeviceToStream:
      return BindDirection.StreamToDevice;
    default:
      return undefined;
  }
};
