import { DragEvent, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createDevice, createMassStream } from "reducers/project";
import { DeviceType } from "const";
import { ProjectRouteParams, ReduxState, Position, Size } from "types";

export const useCanvasDrop = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams<ProjectRouteParams>();
  const [offset, zoom, editorPosition] = useSelector<ReduxState, [Position, number, Nullable<Size & Position>]>(
    ({ editor, layout }) => [editor.offset, editor.zoom, layout.editorPosition],
    shallowEqual,
  );

  return {
    onDragOver: useCallback((e: DragEvent) => e.preventDefault(), []),
    onDrop: useCallback(
      (e: DragEvent) => {
        e.preventDefault();
        if (!editorPosition) return;
        const elementPosition = {
          left: (e.pageX - editorPosition.left - editorPosition.width / 2) * (1 / zoom) + offset.left,
          top: (e.pageY - editorPosition.top - editorPosition.height / 2) * (1 / zoom) + offset.top,
        };

        const action = e.dataTransfer.getData("action");
        switch (action) {
          case "createComponent":
            return dispatch(
              createDevice(+projectId, {
                type: e.dataTransfer.getData("type") as DeviceType,
                position: elementPosition,
              }),
            );

          case "createFlow":
            return dispatch(createMassStream(+projectId, { position: elementPosition }));

          default:
            return;
        }
      },
      [offset, editorPosition, zoom, dispatch, projectId],
    ),
  };
};
