export default class CSSUnit {
  private value: number;
  private unit: string;

  constructor(value: number, unit = "px") {
    this.value = value;
    this.unit = unit;
  }

  public valueOf = () => this.value;
  public toString = () => `${this.value}${this.unit}`;
}

const colors = {
  accent: "#00A7E7",
  logo: "#00BDE7",
  danger: "#EE3300",
  orange: "#FF8800",
  green: "#33BB00",
  edit: "#EE9933",
  page: "#f8f7f8",
  white: "#FFFFFF",
  black: "#000000",
  border: {
    secondary: "#D3D3D3",
    tertiary: "#E3E3E3",
  },
  content: {
    primary: "#111122",
    secondary: "#656467",
    tertiary: "#959398",
  },
  container: {
    active: {
      backgroundColor: "#888A9933",
    },
  },
};

export const theme = {
  colors,
  components: {
    navbar: {
      background: "#404144",
    },
    control: {
      height: new CSSUnit(26),
      default: {
        color: colors.content.primary,
        backgroundColor: colors.white,
        borderColor: colors.border.tertiary,
      },
      hover: {
        borderColor: colors.accent,
      },
      focus: {
        borderColor: "#E1CEB0",
        backgroundColor: "#FFF4D8",
        color: colors.black,
      },
      readOnly: {
        borderColor: colors.accent,
        backgroundColor: colors.white,
        color: colors.accent,
      },
      disabled: {
        color: colors.content.tertiary,
        borderColor: "transparent",
        backgroundColor: "transparent",
      },
    },
    button: {
      borderRadius: new CSSUnit(6),
    },
  },
  typography: {
    fontSize: new CSSUnit(13),
    lineHeight: new CSSUnit(18),
    letterSpacing: new CSSUnit(0.2),
    fontWeight: {
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
    caption: {
      fontSize: new CSSUnit(12),
      lineHeight: new CSSUnit(16),
      letterSpacing: new CSSUnit(0),
    },
    measure: {
      fontSize: new CSSUnit(10),
      lineHeight: new CSSUnit(14),
    },
  },
  spacings: {
    xsmall: new CSSUnit(4),
    small: new CSSUnit(6),
    medium: new CSSUnit(8),
    xmedium: new CSSUnit(12),
    large: new CSSUnit(16),
    xlarge: new CSSUnit(20),
  },
  elevations: {
    button: {
      default: "0 1px 1px 0 #00000033, 0 0 0 1px #00000009",
      hover: "0 3px 6px -2px #00000033, 0 0 0 1px #00000022",
      active: "0 0 0 0 #00000000, 0 0 0 1px #00000009",
    },
    modal: {
      default: "0 8px 15px -5px #00000022, 0 0 0 1px #00000011",
      active: "0 14px 30px -10px #00000044, 0 0 0 1px #00000011",
    },
  },
  transitions: {
    short: new CSSUnit(0.1, "s"),
    medium: new CSSUnit(0.2, "s"),
  },
};
