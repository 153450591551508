import React, { useCallback, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { StreamSelect } from "./stream-select";
import { Placeholder } from "components";
import { createBind, unbindDevice } from "reducers/project";
import { BindDirection, schemeModels } from "const";
import { ProjectRouteParams, ReduxState } from "types";
import { IDevice, IMassLink, IMassStream } from "models";
import * as Markup from "components/object-image/object-image.styles";

interface ObjectImageProps {
  params: ProjectRouteParams;
  objectData: Nullable<IDevice | IMassStream>;
  scale?: number;
}

export const ObjectImage = React.memo((props: ObjectImageProps) => {
  const { params, objectData, scale = 1.5 } = props;
  const { projectId, deviceId } = params;
  const dispatch = useDispatch();
  const model = useMemo(() => objectData?.type && schemeModels[objectData.type], [objectData]);

  const deviceLinks = useSelector<ReduxState, IMassLink[]>(
    (state) => (deviceId ? (state.project?.massLinks || []).filter((massLink) => massLink.deviceId === +deviceId) : []),
    shallowEqual,
  );
  const massStreams = useSelector<ReduxState, Nullable<IMassStream[]>>(
    ({ project }) => project?.massStreams,
    shallowEqual,
  );
  const massStreamsList = useMemo(() => {
    return massStreams?.map((stream) => ({ value: stream.id.toString(), label: String(stream.name || stream.id) }));
  }, [massStreams]);

  const [inputStreams, outputStreams] = useMemo(
    () =>
      [BindDirection.StreamToDevice, BindDirection.DeviceToStream].map((type) =>
        deviceLinks
          .filter((link) => link.direction === type)
          .sort((a, b) => (a.connectorIndex > b.connectorIndex ? 1 : -1)),
      ),
    [deviceLinks],
  );

  const handleConnectDeviceStream = useCallback(
    (massStreamId: Nullable<number>, direction: BindDirection, index: number) => {
      deviceId &&
        (massStreamId
          ? dispatch(createBind(+projectId, direction, massStreamId, +deviceId, index))
          : dispatch(unbindDevice(+projectId, direction, +deviceId, index)));
    },
    [dispatch, deviceId, projectId],
  );

  return (
    <Markup.Container scale={scale}>
      {model && objectData ? (
        <>
          {inputStreams.length ? (
            <Markup.Inputs count={inputStreams.length}>
              {inputStreams.map((link, index) => (
                <StreamSelect
                  label={link.label || model[BindDirection.StreamToDevice]?.[index]?.label || `Вход ${index + 1}`}
                  direction={BindDirection.StreamToDevice}
                  connectorIndex={link.connectorIndex}
                  key={link.id}
                  value={link.massStreamId}
                  onChange={handleConnectDeviceStream}
                  list={massStreamsList}
                />
              ))}
            </Markup.Inputs>
          ) : null}
          <svg width={model.width * scale} height={model.height * scale} viewBox={`0 0 ${model.width} ${model.height}`}>
            {model.source}
          </svg>
          {outputStreams.length ? (
            <Markup.Inputs count={outputStreams.length}>
              {outputStreams.map((link, index) => (
                <StreamSelect
                  label={link.label || model[BindDirection.DeviceToStream]?.[index]?.label || `Выход ${index + 1}`}
                  direction={BindDirection.DeviceToStream}
                  connectorIndex={link.connectorIndex}
                  key={link.id}
                  value={link.massStreamId}
                  onChange={handleConnectDeviceStream}
                  list={massStreamsList}
                />
              ))}
            </Markup.Inputs>
          ) : null}
        </>
      ) : (
        <Placeholder>Выберите объект</Placeholder>
      )}
    </Markup.Container>
  );
});
