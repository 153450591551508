import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { ariaAttributes } from "utils";
import { IMagnet } from "canvas/components/binding/binding";
import { BindDirection, StreamType } from "const";

interface Props extends IMagnet {
  isActive?: boolean;
}

export const BindingMagnet = React.memo((props: Props) => {
  const { position, offset, isActive, azimuth, direction, type } = props;

  const rotate = useMemo(() => {
    return (
      (azimuth * 90 + (direction === BindDirection.DeviceToStream ? 180 : 0) + (type !== StreamType.Mass ? 180 : 0)) %
      360
    );
  }, [direction, azimuth, type]);

  return (
    <Container
      {...ariaAttributes(isActive && "selected")}
      transform={`translate(${position.left + offset.left - 5}, ${position.top + offset.top - 5}) `}
    >
      <path d="M5 2L1 8H9L5 2Z" transform={`rotate(${rotate} 5 5)`} strokeWidth={4.5} stroke="#FFFFFF" />
      <path d="M5 2L1 8H9L5 2Z" transform={`rotate(${rotate} 5 5)`} />
    </Container>
  );
});

const Container = styled.g(
  ({ theme: { colors } }) => css`
    fill: ${colors.white};
    stroke: ${colors.edit};
    stroke-width: 1.5;

    &[aria-selected] {
      fill: ${colors.edit};
      stroke: ${colors.edit};
    }
  `,
);
