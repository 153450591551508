import { IMassStream } from "models";
import { API_URL } from "consts.env";
import { ApiResponse } from "services/types";

export const postMassStream = async (
  token: string,
  projectId: number,
  payload: Pick<IMassStream, "position">,
): Promise<ApiResponse> => {
  const response = await fetch(`${API_URL}/${projectId}/massStream/`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({ ...payload, name: "" }),
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};

export const putMassStream = async (token: string, projectId: number, payload: IMassStream): Promise<ApiResponse> => {
  const response = await fetch(`${API_URL}/${projectId}/massStream/${payload.id}`, {
    method: "PUT",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};

export const deleteMassStream = async (token: string, projectId: number, id: number): Promise<ApiResponse> => {
  const response = await fetch(`${API_URL}/${projectId}/massStream/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    throw await response.json();
  }
};
