import { shallowEqual, useSelector } from "react-redux";
import { DeviceType, StreamType } from "const";
import { EditorState, ReduxState } from "types";
import { IProjectData } from "models";
import { useEffect, useState } from "react";
import { useRenderLines } from "canvas/components/lines/use-render-lines";
import { calculateLines } from "canvas/components/lines/use-line-path";

type ObjectItem = [DeviceType | StreamType, number, number];

export const useSavePreview = () => {
  const [objectsData, setObjectsData] = useState<ObjectItem[]>();
  const [linesData, setLinesData] = useState<[number, number][][]>();
  const { devices, massStreams, project } =
    useSelector<ReduxState, Nullable<IProjectData>>((state) => state.project, shallowEqual) || {};
  const { offset, zoom } = useSelector<ReduxState, EditorState>(({ editor }) => editor, shallowEqual);
  const lines = useRenderLines(false);

  useEffect(() => {
    if (!devices || !massStreams) return;

    setObjectsData([
      ...(devices || []).map((d) => [d.type, d.position.left, d.position.top] as ObjectItem),
      ...(massStreams || []).map((s) => [s.type, s.position.left, s.position.top] as ObjectItem),
    ]);
  }, [devices, massStreams]);

  useEffect(() => {
    setLinesData(lines.map(calculateLines));
  }, [lines]);

  useEffect(() => {
    if (!objectsData || !project) return;

    localStorage.setItem(
      `preview_${project.id}`,
      JSON.stringify({ p: [offset.left, offset.top, zoom], l: linesData, o: objectsData }),
    );
  }, [linesData, objectsData, offset, zoom, project, project]);
};
